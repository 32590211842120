import { createSlice } from "@reduxjs/toolkit";
import { ILoading } from "./models";

const initialState: ILoading = {
  loadingProccess: false,
  wsStatus: false,
};

export const { actions: loadingActions, reducer } = createSlice({
  name: "Loading",
  initialState,
  reducers: {
    startLoading: (state: ILoading) => {
      state.loadingProccess = true;
    },
    stopLoading: (state: ILoading) => {
      state.loadingProccess = false;
    },
    setWsStatus: (state: ILoading, { payload }) => {
      state.wsStatus = payload;
    },
  },
});
