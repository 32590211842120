import {
  IBookmark,
  IExceptionForAdd,
  IExchangeParams,
  IGraphSettings,
  IpriceDynamicsDashboardChartSettings,
  IRate,
  ISendWSMessageParams,
  IWSActualData,
} from "./models";
import { createSlice } from "@reduxjs/toolkit";
import { asyncReducer } from "../../utils";
import noop from "lodash/noop";

export const initialState: IWSActualData = {
  wsData: {
    apiData: {
      user__balance__USD__total: {
        success: false,
        data: 0,
      },
      user__balance__BTC__total: {
        success: false,
        data: 0,
      },
      user__history__gate_and_trades: {
        success: false,
        data: [],
      },
      user__history__chart: {
        success: false,
        data: [],
      },
      user__balance__USD__detail: {
        success: false,
        data: [],
      },
      user__assets: {
        success: false,
        data: [],
      },
      user__history__change__USD: {
        success: false,
        data: {
          d: {
            USD: "-",
            perc: "-",
          },
          m: {
            USD: "-",
            perc: "-",
          },
          w: {
            USD: "-",
            perc: "-",
          },
          y: {
            USD: "-",
            perc: "-",
          },
        },
      },
      user__assets_bookmarks__get: {
        success: false,
        data: [],
      },
      order__open: {
        success: false,
        data: [],
      },
      exchange__markets: {
        success: false,
        data: [],
      },
      exchange__dynamic_periods: {
        success: false,
        data: [],
      },
      exchange__dynamic_assets: {
        success: false,
        data: [],
      },
      exchange__rates: [],
      exchange__dynamic: {
        success: false,
        data: {
          candles: [],
          perc: 0,
          usd: 0,
        },
      },
      profitability: {
        success: false,
        data: {
          message: "",
          perc: 0,
          resume_url: "",
          success: false,
          usd: 0,
          exceptions: [],
          resume_urls: [],
          exchanges: [],
          ready: false,
        },
      },
    },
    currentGraphSettings: {
      name: "week",
      period: "7days",
      timeframe: "daily",
    },
    exchangeParams: {
      pair: { label: "BTC/USDT", value: "BTC/USDT" },
    },
    priceDynamicsDashboardChartSettings: {
      period: "year",
      coinTicker: "BTC",
    },
  },
};

export const { actions: wsActualDataActions, reducer } = createSlice({
  name: "WSActualData",
  initialState,
  reducers: {
    setGraphSettings: (
      state: IWSActualData,
      { payload }: { payload: IGraphSettings }
    ) => {
      state.wsData.currentGraphSettings = payload;
    },
    setExhangeParams: (
      state: IWSActualData,
      { payload }: { payload: IExchangeParams }
    ) => {
      state.wsData.exchangeParams = payload;
    },
    changePriceDynamics: (
      state: IWSActualData,
      { payload }: { payload: IpriceDynamicsDashboardChartSettings }
    ) => {
      state.wsData.priceDynamicsDashboardChartSettings = payload;
    },
    getWsActualData: (state: IWSActualData, { payload: { data } }) => {
      state.wsData.apiData = {
        ...state.wsData.apiData,
        ...data,
        exchange__rates: data.exchange__rates.data
          ? Object.keys(data.exchange__rates.data)
              .map((key) => {
                return {
                  symbol: key,
                  ...data.exchange__rates.data[key],
                };
              })
              .filter(
                (item) =>
                  item.fresh !== 0 &&
                  item.avg_for_last_24h !== 0 &&
                  item.sort_order >= 0
              )
              .sort((a: IRate, b: IRate) =>
                a.sort_order > b.sort_order ? 1 : -1
              )
          : state.wsData.apiData.exchange__rates,
      };
    },
    changeBookmarksOrder: (
      state: IWSActualData,
      { payload }: { payload: IBookmark[] }
    ) => {
      state.wsData.apiData = {
        ...state.wsData.apiData,
        user__assets_bookmarks__get: {
          success: true,
          data: payload,
        },
      };
    },
    clearWsActualDataState: (state: IWSActualData) => {
      state.wsData = initialState.wsData;
    },
    fixExeptionToken: asyncReducer<IWSActualData, IExceptionForAdd>(noop),
    openWSActualData: asyncReducer<IWSActualData>(noop),
    closeWSActualData: asyncReducer<IWSActualData>(noop),
    sendWSMessage: asyncReducer<IWSActualData, ISendWSMessageParams>(noop),
  },
});
