import React, { ReactElement } from "react";
// operations icons
import { ReactComponent as IncreaseSVG } from "../../assets/operationType/up.svg";
import { ReactComponent as DecreaseSVG } from "../../assets/operationType/down.svg";
import { ReactComponent as EqualSVG } from "../../assets/operationType/changing.svg";
// coin icons
import { ReactComponent as NoIcon } from "../../assets/icons/plus.svg";
import { ReactComponent as OneART } from "../../assets/coins/1ART.svg";
import { ReactComponent as OneINCH } from "../../assets/coins/1INCH.svg";
import { ReactComponent as FiveIRE } from "../../assets/coins/5IRE.svg";
import { ReactComponent as AAVE } from "../../assets/coins/AAVE.svg";
import { ReactComponent as ACA } from "../../assets/coins/ACA.svg";
import { ReactComponent as ACH } from "../../assets/coins/ACH.svg";
import { ReactComponent as ACM } from "../../assets/coins/ACM.svg";
import { ReactComponent as ADA } from "../../assets/coins/ADA.svg";
import { ReactComponent as ADADOWN } from "../../assets/coins/ADADOWN.svg";
import { ReactComponent as ADAUP } from "../../assets/coins/ADAUP.svg";
import { ReactComponent as ADX } from "../../assets/coins/ADX.svg";
import { ReactComponent as AE } from "../../assets/coins/AE.svg";
import { ReactComponent as AERGO } from "../../assets/coins/AERGO.svg";
import { ReactComponent as AETHC } from "../../assets/coins/aETHc.svg";
import { ReactComponent as AGIX } from "../../assets/coins/AGIX.svg";
import { ReactComponent as AGLD } from "../../assets/coins/AGLD.svg";
import { ReactComponent as AHT } from "../../assets/coins/AHT.svg";
import { ReactComponent as AION } from "../../assets/coins/AION.svg";
import { ReactComponent as AIOZ } from "../../assets/coins/AIOZ.svg";
import { ReactComponent as AKRO } from "../../assets/coins/AKRO.svg";
import { ReactComponent as AKT } from "../../assets/coins/AKT.svg";
import { ReactComponent as ALCX } from "../../assets/coins/ALCX.svg";
import { ReactComponent as ALGO } from "../../assets/coins/ALGO.svg";
import { ReactComponent as ALICE } from "../../assets/coins/ALICE.svg";
import { ReactComponent as ALPACA } from "../../assets/coins/ALPACA.svg";
import { ReactComponent as ALPHA } from "../../assets/coins/ALPHA.svg";
import { ReactComponent as ALPINE } from "../../assets/coins/ALPINE.svg";
import { ReactComponent as AMB } from "../../assets/coins/AMB.svg";
import { ReactComponent as AMO } from "../../assets/coins/AMO.svg";
import { ReactComponent as AMP } from "../../assets/coins/AMP.svg";
import { ReactComponent as AMPL } from "../../assets/coins/AMPL.svg";
import { ReactComponent as ANC } from "../../assets/coins/ANC.svg";
import { ReactComponent as ANKR } from "../../assets/coins/ANKR.svg";
import { ReactComponent as API3 } from "../../assets/coins/API3.svg";
import { ReactComponent as APX } from "../../assets/coins/APX.svg";
import { ReactComponent as ANT } from "../../assets/coins/ANT.svg";
import { ReactComponent as APE } from "../../assets/coins/APE.svg";
import { ReactComponent as AR } from "../../assets/coins/AR.svg";
import { ReactComponent as ARDR } from "../../assets/coins/ARDR.svg";
import { ReactComponent as ARK } from "../../assets/coins/ARK.svg";
import { ReactComponent as ARNX } from "../../assets/coins/ARNX.svg";
import { ReactComponent as ARPA } from "../../assets/coins/ARPA.svg";
import { ReactComponent as ASD } from "../../assets/coins/ASD.svg";
import { ReactComponent as ASK } from "../../assets/coins/ASK.svg";
import { ReactComponent as ASM } from "../../assets/coins/ASM.svg";
import { ReactComponent as ASR } from "../../assets/coins/ASR.svg";
import { ReactComponent as AST } from "../../assets/coins/AST.svg";
import { ReactComponent as ASTR } from "../../assets/coins/ASTR.svg";
import { ReactComponent as ATA } from "../../assets/coins/ATA.svg";
import { ReactComponent as ATM } from "../../assets/coins/ATM.svg";
import { ReactComponent as ATOLO } from "../../assets/coins/ATOLO.svg";
import { ReactComponent as ATOM } from "../../assets/coins/ATOM.svg";
import { ReactComponent as AUCTION } from "../../assets/coins/AUCTION.svg";
import { ReactComponent as AUD } from "../../assets/coins/AUD.svg";
import { ReactComponent as AUDIO } from "../../assets/coins/AUDIO.svg";
import { ReactComponent as AURORA } from "../../assets/coins/AURORA.svg";
import { ReactComponent as AURY } from "../../assets/coins/AURY.svg";
import { ReactComponent as AUTO } from "../../assets/coins/AUTO.svg";
import { ReactComponent as AVA } from "../../assets/coins/AVA.svg";
import { ReactComponent as AVAX } from "../../assets/coins/AVAX.svg";
import { ReactComponent as AXEL } from "../../assets/coins/AXEL.svg";
import { ReactComponent as AXS } from "../../assets/coins/AXS.svg";
import { ReactComponent as BABY } from "../../assets/coins/BABY.svg";
import { ReactComponent as BADGER } from "../../assets/coins/BADGER.svg";
import { ReactComponent as BAKE } from "../../assets/coins/BAKE.svg";
import { ReactComponent as BAL } from "../../assets/coins/BAL.svg";
import { ReactComponent as BAND } from "../../assets/coins/BAND.svg";
import { ReactComponent as BAR } from "../../assets/coins/BAR.svg";
import { ReactComponent as BAT } from "../../assets/coins/BAT.svg";
import { ReactComponent as BCD } from "../../assets/coins/BCD.svg";
import { ReactComponent as BCH } from "../../assets/coins/BCH.svg";
import { ReactComponent as BEAM } from "../../assets/coins/BEAM.svg";
import { ReactComponent as BEL } from "../../assets/coins/BEL.svg";
import { ReactComponent as BETA } from "../../assets/coins/BETA.svg";
import { ReactComponent as BETH } from "../../assets/coins/BETH.svg";
import { ReactComponent as BFC } from "../../assets/coins/BFC.svg";
import { ReactComponent as BICO } from "../../assets/coins/BICO.svg";
import { ReactComponent as BIFI } from "../../assets/coins/BIFI.svg";
import { ReactComponent as BIOT } from "../../assets/coins/BIOT.svg";
import { ReactComponent as BIT } from "../../assets/coins/BIT.svg";
import { ReactComponent as BLCT } from "../../assets/coins/BLCT.svg";
import { ReactComponent as BLK } from "../../assets/coins/BLK.svg";
import { ReactComponent as BLOK } from "../../assets/coins/BLOK.svg";
import { ReactComponent as BLZ } from "../../assets/coins/BLZ.svg";
import { ReactComponent as BMC } from "../../assets/coins/BMC.svg";
import { ReactComponent as BMX } from "../../assets/coins/BMX.svg";
import { ReactComponent as BNANA } from "../../assets/coins/BNANA.svg";
import { ReactComponent as BNB } from "../../assets/coins/BNB.svg";
import { ReactComponent as BNBDOWN } from "../../assets/coins/BNBDOWN.svg";
import { ReactComponent as BNBUP } from "../../assets/coins/BNBUP.svg";
import { ReactComponent as BNT } from "../../assets/coins/BNT.svg";
import { ReactComponent as BNX } from "../../assets/coins/BNX.svg";
import { ReactComponent as BOBA } from "../../assets/coins/BOBA.svg";
import { ReactComponent as BOND } from "../../assets/coins/BOND.svg";
import { ReactComponent as BOO } from "../../assets/coins/BOO.svg";
import { ReactComponent as BOR } from "../../assets/coins/BOR.svg";
import { ReactComponent as BORA } from "../../assets/coins/BORA.svg";
import { ReactComponent as BOSON } from "../../assets/coins/BOSON.svg";
import { ReactComponent as BRD } from "../../assets/coins/BRD.svg";
import { ReactComponent as BSD } from "../../assets/coins/BSD.svg";
import { ReactComponent as BSV } from "../../assets/coins/BSV.svg";
import { ReactComponent as BSW } from "../../assets/coins/BSW.svg";
import { ReactComponent as BTC } from "../../assets/coins/BTC.svg";
import { ReactComponent as BTCDOWN } from "../../assets/coins/BTCDOWN.svg";
import { ReactComponent as BTCP } from "../../assets/coins/BTCP.svg";
import { ReactComponent as BTCST } from "../../assets/coins/BTCST.svg";
import { ReactComponent as BTCUP } from "../../assets/coins/BTCUP.svg";
import { ReactComponent as BTG } from "../../assets/coins/BTG.svg";
import { ReactComponent as BTM } from "../../assets/coins/BTM.svg";
import { ReactComponent as BTRST } from "../../assets/coins/BTRST.svg";
import { ReactComponent as BTS } from "../../assets/coins/BTS.svg";
import { ReactComponent as BTSE } from "../../assets/coins/BTSE.svg";
import { ReactComponent as BTT } from "../../assets/coins/BTT.svg";
import { ReactComponent as BTTC } from "../../assets/coins/BTTC.svg";
import { ReactComponent as BTTOLD } from "../../assets/coins/BTTOLD.svg";
import { ReactComponent as BURGER } from "../../assets/coins/BURGER.svg";
import { ReactComponent as BUSD } from "../../assets/coins/BUSD.svg";
import { ReactComponent as BZRX } from "../../assets/coins/BZRX.svg";
import { ReactComponent as BZZ } from "../../assets/coins/BZZ.svg";
import { ReactComponent as C98 } from "../../assets/coins/C98.svg";
import { ReactComponent as CAKE } from "../../assets/coins/CAKE.svg";
import { ReactComponent as CBG } from "../../assets/coins/CBG.svg";
import { ReactComponent as CBK } from "../../assets/coins/CBK.svg";
import { ReactComponent as CEEK } from "../../assets/coins/CEEK.svg";
import { ReactComponent as CEL } from "../../assets/coins/CEL.svg";
import { ReactComponent as CELO } from "../../assets/coins/CELO.svg";
import { ReactComponent as CELR } from "../../assets/coins/CELR.svg";
import { ReactComponent as CENNZ } from "../../assets/coins/CENNZ.svg";
import { ReactComponent as CET } from "../../assets/coins/CET.svg";
import { ReactComponent as CFG } from "../../assets/coins/CFG.svg";
import { ReactComponent as CFX } from "../../assets/coins/CFX.svg";
import { ReactComponent as CGU } from "../../assets/coins/CGU.svg";
import { ReactComponent as CHESS } from "../../assets/coins/CHESS.svg";
import { ReactComponent as CHR } from "../../assets/coins/CHR.svg";
import { ReactComponent as CHSB } from "../../assets/coins/CHSB.svg";
import { ReactComponent as CHZ } from "../../assets/coins/CHZ.svg";
import { ReactComponent as CITY } from "../../assets/coins/CITY.svg";
import { ReactComponent as CKB } from "../../assets/coins/CKB.svg";
import { ReactComponent as CLT } from "../../assets/coins/CLT.svg";
import { ReactComponent as CLV } from "../../assets/coins/CLV.svg";
import { ReactComponent as COCOS } from "../../assets/coins/COCOS.svg";
import { ReactComponent as COMP } from "../../assets/coins/COMP.svg";
import { ReactComponent as COS } from "../../assets/coins/COS.svg";
import { ReactComponent as COTI } from "../../assets/coins/COTI.svg";
import { ReactComponent as COVAL } from "../../assets/coins/COVAL.svg";
import { ReactComponent as CQT } from "../../assets/coins/CQT.svg";
import { ReactComponent as CRE } from "../../assets/coins/CRE.svg";
import { ReactComponent as CREAM } from "../../assets/coins/CREAM.svg";
import { ReactComponent as CRO } from "../../assets/coins/CRO.svg";
import { ReactComponent as CRODO } from "../../assets/coins/CRODO.svg";
import { ReactComponent as CRTS } from "../../assets/coins/CRTS.svg";
import { ReactComponent as CRV } from "../../assets/coins/CRV.svg";
import { ReactComponent as CSPR } from "../../assets/coins/CSPR.svg";
import { ReactComponent as CTK } from "../../assets/coins/CTK.svg";
import { ReactComponent as CTSI } from "../../assets/coins/CTSI.svg";
import { ReactComponent as CTX } from "../../assets/coins/CTX.svg";
import { ReactComponent as CTXC } from "../../assets/coins/CTXC.svg";
import { ReactComponent as CUBE } from "../../assets/coins/CUBE.svg";
import { ReactComponent as CVC } from "../../assets/coins/CVC.svg";
import { ReactComponent as CVP } from "../../assets/coins/CVP.svg";
import { ReactComponent as CVX } from "../../assets/coins/CVX.svg";
import { ReactComponent as DAD } from "../../assets/coins/DAD.svg";
import { ReactComponent as DAI } from "../../assets/coins/DAI.svg";
import { ReactComponent as DAR } from "../../assets/coins/DAR.svg";
import { ReactComponent as DASH } from "../../assets/coins/DASH.svg";
import { ReactComponent as DATA } from "../../assets/coins/DATA.svg";
import { ReactComponent as DAWN } from "../../assets/coins/DAWN.svg";
import { ReactComponent as DCR } from "../../assets/coins/DCR.svg";
import { ReactComponent as DDX } from "../../assets/coins/DDX.svg";
import { ReactComponent as DEGO } from "../../assets/coins/DEGO.svg";
import { ReactComponent as DENT } from "../../assets/coins/DENT.svg";
import { ReactComponent as DERC } from "../../assets/coins/DERC.svg";
import { ReactComponent as DERO } from "../../assets/coins/DERO.svg";
import { ReactComponent as DEXE } from "../../assets/coins/DEXE.svg";
import { ReactComponent as DF } from "../../assets/coins/DF.svg";
import { ReactComponent as DFI } from "../../assets/coins/DFI.svg";
import { ReactComponent as DG } from "../../assets/coins/DG.svg";
import { ReactComponent as DGB } from "../../assets/coins/DGB.svg";
import { ReactComponent as DIA } from "../../assets/coins/DIA.svg";
import { ReactComponent as DKA } from "../../assets/coins/DKA.svg";
import { ReactComponent as DNT } from "../../assets/coins/DNT.svg";
import { ReactComponent as DOCK } from "../../assets/coins/DOCK.svg";
import { ReactComponent as DODO } from "../../assets/coins/DODO.svg";
import { ReactComponent as DOGE } from "../../assets/coins/DOGE.svg";
import { ReactComponent as DOT } from "../../assets/coins/DOT.svg";
import { ReactComponent as DOTDOWN } from "../../assets/coins/DOTDOWN.svg";
import { ReactComponent as DOTUP } from "../../assets/coins/DOTUP.svg";
import { ReactComponent as DPI } from "../../assets/coins/DPI.svg";
import { ReactComponent as DPR } from "../../assets/coins/DPR.svg";
import { ReactComponent as DREP } from "../../assets/coins/DREP.svg";
import { ReactComponent as DUSK } from "../../assets/coins/DUSK.svg";
import { ReactComponent as DVF } from "../../assets/coins/DVF.svg";
import { ReactComponent as DX } from "../../assets/coins/DX.svg";
import { ReactComponent as DXD } from "../../assets/coins/DXD.svg";
import { ReactComponent as DYDX } from "../../assets/coins/DYDX.svg";
import { ReactComponent as EFI } from "../../assets/coins/EFI.svg";
import { ReactComponent as EGG } from "../../assets/coins/EGG.svg";
import { ReactComponent as EGLD } from "../../assets/coins/EGLD.svg";
import { ReactComponent as ELF } from "../../assets/coins/ELF.svg";
import { ReactComponent as ENJ } from "../../assets/coins/ENJ.svg";
import { ReactComponent as ENS } from "../../assets/coins/ENS.svg";
import { ReactComponent as EOS } from "../../assets/coins/EOS.svg";
import { ReactComponent as EPS } from "../../assets/coins/EPS.svg";
import { ReactComponent as EPX } from "../../assets/coins/EPX.svg";
import { ReactComponent as ERG } from "../../assets/coins/ERG.svg";
import { ReactComponent as ERN } from "../../assets/coins/ERN.svg";
import { ReactComponent as ETC } from "../../assets/coins/ETC.svg";
import { ReactComponent as ETH } from "../../assets/coins/ETH.svg";
import { ReactComponent as ETHDOWN } from "../../assets/coins/ETHDOWN.svg";
import { ReactComponent as ETHUP } from "../../assets/coins/ETHUP.svg";
import { ReactComponent as ETN } from "../../assets/coins/ETN.svg";
import { ReactComponent as EUM } from "../../assets/coins/EUM.svg";
import { ReactComponent as EUR } from "../../assets/coins/EUR.svg";
import { ReactComponent as EXRD } from "../../assets/coins/EXRD.svg";
import { ReactComponent as EZ } from "../../assets/coins/EZ.svg";
import { ReactComponent as FARM } from "../../assets/coins/FARM.svg";
import { ReactComponent as FCT } from "../../assets/coins/FCT.svg";
import { ReactComponent as FEI } from "../../assets/coins/FEI.svg";
import { ReactComponent as FET } from "../../assets/coins/FET.svg";
import { ReactComponent as FIDA } from "../../assets/coins/FIDA.svg";
import { ReactComponent as FIL } from "../../assets/coins/FIL.svg";
import { ReactComponent as FIO } from "../../assets/coins/FIO.svg";
import { ReactComponent as FIRO } from "../../assets/coins/FIRO.svg";
import { ReactComponent as FIS } from "../../assets/coins/FIS.svg";
import { ReactComponent as FLETA } from "../../assets/coins/FLETA.svg";
import { ReactComponent as FLOW } from "../../assets/coins/FLOW.svg";
import { ReactComponent as FLUID } from "../../assets/coins/FLUID.svg";
import { ReactComponent as FLUX } from "../../assets/coins/FLUX.svg";
import { ReactComponent as FOR } from "../../assets/coins/FOR.svg";
import { ReactComponent as FORTH } from "../../assets/coins/FORTH.svg";
import { ReactComponent as FOX } from "../../assets/coins/FOX.svg";
import { ReactComponent as FRAX } from "../../assets/coins/FRAX.svg";
import { ReactComponent as FRONT } from "../../assets/coins/FRONT.svg";
import { ReactComponent as FRTS } from "../../assets/coins/FRTS.svg";
import { ReactComponent as FSN } from "../../assets/coins/FSN.svg";
import { ReactComponent as FTM } from "../../assets/coins/FTM.svg";
import { ReactComponent as FTT } from "../../assets/coins/FTT.svg";
import { ReactComponent as FUN } from "../../assets/coins/FUN.svg";
import { ReactComponent as FUSE } from "../../assets/coins/FUSE.svg";
import { ReactComponent as FXS } from "../../assets/coins/FXS.svg";
import { ReactComponent as GAL } from "../../assets/coins/GAL.svg";
import { ReactComponent as GALA } from "../../assets/coins/GALA.svg";
import { ReactComponent as GAS } from "../../assets/coins/GAS.svg";
import { ReactComponent as GBP } from "../../assets/coins/GBP.svg";
import { ReactComponent as GENE } from "../../assets/coins/GENE.svg";
import { ReactComponent as GHST } from "../../assets/coins/GHST.svg";
import { ReactComponent as GLM } from "../../assets/coins/GLM.svg";
import { ReactComponent as GLMR } from "../../assets/coins/GLMR.svg";
import { ReactComponent as GMT } from "../../assets/coins/GMT.svg";
import { ReactComponent as GNO } from "../../assets/coins/GNO.svg";
import { ReactComponent as GO } from "../../assets/coins/GO.svg";
import { ReactComponent as GRS } from "../../assets/coins/GRS.svg";
import { ReactComponent as GRT } from "../../assets/coins/GRT.svg";
import { ReactComponent as GT } from "../../assets/coins/GT.svg";
import { ReactComponent as GTC } from "../../assets/coins/GTC.svg";
import { ReactComponent as GTO } from "../../assets/coins/GTO.svg";
import { ReactComponent as GUSD } from "../../assets/coins/GUSD.svg";
import { ReactComponent as GXC } from "../../assets/coins/GXC.svg";
import { ReactComponent as GYEN } from "../../assets/coins/GYEN.svg";
import { ReactComponent as HARD } from "../../assets/coins/HARD.svg";
import { ReactComponent as HBAR } from "../../assets/coins/HBAR.svg";
import { ReactComponent as HBTC } from "../../assets/coins/HBTC.svg";
import { ReactComponent as HERO } from "../../assets/coins/HERO.svg";
import { ReactComponent as HEZ } from "../../assets/coins/HEZ.svg";
import { ReactComponent as HIGH } from "../../assets/coins/HIGH.svg";
import { ReactComponent as HIVE } from "../../assets/coins/HIVE.svg";
import { ReactComponent as HNS } from "../../assets/coins/HNS.svg";
import { ReactComponent as HNT } from "../../assets/coins/HNT.svg";
import { ReactComponent as HOO } from "../../assets/coins/HOO.svg";
import { ReactComponent as HOPR } from "../../assets/coins/HOPR.svg";
import { ReactComponent as HOT } from "../../assets/coins/HOT.svg";
import { ReactComponent as HT } from "../../assets/coins/HT.svg";
import { ReactComponent as HTR } from "../../assets/coins/HTR.svg";
import { ReactComponent as HUNT } from "../../assets/coins/HUNT.svg";
import { ReactComponent as HUSD } from "../../assets/coins/HUSD.svg";
import { ReactComponent as HYDRA } from "../../assets/coins/HYDRA.svg";
import { ReactComponent as HYN } from "../../assets/coins/HYN.svg";
import { ReactComponent as ICHI } from "../../assets/coins/ICHI.svg";
import { ReactComponent as ICP } from "../../assets/coins/ICP.svg";
import { ReactComponent as ICX } from "../../assets/coins/ICX.svg";
import { ReactComponent as IDEX } from "../../assets/coins/IDEX.svg";
import { ReactComponent as IDRT } from "../../assets/coins/IDRT.svg";
import { ReactComponent as ILV } from "../../assets/coins/ILV.svg";
import { ReactComponent as IMX } from "../../assets/coins/IMX.svg";
import { ReactComponent as INJ } from "../../assets/coins/INJ.svg";
import { ReactComponent as IOST } from "../../assets/coins/IOST.svg";
import { ReactComponent as IOTA } from "../../assets/coins/IOTA.svg";
import { ReactComponent as IOTX } from "../../assets/coins/IOTX.svg";
import { ReactComponent as IQ } from "../../assets/coins/IQ.svg";
import { ReactComponent as IRIS } from "../../assets/coins/IRIS.svg";
import { ReactComponent as JASMY } from "../../assets/coins/JASMY.svg";
import { ReactComponent as JOE } from "../../assets/coins/JOE.svg";
import { ReactComponent as JST } from "../../assets/coins/JST.svg";
import { ReactComponent as JUV } from "../../assets/coins/JUV.svg";
import { ReactComponent as KAI } from "../../assets/coins/KAI.svg";
import { ReactComponent as KAVA } from "../../assets/coins/KAVA.svg";
import { ReactComponent as KCS } from "../../assets/coins/KCS.svg";
import { ReactComponent as KDA } from "../../assets/coins/KDA.svg";
import { ReactComponent as KEEP } from "../../assets/coins/KEEP.svg";
import { ReactComponent as KEY } from "../../assets/coins/KEY.svg";
import { ReactComponent as KILT } from "../../assets/coins/KILT.svg";
import { ReactComponent as KIN } from "../../assets/coins/KIN.svg";
import { ReactComponent as KIRO } from "../../assets/coins/KIRO.svg";
import { ReactComponent as KLAY } from "../../assets/coins/KLAY.svg";
import { ReactComponent as KLV } from "../../assets/coins/KLV.svg";
import { ReactComponent as KMD } from "../../assets/coins/KMD.svg";
import { ReactComponent as KNC } from "../../assets/coins/KNC.svg";
import { ReactComponent as KP3R } from "../../assets/coins/KP3R.svg";
import { ReactComponent as KRT } from "../../assets/coins/KRT.svg";
import { ReactComponent as KSM } from "../../assets/coins/KSM.svg";
import { ReactComponent as KZEN } from "../../assets/coins/KZEN.svg";
import { ReactComponent as LA } from "../../assets/coins/LA.svg";
import { ReactComponent as LAT } from "../../assets/coins/LAT.svg";
import { ReactComponent as LAZIO } from "../../assets/coins/LAZIO.svg";
import { ReactComponent as LCX } from "../../assets/coins/LCX.svg";
import { ReactComponent as LDO } from "../../assets/coins/LDO.svg";
import { ReactComponent as LEO } from "../../assets/coins/LEO.svg";
import { ReactComponent as LINA } from "../../assets/coins/LINA.svg";
import { ReactComponent as LINK } from "../../assets/coins/LINK.svg";
import { ReactComponent as LINKDOWN } from "../../assets/coins/LINKDOWN.svg";
import { ReactComponent as LINKUP } from "../../assets/coins/LINKUP.svg";
import { ReactComponent as LIT } from "../../assets/coins/LIT.svg";
import { ReactComponent as LN } from "../../assets/coins/LN.svg";
import { ReactComponent as LOG } from "../../assets/coins/LOG.svg";
import { ReactComponent as LOKA } from "../../assets/coins/LOKA.svg";
import { ReactComponent as LON } from "../../assets/coins/LON.svg";
import { ReactComponent as LOOM } from "../../assets/coins/LOOM.svg";
import { ReactComponent as LPT } from "../../assets/coins/LPT.svg";
import { ReactComponent as LQTY } from "../../assets/coins/LQTY.svg";
import { ReactComponent as LRC } from "../../assets/coins/LRC.svg";
import { ReactComponent as LSK } from "../../assets/coins/LSK.svg";
import { ReactComponent as LTC } from "../../assets/coins/LTC.svg";
import { ReactComponent as LTO } from "../../assets/coins/LTO.svg";
import { ReactComponent as LUNA } from "../../assets/coins/LUNA.svg";
import { ReactComponent as LUNC } from "../../assets/coins/LUNC.svg";
import { ReactComponent as LUSD } from "../../assets/coins/LUSD.svg";
import { ReactComponent as MAID } from "../../assets/coins/MAID.svg";
import { ReactComponent as MANA } from "../../assets/coins/MANA.svg";
import { ReactComponent as MARO } from "../../assets/coins/MARO.svg";
import { ReactComponent as MASK } from "../../assets/coins/MASK.svg";
import { ReactComponent as MATH } from "../../assets/coins/MATH.svg";
import { ReactComponent as MATIC } from "../../assets/coins/MATIC.svg";
import { ReactComponent as MBL } from "../../assets/coins/MBL.svg";
import { ReactComponent as MBOX } from "../../assets/coins/MBOX.svg";
import { ReactComponent as MC } from "../../assets/coins/MC.svg";
import { ReactComponent as MDA } from "../../assets/coins/MDA.svg";
import { ReactComponent as MDT } from "../../assets/coins/MDT.svg";
import { ReactComponent as MDX } from "../../assets/coins/MDX.svg";
import { ReactComponent as MED } from "../../assets/coins/MED.svg";
import { ReactComponent as META } from "../../assets/coins/META.svg";
import { ReactComponent as MEV } from "../../assets/coins/MEV.svg";
import { ReactComponent as MFT } from "../../assets/coins/MFT.svg";
import { ReactComponent as MINA } from "../../assets/coins/MINA.svg";
import { ReactComponent as MIOTA } from "../../assets/coins/MIOTA.svg";
import { ReactComponent as MIR } from "../../assets/coins/MIR.svg";
import { ReactComponent as MITH } from "../../assets/coins/MITH.svg";
import { ReactComponent as MIX } from "../../assets/coins/MIX.svg";
import { ReactComponent as MKR } from "../../assets/coins/MKR.svg";
import { ReactComponent as MLK } from "../../assets/coins/MLK.svg";
import { ReactComponent as MLN } from "../../assets/coins/MLN.svg";
import { ReactComponent as MNGO } from "../../assets/coins/MNGO.svg";
import { ReactComponent as MNW } from "../../assets/coins/MNW.svg";
import { ReactComponent as MOB } from "../../assets/coins/MOB.svg";
import { ReactComponent as MOF } from "../../assets/coins/MOF.svg";
import { ReactComponent as MONA } from "../../assets/coins/MONA.svg";
import { ReactComponent as MOVEZ } from "../../assets/coins/MOVEZ.svg";
import { ReactComponent as MOVR } from "../../assets/coins/MOVR.svg";
import { ReactComponent as MSOL } from "../../assets/coins/MSOL.svg";
import { ReactComponent as MTL } from "../../assets/coins/MTL.svg";
import { ReactComponent as MULTI } from "../../assets/coins/MULTI.svg";
import { ReactComponent as MUSD } from "../../assets/coins/MUSD.svg";
import { ReactComponent as MWC } from "../../assets/coins/MWC.svg";
import { ReactComponent as MX } from "../../assets/coins/MX.svg";
import { ReactComponent as MXC } from "../../assets/coins/MXC.svg";
import { ReactComponent as NAS } from "../../assets/coins/NAS.svg";
import { ReactComponent as NAV } from "../../assets/coins/NAV.svg";
import { ReactComponent as NBS } from "../../assets/coins/NBS.svg";
import { ReactComponent as NCT } from "../../assets/coins/NCT.svg";
import { ReactComponent as NEAR } from "../../assets/coins/NEAR.svg";
import { ReactComponent as NEBL } from "../../assets/coins/NEBL.svg";
import { ReactComponent as NEO } from "../../assets/coins/NEO.svg";
import { ReactComponent as NEXO } from "../../assets/coins/NEXO.svg";
import { ReactComponent as NFT } from "../../assets/coins/NFT.svg";
import { ReactComponent as NIF } from "../../assets/coins/NIF.svg";
import { ReactComponent as NKN } from "../../assets/coins/NKN.svg";
import { ReactComponent as NMC } from "../../assets/coins/NMC.svg";
import { ReactComponent as NMR } from "../../assets/coins/NMR.svg";
import { ReactComponent as NOIA } from "../../assets/coins/NOIA.svg";
import { ReactComponent as NRG } from "../../assets/coins/NRG.svg";
import { ReactComponent as NSBT } from "../../assets/coins/NSBT.svg";
import { ReactComponent as NU } from "../../assets/coins/NU.svg";
import { ReactComponent as NULS } from "../../assets/coins/NULS.svg";
import { ReactComponent as NXM } from "../../assets/coins/NXM.svg";
import { ReactComponent as NXS } from "../../assets/coins/NXS.svg";
import { ReactComponent as NYE } from "../../assets/coins/NYE.svg";
import { ReactComponent as OAX } from "../../assets/coins/OAX.svg";
import { ReactComponent as OCEAN } from "../../assets/coins/OCEAN.svg";
import { ReactComponent as OG } from "../../assets/coins/OG.svg";
import { ReactComponent as OGN } from "../../assets/coins/OGN.svg";
import { ReactComponent as OKB } from "../../assets/coins/OKB.svg";
import { ReactComponent as OM } from "../../assets/coins/OM.svg";
import { ReactComponent as OMG } from "../../assets/coins/OMG.svg";
import { ReactComponent as ONE } from "../../assets/coins/ONE.svg";
import { ReactComponent as ONG } from "../../assets/coins/ONG.svg";
import { ReactComponent as ONT } from "../../assets/coins/ONT.svg";
import { ReactComponent as ONUS } from "../../assets/coins/ONUS.svg";
import { ReactComponent as OOKI } from "../../assets/coins/OOKI.svg";
import { ReactComponent as OP } from "../../assets/coins/OP.svg";
import { ReactComponent as ORBS } from "../../assets/coins/ORBS.svg";
import { ReactComponent as ORC } from "../../assets/coins/ORC.svg";
import { ReactComponent as ORN } from "../../assets/coins/ORN.svg";
import { ReactComponent as OSMO } from "../../assets/coins/OSMO.svg";
import { ReactComponent as OUSD } from "../../assets/coins/OUSD.svg";
import { ReactComponent as OXEN } from "../../assets/coins/OXEN.svg";
import { ReactComponent as OXT } from "../../assets/coins/OXT.svg";
import { ReactComponent as PAXG } from "../../assets/coins/PAXG.svg";
import { ReactComponent as PEAK } from "../../assets/coins/PEAK.svg";
import { ReactComponent as PEOPLE } from "../../assets/coins/PEOPLE.svg";
import { ReactComponent as PERL } from "../../assets/coins/PERL.svg";
import { ReactComponent as PERP } from "../../assets/coins/PERP.svg";
import { ReactComponent as PHA } from "../../assets/coins/PHA.svg";
import { ReactComponent as PHB } from "../../assets/coins/PHB.svg";
import { ReactComponent as PHL } from "../../assets/coins/PHL.svg";
import { ReactComponent as PIVX } from "../../assets/coins/PIVX.svg";
import { ReactComponent as PLA } from "../../assets/coins/PLA.svg";
import { ReactComponent as PNK } from "../../assets/coins/PNK.svg";
import { ReactComponent as PNT } from "../../assets/coins/PNT.svg";
import { ReactComponent as POLS } from "../../assets/coins/POLS.svg";
import { ReactComponent as POLY } from "../../assets/coins/POLY.svg";
import { ReactComponent as POND } from "../../assets/coins/POND.svg";
import { ReactComponent as PORTO } from "../../assets/coins/PORTO.svg";
import { ReactComponent as POWR } from "../../assets/coins/POWR.svg";
import { ReactComponent as PRE } from "../../assets/coins/PRE.svg";
import { ReactComponent as PROM } from "../../assets/coins/PROM.svg";
import { ReactComponent as PROS } from "../../assets/coins/PROS.svg";
import { ReactComponent as PRQOne } from "../../assets/coins/PRQ-1.svg";
import { ReactComponent as PRQ } from "../../assets/coins/PRQ.svg";
import { ReactComponent as PSG } from "../../assets/coins/PSG.svg";
import { ReactComponent as PUNDIX } from "../../assets/coins/PUNDIX.svg";
import { ReactComponent as PYR } from "../../assets/coins/PYR.svg";
import { ReactComponent as QASH } from "../../assets/coins/QASH.svg";
import { ReactComponent as QC } from "../../assets/coins/QC.svg";
import { ReactComponent as QI } from "../../assets/coins/QI.svg";
import { ReactComponent as QKC } from "../../assets/coins/QKC.svg";
import { ReactComponent as QLC } from "../../assets/coins/QLC.svg";
import { ReactComponent as QNT } from "../../assets/coins/QNT.svg";
import { ReactComponent as QOM } from "../../assets/coins/QOM.svg";
import { ReactComponent as QSP } from "../../assets/coins/QSP.svg";
import { ReactComponent as QTUM } from "../../assets/coins/QTUM.svg";
import { ReactComponent as QUACK } from "../../assets/coins/QUACK.svg";
import { ReactComponent as QUICK } from "../../assets/coins/QUICK.svg";
import { ReactComponent as RAD } from "../../assets/coins/RAD.svg";
import { ReactComponent as RAI } from "../../assets/coins/RAI.svg";
import { ReactComponent as RAMP } from "../../assets/coins/RAMP.svg";
import { ReactComponent as RARE } from "../../assets/coins/RARE.svg";
import { ReactComponent as RARI } from "../../assets/coins/RARI.svg";
import { ReactComponent as RAY } from "../../assets/coins/RAY.svg";
import { ReactComponent as RBN } from "../../assets/coins/RBN.svg";
import { ReactComponent as REAP } from "../../assets/coins/REAP.svg";
import { ReactComponent as REEF } from "../../assets/coins/REEF.svg";
import { ReactComponent as REI } from "../../assets/coins/REI.svg";
import { ReactComponent as REN } from "../../assets/coins/REN.svg";
import { ReactComponent as RENBTC } from "../../assets/coins/RENBTC.svg";
import { ReactComponent as REP } from "../../assets/coins/REP.svg";
import { ReactComponent as REQ } from "../../assets/coins/REQ.svg";
import { ReactComponent as REV } from "../../assets/coins/REV.svg";
import { ReactComponent as RFOX } from "../../assets/coins/RFOX.svg";
import { ReactComponent as RFR } from "../../assets/coins/RFR.svg";
import { ReactComponent as RGT } from "../../assets/coins/RGT.svg";
import { ReactComponent as RIF } from "../../assets/coins/RIF.svg";
import { ReactComponent as RISE } from "../../assets/coins/RISE.svg";
import { ReactComponent as RKN } from "../../assets/coins/RKN.svg";
import { ReactComponent as RLC } from "../../assets/coins/RLC.svg";
import { ReactComponent as RLY } from "../../assets/coins/RLY.svg";
import { ReactComponent as RMRK } from "../../assets/coins/RMRK.svg";
import { ReactComponent as RNDR } from "../../assets/coins/RNDR.svg";
import { ReactComponent as ROOK } from "../../assets/coins/ROOK.svg";
import { ReactComponent as ROSE } from "../../assets/coins/ROSE.svg";
import { ReactComponent as RSR } from "../../assets/coins/RSR.svg";
import { ReactComponent as RSS3 } from "../../assets/coins/RSS3.svg";
import { ReactComponent as RUNE } from "../../assets/coins/RUNE.svg";
import { ReactComponent as RVN } from "../../assets/coins/RVN.svg";
import { ReactComponent as RVP } from "../../assets/coins/RVP.svg";
import { ReactComponent as SAFE } from "../../assets/coins/SAFE.svg";
import { ReactComponent as SAFEMOON } from "../../assets/coins/SAFEMOON.svg";
import { ReactComponent as SAFFLE } from "../../assets/coins/SAFFLE.svg";
import { ReactComponent as SAMO } from "../../assets/coins/SAMO.svg";
import { ReactComponent as SAND } from "../../assets/coins/SAND.svg";
import { ReactComponent as SANTOS } from "../../assets/coins/SANTOS.svg";
import { ReactComponent as SC } from "../../assets/coins/SC.svg";
import { ReactComponent as SCRT } from "../../assets/coins/SCRT.svg";
import { ReactComponent as SDAO } from "../../assets/coins/SDAO.svg";
import { ReactComponent as SDN } from "../../assets/coins/SDN.svg";
import { ReactComponent as SERO } from "../../assets/coins/SERO.svg";
import { ReactComponent as SFP } from "../../assets/coins/SFP.svg";
import { ReactComponent as SFUND } from "../../assets/coins/SFUND.svg";
import { ReactComponent as SHIB } from "../../assets/coins/SHIB.svg";
import { ReactComponent as SIX } from "../../assets/coins/SIX.svg";
import { ReactComponent as SKL } from "../../assets/coins/SKL.svg";
import { ReactComponent as SLP } from "../../assets/coins/SLP.svg";
import { ReactComponent as SNL } from "../../assets/coins/SNL.svg";
import { ReactComponent as SNM } from "../../assets/coins/SNM.svg";
import { ReactComponent as SNT } from "../../assets/coins/SNT.svg";
import { ReactComponent as SNX } from "../../assets/coins/SNX.svg";
import { ReactComponent as SOFI } from "../../assets/coins/SOFI.svg";
import { ReactComponent as SOL } from "../../assets/coins/SOL.svg";
import { ReactComponent as SOS } from "../../assets/coins/SOS.svg";
import { ReactComponent as SOUL } from "../../assets/coins/SOUL.svg";
import { ReactComponent as SPARTA } from "../../assets/coins/SPARTA.svg";
import { ReactComponent as SPELL } from "../../assets/coins/SPELL.svg";
import { ReactComponent as SPS } from "../../assets/coins/SPS.svg";
import { ReactComponent as SQR } from "../../assets/coins/SQR.svg";
import { ReactComponent as SRM } from "../../assets/coins/SRM.svg";
import { ReactComponent as SSV } from "../../assets/coins/SSV.svg";
import { ReactComponent as SSX } from "../../assets/coins/SSX.svg";
import { ReactComponent as STAKE } from "../../assets/coins/STAKE.svg";
import { ReactComponent as STARL } from "../../assets/coins/STARL.svg";
import { ReactComponent as STEEM } from "../../assets/coins/STEEM.svg";
import { ReactComponent as STETH } from "../../assets/coins/STETH.svg";
import { ReactComponent as STMX } from "../../assets/coins/STMX.svg";
import { ReactComponent as STORJ } from "../../assets/coins/STORJ.svg";
import { ReactComponent as STPT } from "../../assets/coins/STPT.svg";
import { ReactComponent as STRAX } from "../../assets/coins/STRAX.svg";
import { ReactComponent as STRK } from "../../assets/coins/STRK.svg";
import { ReactComponent as STX } from "../../assets/coins/STX.svg";
import { ReactComponent as SUKU } from "../../assets/coins/SUKU.svg";
import { ReactComponent as SUN } from "../../assets/coins/SUN.svg";
import { ReactComponent as SUPER } from "../../assets/coins/SUPER.svg";
import { ReactComponent as SUSHI } from "../../assets/coins/SUSHI.svg";
import { ReactComponent as SWAP } from "../../assets/coins/SWAP.svg";
import { ReactComponent as SWP } from "../../assets/coins/SWP.svg";
import { ReactComponent as SXP } from "../../assets/coins/SXP.svg";
import { ReactComponent as SYS } from "../../assets/coins/SYS.svg";
import { ReactComponent as T } from "../../assets/coins/T.svg";
import { ReactComponent as TCT } from "../../assets/coins/TCT.svg";
import { ReactComponent as TEL } from "../../assets/coins/TEL.svg";
import { ReactComponent as TFUEL } from "../../assets/coins/TFUEL.svg";
import { ReactComponent as THETA } from "../../assets/coins/THETA.svg";
import { ReactComponent as TIME } from "../../assets/coins/TIME.svg";
import { ReactComponent as TITAN } from "../../assets/coins/TITAN.svg";
import { ReactComponent as TKO } from "../../assets/coins/TKO.svg";
import { ReactComponent as TLM } from "../../assets/coins/TLM.svg";
import { ReactComponent as TLOS } from "../../assets/coins/TLOS.svg";
import { ReactComponent as TNB } from "../../assets/coins/TNB.svg";
import { ReactComponent as TOMO } from "../../assets/coins/TOMO.svg";
import { ReactComponent as TON } from "../../assets/coins/TON.svg";
import { ReactComponent as TORN } from "../../assets/coins/TORN.svg";
import { ReactComponent as TPT } from "../../assets/coins/TPT.svg";
import { ReactComponent as TRB } from "../../assets/coins/TRB.svg";
import { ReactComponent as TRIBE } from "../../assets/coins/TRIBE.svg";
import { ReactComponent as TROY } from "../../assets/coins/TROY.svg";
import { ReactComponent as TRU } from "../../assets/coins/TRU.svg";
import { ReactComponent as TRX } from "../../assets/coins/TRX.svg";
import { ReactComponent as TRXDOWN } from "../../assets/coins/TRXDOWN.svg";
import { ReactComponent as TRXUP } from "../../assets/coins/TRXUP.svg";
import { ReactComponent as TT } from "../../assets/coins/TT.svg";
import { ReactComponent as TTT } from "../../assets/coins/TTT.svg";
import { ReactComponent as TUSD } from "../../assets/coins/TUSD.svg";
import { ReactComponent as TVK } from "../../assets/coins/TVK.svg";
import { ReactComponent as TWT } from "../../assets/coins/TWT.svg";
import { ReactComponent as UFI } from "../../assets/coins/UFI.svg";
import { ReactComponent as UFO } from "../../assets/coins/UFO.svg";
import { ReactComponent as UFT } from "../../assets/coins/UFT.svg";
import { ReactComponent as ULT } from "../../assets/coins/ULT.svg";
import { ReactComponent as UMA } from "../../assets/coins/UMA.svg";
import { ReactComponent as UNFI } from "../../assets/coins/UNFI.svg";
import { ReactComponent as UNI } from "../../assets/coins/UNI.svg";
import { ReactComponent as UQC } from "../../assets/coins/UQC.svg";
import { ReactComponent as USDC } from "../../assets/coins/USDC.svg";
import { ReactComponent as USDD } from "../../assets/coins/USDD.svg";
import { ReactComponent as USDJ } from "../../assets/coins/USDJ.svg";
import { ReactComponent as USDN } from "../../assets/coins/USDN.svg";
import { ReactComponent as USDP } from "../../assets/coins/USDP.svg";
import { ReactComponent as USDT } from "../../assets/coins/USDT.svg";
import { ReactComponent as USTC } from "../../assets/coins/USTC.svg";
import { ReactComponent as UTK } from "../../assets/coins/UTK.svg";
import { ReactComponent as VBIT } from "../../assets/coins/VBIT.svg";
import { ReactComponent as VEGA } from "../../assets/coins/VEGA.svg";
import { ReactComponent as VERI } from "../../assets/coins/VERI.svg";
import { ReactComponent as VET } from "../../assets/coins/VET.svg";
import { ReactComponent as VGX } from "../../assets/coins/VGX.svg";
import { ReactComponent as VIB } from "../../assets/coins/VIB.svg";
import { ReactComponent as VIDT } from "../../assets/coins/VIDT.svg";
import { ReactComponent as VITE } from "../../assets/coins/VITE.svg";
import { ReactComponent as VLX } from "../../assets/coins/VLX.svg";
import { ReactComponent as VOXEL } from "../../assets/coins/VOXEL.svg";
import { ReactComponent as VR } from "../../assets/coins/VR.svg";
import { ReactComponent as VRA } from "../../assets/coins/VRA.svg";
import { ReactComponent as VTHO } from "../../assets/coins/VTHO.svg";
import { ReactComponent as VUSDT } from "../../assets/coins/vUSDT.svg";
import { ReactComponent as VXV } from "../../assets/coins/VXV.svg";
import { ReactComponent as VXVS } from "../../assets/coins/vXVS.svg";
import { ReactComponent as WABI } from "../../assets/coins/WABI.svg";
import { ReactComponent as WAN } from "../../assets/coins/WAN.svg";
import { ReactComponent as WAVES } from "../../assets/coins/WAVES.svg";
import { ReactComponent as WAXP } from "../../assets/coins/WAXP.svg";
import { ReactComponent as WBNB } from "../../assets/coins/WBNB.svg";
import { ReactComponent as WBTC } from "../../assets/coins/WBTC.svg";
import { ReactComponent as WEMIX } from "../../assets/coins/WEMIX.svg";
import { ReactComponent as WILD } from "../../assets/coins/WILD.svg";
import { ReactComponent as WIN } from "../../assets/coins/WIN.svg";
import { ReactComponent as WING } from "../../assets/coins/WING.svg";
import { ReactComponent as WNXM } from "../../assets/coins/WNXM.svg";
import { ReactComponent as WOO } from "../../assets/coins/WOO.svg";
import { ReactComponent as WOZX } from "../../assets/coins/WOZX.svg";
import { ReactComponent as WRX } from "../../assets/coins/WRX.svg";
import { ReactComponent as WTC } from "../../assets/coins/WTC.svg";
import { ReactComponent as WTRX } from "../../assets/coins/WTRX.svg";
import { ReactComponent as WXT } from "../../assets/coins/WXT.svg";
import { ReactComponent as XAUT } from "../../assets/coins/XAUT.svg";
import { ReactComponent as XCH } from "../../assets/coins/XCH.svg";
import { ReactComponent as XCN } from "../../assets/coins/XCN.svg";
import { ReactComponent as XDB } from "../../assets/coins/XDB.svg";
import { ReactComponent as XDC } from "../../assets/coins/XDC.svg";
import { ReactComponent as XEC } from "../../assets/coins/XEC.svg";
import { ReactComponent as XEM } from "../../assets/coins/XEM.svg";
import { ReactComponent as XHV } from "../../assets/coins/XHV.svg";
import { ReactComponent as XLM } from "../../assets/coins/XLM.svg";
import { ReactComponent as XMR } from "../../assets/coins/XMR.svg";
import { ReactComponent as XNC } from "../../assets/coins/XNC.svg";
import { ReactComponent as XNO } from "../../assets/coins/XNO.svg";
import { ReactComponent as XPR } from "../../assets/coins/XPR.svg";
import { ReactComponent as XPRT } from "../../assets/coins/XPRT.svg";
import { ReactComponent as XRP } from "../../assets/coins/XRP.svg";
import { ReactComponent as XRPDOWN } from "../../assets/coins/XRPDOWN.svg";
import { ReactComponent as XRPUP } from "../../assets/coins/XRPUP.svg";
import { ReactComponent as XTZ } from "../../assets/coins/XTZ.svg";
import { ReactComponent as XVG } from "../../assets/coins/XVG.svg";
import { ReactComponent as XVS } from "../../assets/coins/XVS.svg";
import { ReactComponent as XWC } from "../../assets/coins/XWC.svg";
import { ReactComponent as XYM } from "../../assets/coins/XYM.svg";
import { ReactComponent as XYO } from "../../assets/coins/XYO.svg";
import { ReactComponent as YFI } from "../../assets/coins/YFI.svg";
import { ReactComponent as YFII } from "../../assets/coins/YFII.svg";
import { ReactComponent as YGG } from "../../assets/coins/YGG.svg";
import { ReactComponent as YLD } from "../../assets/coins/YLD.svg";
import { ReactComponent as YOOSHI } from "../../assets/coins/YOOSHI.svg";
import { ReactComponent as YOUC } from "../../assets/coins/YOUC.svg";
import { ReactComponent as ZAM } from "../../assets/coins/ZAM.svg";
import { ReactComponent as ZB } from "../../assets/coins/ZB.svg";
import { ReactComponent as ZEC } from "../../assets/coins/ZEC.svg";
import { ReactComponent as ZEN } from "../../assets/coins/ZEN.svg";
import { ReactComponent as ZEON } from "../../assets/coins/ZEON.svg";
import { ReactComponent as ZIL } from "../../assets/coins/ZIL.svg";
import { ReactComponent as ZRX } from "../../assets/coins/ZRX.svg";
import { ReactComponent as KAR } from "../../assets/coins/KAR.svg";
import { ReactComponent as APT } from "../../assets/coins/APT.svg";
// exchanges
import { ReactComponent as Binance } from "../../assets/exchangeLogo/binance.svg";
import { ReactComponent as Bitfinex } from "../../assets/exchangeLogo/bitfinex.svg";
import { ReactComponent as Coinbase } from "../../assets/exchangeLogo/coinbase.svg";
import { ReactComponent as Huobi } from "../../assets/exchangeLogo/huobi.svg";
import { ReactComponent as Poloniex } from "../../assets/exchangeLogo/poloniex.svg";
import { ReactComponent as Ftx } from "../../assets/exchangeLogo/ftx.svg";
import { ReactComponent as Kucoin } from "../../assets/exchangeLogo/kucoin.svg";
import { ReactComponent as Okx } from "../../assets/exchangeLogo/okx.svg";

export const HISTORY_OPERATION_TYPE = {
  buy: "buy",
  sell: "sell",
  withdrawal: "withdrawal",
  deposit: "deposit",
};

export const OPERATION_ICONS: Record<string, ReactElement> = {
  [HISTORY_OPERATION_TYPE.buy]: <EqualSVG />,
  [HISTORY_OPERATION_TYPE.sell]: <EqualSVG />,
  [HISTORY_OPERATION_TYPE.withdrawal]: <IncreaseSVG />,
  [HISTORY_OPERATION_TYPE.deposit]: <DecreaseSVG />,
};

export const EXCHANGE_ICONS: Record<string, ReactElement> = {
  binance: <Binance />,
  coinbase: <Coinbase />,
  bitfinex: <Bitfinex />,
  huobi: <Huobi />,
  poloniex: <Poloniex />,
  ftx: <Ftx />,
  kucoin: <Kucoin />,
  okx: <Okx />,
};

export const COIN_ICONS: Record<string, ReactElement> = {
  "1ART": <OneART />,
  "1INCH": <OneINCH />,
  "5IRE": <FiveIRE />,
  AAVE: <AAVE />,
  ACA: <ACA />,
  ACH: <ACH />,
  ACM: <ACM />,
  ADA: <ADA />,
  ADADOWN: <ADADOWN />,
  ADAUP: <ADAUP />,
  ADX: <ADX />,
  AE: <AE />,
  AERGO: <AERGO />,
  AETHC: <AETHC />,
  AGIX: <AGIX />,
  AGLD: <AGLD />,
  AHT: <AHT />,
  AION: <AION />,
  AIOZ: <AIOZ />,
  AKRO: <AKRO />,
  AKT: <AKT />,
  ALCX: <ALCX />,
  ALGO: <ALGO />,
  ALICE: <ALICE />,
  ALPACA: <ALPACA />,
  ALPHA: <ALPHA />,
  ALPINE: <ALPINE />,
  AMB: <AMB />,
  AMO: <AMO />,
  AMP: <AMP />,
  AMPL: <AMPL />,
  ANC: <ANC />,
  ANKR: <ANKR />,
  API3: <API3 />,
  APX: <APX />,
  ANT: <ANT />,
  APE: <APE />,
  AR: <AR />,
  ARDR: <ARDR />,
  ARK: <ARK />,
  ARNX: <ARNX />,
  ARPA: <ARPA />,
  ASD: <ASD />,
  ASK: <ASK />,
  ASM: <ASM />,
  ASR: <ASR />,
  AST: <AST />,
  ASTR: <ASTR />,
  ATA: <ATA />,
  ATM: <ATM />,
  ATOLO: <ATOLO />,
  ATOM: <ATOM />,
  AUCTION: <AUCTION />,
  AUD: <AUD />,
  AUDIO: <AUDIO />,
  AURORA: <AURORA />,
  AURY: <AURY />,
  AUTO: <AUTO />,
  AVA: <AVA />,
  AVAX: <AVAX />,
  AXEL: <AXEL />,
  AXS: <AXS />,
  BABY: <BABY />,
  BADGER: <BADGER />,
  BAKE: <BAKE />,
  BAL: <BAL />,
  BAND: <BAND />,
  BAR: <BAR />,
  BAT: <BAT />,
  BCD: <BCD />,
  BCH: <BCH />,
  BEAM: <BEAM />,
  BEL: <BEL />,
  BETA: <BETA />,
  BETH: <BETH />,
  BFC: <BFC />,
  BICO: <BICO />,
  BIFI: <BIFI />,
  BIOT: <BIOT />,
  BIT: <BIT />,
  BLCT: <BLCT />,
  BLK: <BLK />,
  BLOK: <BLOK />,
  BLZ: <BLZ />,
  BMC: <BMC />,
  BMX: <BMX />,
  BNANA: <BNANA />,
  BNB: <BNB />,
  BNBDOWN: <BNBDOWN />,
  BNBUP: <BNBUP />,
  BNT: <BNT />,
  BNX: <BNX />,
  BOBA: <BOBA />,
  BOND: <BOND />,
  BOO: <BOO />,
  BOR: <BOR />,
  BORA: <BORA />,
  BOSON: <BOSON />,
  BRD: <BRD />,
  BSD: <BSD />,
  BSV: <BSV />,
  BSW: <BSW />,
  BTC: <BTC />,
  BTCDOWN: <BTCDOWN />,
  BTCP: <BTCP />,
  BTCST: <BTCST />,
  BTCUP: <BTCUP />,
  BTG: <BTG />,
  BTM: <BTM />,
  BTRST: <BTRST />,
  BTS: <BTS />,
  BTSE: <BTSE />,
  BTT: <BTT />,
  BTTC: <BTTC />,
  BTTOLD: <BTTOLD />,
  BURGER: <BURGER />,
  BUSD: <BUSD />,
  BZRX: <BZRX />,
  BZZ: <BZZ />,
  C98: <C98 />,
  CAKE: <CAKE />,
  CBG: <CBG />,
  CBK: <CBK />,
  CEEK: <CEEK />,
  CEL: <CEL />,
  CELO: <CELO />,
  CELR: <CELR />,
  CENNZ: <CENNZ />,
  CET: <CET />,
  CFG: <CFG />,
  CFX: <CFX />,
  CGU: <CGU />,
  CHESS: <CHESS />,
  CHR: <CHR />,
  CHSB: <CHSB />,
  CHZ: <CHZ />,
  CITY: <CITY />,
  CKB: <CKB />,
  CLT: <CLT />,
  CLV: <CLV />,
  COCOS: <COCOS />,
  COMP: <COMP />,
  COS: <COS />,
  COTI: <COTI />,
  COVAL: <COVAL />,
  CQT: <CQT />,
  CRE: <CRE />,
  CREAM: <CREAM />,
  CRO: <CRO />,
  CRODO: <CRODO />,
  CRTS: <CRTS />,
  CRV: <CRV />,
  CSPR: <CSPR />,
  CTK: <CTK />,
  CTSI: <CTSI />,
  CTX: <CTX />,
  CTXC: <CTXC />,
  CUBE: <CUBE />,
  CVC: <CVC />,
  CVP: <CVP />,
  CVX: <CVX />,
  DAD: <DAD />,
  DAI: <DAI />,
  DAR: <DAR />,
  DASH: <DASH />,
  DATA: <DATA />,
  DAWN: <DAWN />,
  DCR: <DCR />,
  DDX: <DDX />,
  DEGO: <DEGO />,
  DENT: <DENT />,
  DERC: <DERC />,
  DERO: <DERO />,
  DEXE: <DEXE />,
  DF: <DF />,
  DFI: <DFI />,
  DG: <DG />,
  DGB: <DGB />,
  DIA: <DIA />,
  DKA: <DKA />,
  DNT: <DNT />,
  DOCK: <DOCK />,
  DODO: <DODO />,
  DOGE: <DOGE />,
  DOT: <DOT />,
  DOTDOWN: <DOTDOWN />,
  DOTUP: <DOTUP />,
  DPI: <DPI />,
  DPR: <DPR />,
  DREP: <DREP />,
  DUSK: <DUSK />,
  DVF: <DVF />,
  DX: <DX />,
  DXD: <DXD />,
  DYDX: <DYDX />,
  EFI: <EFI />,
  EGG: <EGG />,
  EGLD: <EGLD />,
  ELF: <ELF />,
  ENJ: <ENJ />,
  ENS: <ENS />,
  EOS: <EOS />,
  EPS: <EPS />,
  EPX: <EPX />,
  ERG: <ERG />,
  ERN: <ERN />,
  ETC: <ETC />,
  ETH: <ETH />,
  ETHDOWN: <ETHDOWN />,
  ETHUP: <ETHUP />,
  ETN: <ETN />,
  EUM: <EUM />,
  EUR: <EUR />,
  EXRD: <EXRD />,
  EZ: <EZ />,
  FARM: <FARM />,
  FCT: <FCT />,
  FEI: <FEI />,
  FET: <FET />,
  FIDA: <FIDA />,
  FIL: <FIL />,
  FIO: <FIO />,
  FIRO: <FIRO />,
  FIS: <FIS />,
  FLETA: <FLETA />,
  FLOW: <FLOW />,
  FLUID: <FLUID />,
  FLUX: <FLUX />,
  FOR: <FOR />,
  FORTH: <FORTH />,
  FOX: <FOX />,
  FRAX: <FRAX />,
  FRONT: <FRONT />,
  FRTS: <FRTS />,
  FSN: <FSN />,
  FTM: <FTM />,
  FTT: <FTT />,
  FUN: <FUN />,
  FUSE: <FUSE />,
  FXS: <FXS />,
  GAL: <GAL />,
  GALA: <GALA />,
  GAS: <GAS />,
  GBP: <GBP />,
  GENE: <GENE />,
  GHST: <GHST />,
  GLM: <GLM />,
  GLMR: <GLMR />,
  GMT: <GMT />,
  GNO: <GNO />,
  GO: <GO />,
  GRS: <GRS />,
  GRT: <GRT />,
  GT: <GT />,
  GTC: <GTC />,
  GTO: <GTO />,
  GUSD: <GUSD />,
  GXC: <GXC />,
  GYEN: <GYEN />,
  HARD: <HARD />,
  HBAR: <HBAR />,
  HBTC: <HBTC />,
  HERO: <HERO />,
  HEZ: <HEZ />,
  HIGH: <HIGH />,
  HIVE: <HIVE />,
  HNS: <HNS />,
  HNT: <HNT />,
  HOO: <HOO />,
  HOPR: <HOPR />,
  HOT: <HOT />,
  HT: <HT />,
  HTR: <HTR />,
  HUNT: <HUNT />,
  HUSD: <HUSD />,
  HYDRA: <HYDRA />,
  HYN: <HYN />,
  ICHI: <ICHI />,
  ICP: <ICP />,
  ICX: <ICX />,
  IDEX: <IDEX />,
  IDRT: <IDRT />,
  ILV: <ILV />,
  IMX: <IMX />,
  INJ: <INJ />,
  IOST: <IOST />,
  IOTA: <IOTA />,
  IOTX: <IOTX />,
  IQ: <IQ />,
  IRIS: <IRIS />,
  JASMY: <JASMY />,
  JOE: <JOE />,
  JST: <JST />,
  JUV: <JUV />,
  KAI: <KAI />,
  KAVA: <KAVA />,
  KCS: <KCS />,
  KDA: <KDA />,
  KEEP: <KEEP />,
  KEY: <KEY />,
  KILT: <KILT />,
  KIN: <KIN />,
  KIRO: <KIRO />,
  KLAY: <KLAY />,
  KLV: <KLV />,
  KMD: <KMD />,
  KNC: <KNC />,
  KP3R: <KP3R />,
  KRT: <KRT />,
  KSM: <KSM />,
  KZEN: <KZEN />,
  LA: <LA />,
  LAT: <LAT />,
  LAZIO: <LAZIO />,
  LCX: <LCX />,
  LDO: <LDO />,
  LEO: <LEO />,
  LINA: <LINA />,
  LINK: <LINK />,
  LINKDOWN: <LINKDOWN />,
  LINKUP: <LINKUP />,
  LIT: <LIT />,
  LN: <LN />,
  LOG: <LOG />,
  LOKA: <LOKA />,
  LON: <LON />,
  LOOM: <LOOM />,
  LPT: <LPT />,
  LQTY: <LQTY />,
  LRC: <LRC />,
  LSK: <LSK />,
  LTC: <LTC />,
  LTO: <LTO />,
  LUNA: <LUNA />,
  LUNC: <LUNC />,
  LUSD: <LUSD />,
  MAID: <MAID />,
  MANA: <MANA />,
  MARO: <MARO />,
  MASK: <MASK />,
  MATH: <MATH />,
  MATIC: <MATIC />,
  MBL: <MBL />,
  MBOX: <MBOX />,
  MC: <MC />,
  MDA: <MDA />,
  MDT: <MDT />,
  MDX: <MDX />,
  MED: <MED />,
  META: <META />,
  MEV: <MEV />,
  MFT: <MFT />,
  MINA: <MINA />,
  MIOTA: <MIOTA />,
  MIR: <MIR />,
  MITH: <MITH />,
  MIX: <MIX />,
  MKR: <MKR />,
  MLK: <MLK />,
  MLN: <MLN />,
  MNGO: <MNGO />,
  MNW: <MNW />,
  MOB: <MOB />,
  MOF: <MOF />,
  MONA: <MONA />,
  MOVEZ: <MOVEZ />,
  MOVR: <MOVR />,
  MSOL: <MSOL />,
  MTL: <MTL />,
  MULTI: <MULTI />,
  MUSD: <MUSD />,
  MWC: <MWC />,
  MX: <MX />,
  MXC: <MXC />,
  NAS: <NAS />,
  NAV: <NAV />,
  NBS: <NBS />,
  NCT: <NCT />,
  NEAR: <NEAR />,
  NEBL: <NEBL />,
  NEO: <NEO />,
  NEXO: <NEXO />,
  NFT: <NFT />,
  NIF: <NIF />,
  NKN: <NKN />,
  NMC: <NMC />,
  NMR: <NMR />,
  NOIA: <NOIA />,
  NRG: <NRG />,
  NSBT: <NSBT />,
  NU: <NU />,
  NULS: <NULS />,
  NXM: <NXM />,
  NXS: <NXS />,
  NYE: <NYE />,
  OAX: <OAX />,
  OCEAN: <OCEAN />,
  OG: <OG />,
  OGN: <OGN />,
  OKB: <OKB />,
  OM: <OM />,
  OMG: <OMG />,
  ONE: <ONE />,
  ONG: <ONG />,
  ONT: <ONT />,
  ONUS: <ONUS />,
  OOKI: <OOKI />,
  OP: <OP />,
  ORBS: <ORBS />,
  ORC: <ORC />,
  ORN: <ORN />,
  OSMO: <OSMO />,
  OUSD: <OUSD />,
  OXEN: <OXEN />,
  OXT: <OXT />,
  PAXG: <PAXG />,
  PEAK: <PEAK />,
  PEOPLE: <PEOPLE />,
  PERL: <PERL />,
  PERP: <PERP />,
  PHA: <PHA />,
  PHB: <PHB />,
  PHL: <PHL />,
  PIVX: <PIVX />,
  PLA: <PLA />,
  PNK: <PNK />,
  PNT: <PNT />,
  POLS: <POLS />,
  POLY: <POLY />,
  POND: <POND />,
  PORTO: <PORTO />,
  POWR: <POWR />,
  PRE: <PRE />,
  PROM: <PROM />,
  PROS: <PROS />,
  "PRQ-1": <PRQOne />,
  PRQ: <PRQ />,
  PSG: <PSG />,
  PUNDIX: <PUNDIX />,
  PYR: <PYR />,
  QASH: <QASH />,
  QC: <QC />,
  QI: <QI />,
  QKC: <QKC />,
  QLC: <QLC />,
  QNT: <QNT />,
  QOM: <QOM />,
  QSP: <QSP />,
  QTUM: <QTUM />,
  QUACK: <QUACK />,
  QUICK: <QUICK />,
  RAD: <RAD />,
  RAI: <RAI />,
  RAMP: <RAMP />,
  RARE: <RARE />,
  RARI: <RARI />,
  RAY: <RAY />,
  RBN: <RBN />,
  REAP: <REAP />,
  REEF: <REEF />,
  REI: <REI />,
  REN: <REN />,
  RENBTC: <RENBTC />,
  REP: <REP />,
  REQ: <REQ />,
  REV: <REV />,
  RFOX: <RFOX />,
  RFR: <RFR />,
  RGT: <RGT />,
  RIF: <RIF />,
  RISE: <RISE />,
  RKN: <RKN />,
  RLC: <RLC />,
  RLY: <RLY />,
  RMRK: <RMRK />,
  RNDR: <RNDR />,
  ROOK: <ROOK />,
  ROSE: <ROSE />,
  RSR: <RSR />,
  RSS3: <RSS3 />,
  RUNE: <RUNE />,
  RVN: <RVN />,
  RVP: <RVP />,
  SAFE: <SAFE />,
  SAFEMOON: <SAFEMOON />,
  SAFFLE: <SAFFLE />,
  SAMO: <SAMO />,
  SAND: <SAND />,
  SANTOS: <SANTOS />,
  SC: <SC />,
  SCRT: <SCRT />,
  SDAO: <SDAO />,
  SDN: <SDN />,
  SERO: <SERO />,
  SFP: <SFP />,
  SFUND: <SFUND />,
  SHIB: <SHIB />,
  SIX: <SIX />,
  SKL: <SKL />,
  SLP: <SLP />,
  SNL: <SNL />,
  SNM: <SNM />,
  SNT: <SNT />,
  SNX: <SNX />,
  SOFI: <SOFI />,
  SOL: <SOL />,
  SOS: <SOS />,
  SOUL: <SOUL />,
  SPARTA: <SPARTA />,
  SPELL: <SPELL />,
  SPS: <SPS />,
  SQR: <SQR />,
  SRM: <SRM />,
  SSV: <SSV />,
  SSX: <SSX />,
  STAKE: <STAKE />,
  STARL: <STARL />,
  STEEM: <STEEM />,
  STETH: <STETH />,
  STMX: <STMX />,
  STORJ: <STORJ />,
  STPT: <STPT />,
  STRAX: <STRAX />,
  STRK: <STRK />,
  STX: <STX />,
  SUKU: <SUKU />,
  SUN: <SUN />,
  SUPER: <SUPER />,
  SUSHI: <SUSHI />,
  SWAP: <SWAP />,
  SWP: <SWP />,
  SXP: <SXP />,
  SYS: <SYS />,
  T: <T />,
  TCT: <TCT />,
  TEL: <TEL />,
  TFUEL: <TFUEL />,
  THETA: <THETA />,
  TIME: <TIME />,
  TITAN: <TITAN />,
  TKO: <TKO />,
  TLM: <TLM />,
  TLOS: <TLOS />,
  TNB: <TNB />,
  TOMO: <TOMO />,
  TON: <TON />,
  TORN: <TORN />,
  TPT: <TPT />,
  TRB: <TRB />,
  TRIBE: <TRIBE />,
  TROY: <TROY />,
  TRU: <TRU />,
  TRX: <TRX />,
  TRXDOWN: <TRXDOWN />,
  TRXUP: <TRXUP />,
  TT: <TT />,
  TTT: <TTT />,
  TUSD: <TUSD />,
  TVK: <TVK />,
  TWT: <TWT />,
  UFI: <UFI />,
  UFO: <UFO />,
  UFT: <UFT />,
  ULT: <ULT />,
  UMA: <UMA />,
  UNFI: <UNFI />,
  UNI: <UNI />,
  UQC: <UQC />,
  USDC: <USDC />,
  USDD: <USDD />,
  USDJ: <USDJ />,
  USDN: <USDN />,
  USDP: <USDP />,
  USDT: <USDT />,
  USTC: <USTC />,
  UTK: <UTK />,
  VBIT: <VBIT />,
  VEGA: <VEGA />,
  VERI: <VERI />,
  VET: <VET />,
  VGX: <VGX />,
  VIB: <VIB />,
  VIDT: <VIDT />,
  VITE: <VITE />,
  VLX: <VLX />,
  VOXEL: <VOXEL />,
  VR: <VR />,
  VRA: <VRA />,
  VTHO: <VTHO />,
  VUSDT: <VUSDT />,
  VXV: <VXV />,
  VXVS: <VXVS />,
  WABI: <WABI />,
  WAN: <WAN />,
  WAVES: <WAVES />,
  WAXP: <WAXP />,
  WBNB: <WBNB />,
  WBTC: <WBTC />,
  WEMIX: <WEMIX />,
  WILD: <WILD />,
  WIN: <WIN />,
  WING: <WING />,
  WNXM: <WNXM />,
  WOO: <WOO />,
  WOZX: <WOZX />,
  WRX: <WRX />,
  WTC: <WTC />,
  WTRX: <WTRX />,
  WXT: <WXT />,
  XAUT: <XAUT />,
  XCH: <XCH />,
  XCN: <XCN />,
  XDB: <XDB />,
  XDC: <XDC />,
  XEC: <XEC />,
  XEM: <XEM />,
  XHV: <XHV />,
  XLM: <XLM />,
  XMR: <XMR />,
  XNC: <XNC />,
  XNO: <XNO />,
  XPR: <XPR />,
  XPRT: <XPRT />,
  XRP: <XRP />,
  XRPDOWN: <XRPDOWN />,
  XRPUP: <XRPUP />,
  XTZ: <XTZ />,
  XVG: <XVG />,
  XVS: <XVS />,
  XWC: <XWC />,
  XYM: <XYM />,
  XYO: <XYO />,
  YFI: <YFI />,
  YFII: <YFII />,
  YGG: <YGG />,
  YLD: <YLD />,
  YOOSHI: <YOOSHI />,
  YOUC: <YOUC />,
  ZAM: <ZAM />,
  ZB: <ZB />,
  ZEC: <ZEC />,
  ZEN: <ZEN />,
  ZEON: <ZEON />,
  ZIL: <ZIL />,
  ZRX: <ZRX />,
  KAR: <KAR />,
  APT: <APT />,
  NoIcon: <NoIcon />,
};

// export const cointNames = [];
// for (const key in COIN_ICONS) {
//   cointNames.push(key as never);
// }

// const diff = assetBookmarks.filter(  // all coins
//   (item) => !cointNames.includes(item as never) // svg
// );

// console.log("diff", diff);
