import React from "react";
import style from "./styles/index.module.scss";

interface IAssetItem {
  data: string;
  onClick: (data: string) => void;
}

const AssetItem = ({ data, onClick }: IAssetItem) => {
  return (
    <div className={style.item} onClick={() => onClick(data)}>
      <span className={style.pair}>{data}</span>
    </div>
  );
};

export default React.memo(AssetItem);
