import { axiosInstance } from "../../apiConfing/instance";
import {
  IAuthenticationParam,
  IAuthenticationParamAsync,
  IPasswordChangeParam,
  IResetInstructionsParam,
  IResetPasswordParams,
} from "./models";

export const registrationService = async (
  params: IAuthenticationParamAsync
) => {
  return await axiosInstance.post("/signin", params);
};

export const loginService = async (
  params: IAuthenticationParamAsync
): Promise<IAuthenticationParam> => {
  return await axiosInstance.post("/login", params);
};

export const changePasswordService = async (
  params: IPasswordChangeParam
): Promise<void> => {
  await axiosInstance.post("/passwordchange", params);
};

export const sendResetInstructionService = async (
  params: IResetInstructionsParam
): Promise<void> => {
  await axiosInstance.post("/codereset/send", params);
};

export const resetPasswordService = async (
  params: IResetPasswordParams
): Promise<void> => {
  await axiosInstance.post("/codereset/enter", params);
};
