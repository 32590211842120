import { createSlice } from "@reduxjs/toolkit";
import { INotificationModal } from "./models";

const initialState: INotificationModal = {
  modalContent: "",
};

export const { actions: notificationModalActions, reducer } = createSlice({
  name: "NotificationModal",
  initialState,
  reducers: {
    addNotificationModal: (
      state: INotificationModal,
      { payload }: { payload: string }
    ) => {
      state.modalContent = payload;
    },
    removeNotificationModal: (state: INotificationModal) => {
      state.modalContent = "";
    },
  },
});
