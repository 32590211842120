import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../../commonComponents/Button";
import { useDispatch } from "react-redux";
import { wsActualDataActions } from "../../store/reducers/WSActualData/slice";
import { authenticationActions } from "../../store/reducers/Authentication/slice";
import { ReactComponent as LogoSVG } from "../../assets/icons/faviconLogo.svg";
import { ReactComponent as GridSVG } from "../../assets/icons/view-grid.svg";
import { ReactComponent as UserSVG } from "../../assets/icons/user.svg";
import { ReactComponent as ServerSVG } from "../../assets/icons/server.svg";
import { ReactComponent as BillingSVG } from "../../assets/icons/billing.svg";
import { ReactComponent as LogoutSVG } from "../../assets/icons/logout.svg";
import { ReactComponent as TransferSVG } from "../../assets/icons/data-transfer-up.svg";
import { exchangesActions } from "../../store/reducers/Exchange/slice";
import { SupportContainer } from "../SupportContainer";
//helpers
import { buttonTypes } from "../../commonComponents/Button/constants";
import { loadingActions } from "../../store/reducers/Loading/slice";
import urls from "../../routes/urls";
//styles
import style from "./styles/index.module.scss";
import { useHistory } from "react-router-dom";

export const Aside = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <aside className={style.AsideContainer}>
      <h1
        className={style.logo}
        onClick={() => history.push(urls.ASIDE_ROUTES.BASE_DASHBOARD_PAGE)}
      >
        <LogoSVG />
        <label>
          Lets<span>Bulls</span>
        </label>
      </h1>
      <nav className={style.linkContainer}>
        <Button
          type={buttonTypes.link}
          path={urls.ASIDE_ROUTES.BASE_DASHBOARD_PAGE}
          title={t("links.dashboard")}
          icon={<GridSVG />}
        />

        <Button
          type={buttonTypes.link}
          path={urls.ASIDE_ROUTES.BASE_PORTFOLIO_PAGE}
          title={t("links.portfolio")}
          icon={<TransferSVG />}
        />

        <Button
          type={buttonTypes.link}
          path={urls.ASIDE_ROUTES.BASE_STRATEGY_PAGE}
          title={t("links.strategy")}
          icon={<ServerSVG />}
          // isComingSoon={true}
        />

         <Button
          type={buttonTypes.link}
          path={urls.ASIDE_ROUTES.BASE_BILLING_PAGE}
          title={t("links.billing")}
          icon={<BillingSVG />}
        />

        <Button
          type={buttonTypes.link}
          path={urls.ASIDE_ROUTES.BASE_ACCOUNT_PAGE}
          title={t("links.account")}
          icon={<UserSVG />}
        />

        <Button
          type={buttonTypes.link}
          title={t("buttons.logOut")}
          icon={<LogoutSVG />}
          onClick={() => {
            dispatch(authenticationActions.logout());
            dispatch(wsActualDataActions.clearWsActualDataState());
            dispatch(loadingActions.setWsStatus(false));
            dispatch(exchangesActions.clear());
          }}
        />
      </nav>
      <section className={style.supportContainer}>
        <SupportContainer />
      </section>
    </aside>
  );
};
