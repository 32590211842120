// React
import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
// Component
import { Select } from "../../commonComponents/Select";
import { CustomInput } from "../../commonComponents/CustomInput";
import { ReactComponent as PauseSVG } from "../../assets/icons/pause.svg";
import { ReactComponent as CheckedSVG } from "../../assets/icons/checked.svg";
import Button from "../../commonComponents/Button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/storeConfig";
import { firstLetterToUppercase } from "../../utils/dateFormatter";
import { strategiesActions } from "../../store/reducers/Strategies/slice";
import { useAsyncDispatch } from "../../store/utils";
// models
import { SelectTypes } from "../../commonComponents/Select/models";
// helpers
import { buttonTypes } from "../../commonComponents/Button/constants";
// styles
import style from "./styles/index.module.scss";

export const strategyDetailOptionsMock = {
  pairs: [
    { value: "BTC/USD", label: "BTC / USD" },
    { value: "LTC/USD", label: "LTC / USD" },
    { value: "DASH/USD", label: "DASH / USD" },
  ],
  type: [
    { value: "long", label: "Long" },
    { value: "short", label: "Short" },
  ],
};

export const StrategyParams = () => {
  const dispatch = useDispatch();
  const asyncDispatch = useAsyncDispatch();
  const { t } = useTranslation();
  const { connectedExchanges } = useSelector(
    (state: RootState) => state.Exchanges
  );
  const { strategyParams, activeStrategyDetailParams } = useSelector(
    (state: RootState) => state.Strategies
  );
  const exchanges = connectedExchanges?.map((item) => {
    return {
      value: item.exchange,
      label: firstLetterToUppercase(item.exchange),
      api_key: item.api_key,
    };
  });

  const changeInput = (e: FormEvent<HTMLInputElement>) => {
    dispatch(
      strategiesActions.setActiveStrategyDetailParams({
        ...activeStrategyDetailParams,
        [e.currentTarget.name]: e.currentTarget.value,
      })
    );
  };

  const handleSelectExchanges = (newValue: any) => {
    newValue &&
      dispatch(
        strategiesActions.setActiveStrategyDetailParams({
          ...activeStrategyDetailParams,
          exchange: newValue,
        })
      );
  };

  const handleSelectType = (newValue: any) => {
    newValue &&
      dispatch(
        strategiesActions.setActiveStrategyDetailParams({
          ...activeStrategyDetailParams,
          type: newValue,
        })
      );
  };

  const handleSelectPair = (newValue: any) => {
    newValue &&
      dispatch(
        strategiesActions.setActiveStrategyDetailParams({
          ...activeStrategyDetailParams,
          pair: newValue,
        })
      );
  };

  const onSave = () => {
    asyncDispatch(strategiesActions.addGridStrategyAsync, {
      ...activeStrategyDetailParams,
      exchange: activeStrategyDetailParams.exchange.value || exchanges[0].value,
      pair: activeStrategyDetailParams.pair.value,
      type: activeStrategyDetailParams.type.value,
      apiKey: activeStrategyDetailParams.exchange.api_key,
    });
  };
  return (
    <div className={style.StrategyParamsContainer}>
      <h2 className={style.title}>{t("strategyPage.strategyParams")}</h2>
      <section className={style.form}>
        <div className={style.controls}>
          <Select
            label={t("strategyPage.selectExchange")}
            options={exchanges}
            type={SelectTypes.Big}
            onChange={handleSelectExchanges}
            defaultValue={activeStrategyDetailParams.exchange}
          />
          <Select
            label={t("strategyPage.selectPair")}
            options={strategyDetailOptionsMock.pairs}
            type={SelectTypes.Big}
            onChange={handleSelectPair}
            defaultValue={activeStrategyDetailParams.pair}
          />
          <Select
            label={t("strategyPage.selectType")}
            options={strategyDetailOptionsMock.type}
            type={SelectTypes.Big}
            onChange={handleSelectType}
            defaultValue={activeStrategyDetailParams.type}
          />
          <CustomInput
            label={t("strategyPage.enterTheInvestmentAmount")}
            onChange={changeInput}
            name="investmentAmount"
            value={activeStrategyDetailParams.investmentAmount}
            type={"number"}
          />
          <CustomInput
            label={t("strategyPage.topPrice")}
            onChange={changeInput}
            name="topPrice"
            value={activeStrategyDetailParams.topPrice}
            type={"number"}
          />
          <CustomInput
            label={t("strategyPage.lowerPrice")}
            onChange={changeInput}
            name="lowPrice"
            value={activeStrategyDetailParams.lowPrice}
            type={"number"}
          />
          <CustomInput
            label={"sellPrice"}
            onChange={changeInput}
            name="sellPrice"
            value={activeStrategyDetailParams.sellPrice}
            type={"number"}
          />
          <CustomInput
            label={"buyPrice"}
            onChange={changeInput}
            name="buyPrice"
            value={activeStrategyDetailParams.buyPrice}
            type={"number"}
          />
          <CustomInput
            label={t("strategyPage.gridStep")}
            onChange={changeInput}
            name="gridStep"
            value={activeStrategyDetailParams.gridStep}
            type={"number"}
          />
          <CustomInput
            label={t("strategyPage.numberOfLevels")}
            onChange={changeInput}
            name="levels"
            value={activeStrategyDetailParams.levels}
            type={"number"}
          />
        </div>

        <div className={style.buttons}>
          {!strategyParams.isNew && (
            <>
              <Button
                icon={<PauseSVG />}
                type={buttonTypes.primary}
                title={t("strategyPage.stopped")}
              />
              <Button
                icon={<CheckedSVG />}
                type={buttonTypes.primary}
                title={t("strategyPage.edit")}
              />
            </>
          )}

          <Button
            icon={<CheckedSVG />}
            type={buttonTypes.primary}
            title={
              strategyParams.isNew
                ? t("strategyPage.start")
                : t("strategyPage.save")
            }
            onClick={onSave}
          />
        </div>
      </section>
    </div>
  );
};
