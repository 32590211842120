//react
import React from "react";
// utils
import { convertMsToDate } from "../../../utils/dateFormatter";
import { formatGraphPrice } from "../../../utils/priceFormatter";
// styles
import styles from "../styles/index.module.scss";

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className={styles.tooltipBox}>
        {payload.map((item: any, idx: number) => (
          <div className={styles.usdAmount} key={idx}>
            $ {formatGraphPrice(item.value)}
          </div>
        ))}

        <div className={styles.date}>{convertMsToDate(label)}</div>
      </div>
    );
  }

  return null;
};
