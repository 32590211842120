// React
import React from "react";

// Component
import { default as ReactSelect } from "react-select";

// models
import { SelectProps } from "./models";

// utils
import { classes } from "../../utils/classes";

// styles
import style from "./styles/index.module.scss";
import { Styles } from "./styles/selectStyles";

export const Select = (props: SelectProps) => {
  const {
    label,
    value,
    options,
    type = "default",
    onChange,
    defaultValue,
    isSearchable,
    className,
    classNamePrefix,
  } = props;

  return (
    <label className={classes(style.SelectContainer, className)}>
      {label && <span className={style.label}>{label}</span>}
      {value && <span className={style.value}>{value}</span>}
      <ReactSelect
        onChange={onChange}
        options={options}
        classNamePrefix={classNamePrefix}
        styles={Styles(type)}
        placeholder=""
        defaultValue={defaultValue || options[0]}
        isSearchable={!!isSearchable || false}
      />
    </label>
  );
};
