// react
import React, { useCallback } from "react";
import { Scrollbars } from "react-custom-scrollbars";

const CustomScrollbars = ({ onScroll, forwardedRef, style, children }: any) => {
  const refSetter = useCallback((scrollbarsRef: any) => {
    if (scrollbarsRef) {
      forwardedRef(scrollbarsRef.view);
    } else {
      forwardedRef(null);
    }
  }, []);

  const renderThumb = ({ style, ...props }: any) => {
    const thumbStyle = {
      backgroundColor: `#676a81`,
      borderRadius: `5px`,
      cursor: "pointer",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  return (
    <Scrollbars
      ref={refSetter}
      style={{ ...style, overflow: "hidden" }}
      onScroll={onScroll}
      thumbMinSize={40}
      renderThumbVertical={renderThumb}
    >
      {children}
    </Scrollbars>
  );
};

export const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => (
  <CustomScrollbars {...props} forwardedRef={ref} />
));
