import React, { ComponentType } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { RootState } from "../store/storeConfig";
import urls from "./urls";

interface PublicRouteProps extends RouteProps {
  component: ComponentType;
}

export const PublicRoute = (props: PublicRouteProps) => {
  const { component: Component, ...rest } = props;
  const isAuth = useSelector(
    (store: RootState) => store.Authentication.isAuthenticated
  );

  return (
    <Route
      {...rest}
      exact
      render={() =>
        !isAuth ? (
          <Component />
        ) : (
          <Redirect push to={urls.BASE_ROUTES.BASE_APP_PAGE} />
        )
      }
    />
  );
};
