import { createSlice } from "@reduxjs/toolkit";
import noop from "lodash/noop";
import { asyncReducer } from "../../utils";
import {
  IAuthentication,
  IAuthenticationParam,
  IAuthenticationParamAsync,
  IPasswordChangeParam,
  IResetInstructionsParam,
  IResetPassword,
} from "./models";

export const initialState: IAuthentication = {
  isAuthenticated: false,
  token: "",
};

export const { actions: authenticationActions, reducer } = createSlice({
  name: "Authentication",
  initialState,
  reducers: {
    login: (
      state: IAuthentication,
      { payload }: { payload: IAuthenticationParam }
    ) => {
      state.isAuthenticated = true;
      state.token = payload.token;
    },
    logout: (state: IAuthentication) => {
      sessionStorage.clear();
      state.isAuthenticated = false;
      state.token = "";
    },
    loginAsync: asyncReducer<IAuthentication, IAuthenticationParamAsync>(noop),
    sendResetInstructionsAsync: asyncReducer<
      IAuthentication,
      IResetInstructionsParam
    >(noop),
    resetPasswordAsync: asyncReducer<IAuthentication, IResetPassword>(noop),
    changePasswordAsync: asyncReducer<IAuthentication, IPasswordChangeParam>(
      noop
    ),
    registrationAsync: asyncReducer<IAuthentication, IAuthenticationParamAsync>(
      noop
    ),
  },
});
