// React
import React, { useLayoutEffect } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
// redux
import { RootState } from "../../store/storeConfig";
import { exchangesActions } from "../../store/reducers/Exchange/slice";
// Component
import { Header } from "../Header";
import { Aside } from "../Aside";
//pages
import DashboardPage from "../../pages/DashboardPage";
const AccountPage = React.lazy(() => import("../../pages/AccountPage"));
const PortfolioPage = React.lazy(() => import("../../pages/PortfolioPage"));

const StrategyDetailPage = React.lazy(
  () => import("../../pages/StrategyDetailPage")
);
const StrategyPage = React.lazy(() => import("../../pages/StrategyPage"));

const TokenDetailsPage = React.lazy(
  () => import("../../pages/TokenDetailsPage")
);
const AboutPage = React.lazy(() => import("../../pages/AboutPage"));
const BillingPage = React.lazy(() => import("../../pages/BillingPage"));
//components
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../Spinner";
import { ConnectExchangeOverlay } from "../ConnectExchangeOverlay";
import { isDeepEqual } from "../../utils/isEqual";
import { loadingActions } from "../../store/reducers/Loading/slice";
import { wsActualDataActions } from "../../store/reducers/WSActualData/slice";
import { useAsyncDispatch } from "../../store/utils";
import { SuspenseLayout } from "../SuspenseLayout";
// constants
import urls from "../../routes/urls";
// style
import style from "./styles/index.module.scss";
import { IExchangeData } from "../../store/reducers/Exchange/models";

export const PageController = () => {
  const isLoading = useSelector(
    (state: RootState) => state.Loading.loadingProccess
  );
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const asyncDispatch = useAsyncDispatch();
  useLayoutEffect(() => {
    asyncDispatch(wsActualDataActions.openWSActualData);
    asyncDispatch(exchangesActions.getAvailableExchangesAsync);
    asyncDispatch(exchangesActions.getConnectedExchangesAsync);
    dispatch(loadingActions.startLoading());
    return () => {
      asyncDispatch(wsActualDataActions.closeWSActualData);
    };
  }, []);

  const connectedExchanges = useSelector(
    (state: RootState) => state.Exchanges.connectedExchanges,
    isDeepEqual
  );
  const inactiveExchanges = connectedExchanges.filter(
    (exchange: IExchangeData) => !exchange.is_active
  );
  const activate = inactiveExchanges.length || !connectedExchanges.length;

  return (
    <div className={style.PageControllerContainer}>
      {[
        urls.ASIDE_ROUTES.BASE_DASHBOARD_PAGE,
        urls.ASIDE_ROUTES.BASE_PORTFOLIO_PAGE,
      ].includes(pathname) && <Spinner isLoading={isLoading} />}
      {!isLoading && activate && (
        <ConnectExchangeOverlay inactiveExchanges={inactiveExchanges} />
      )}
      <Aside />
      <Header />
      <Switch>
        <Route exact path={urls.ASIDE_ROUTES.BASE_DASHBOARD_PAGE}>
          <DashboardPage />
        </Route>
        <Route exact path={urls.ASIDE_ROUTES.BASE_PORTFOLIO_PAGE}>
          <SuspenseLayout element={<PortfolioPage />} />
        </Route>
        <Route exact path={urls.INTERNAL_PORTFOLIO_PAGE.TOKEN_DETAILS}>
          <SuspenseLayout element={<TokenDetailsPage />} />
        </Route>
        <Route exact path={urls.ASIDE_ROUTES.BASE_BILLING_PAGE}>
          <SuspenseLayout element={<BillingPage />} />
        </Route>
        <Route exact path={urls.ASIDE_ROUTES.BASE_ACCOUNT_PAGE}>
          <SuspenseLayout element={<AccountPage />} />
        </Route>
        <Route exact path={urls.INTERNAL_STRATEGY_PAGE.DETAIL_STRATEGY}>
          <SuspenseLayout element={<StrategyDetailPage />} />
        </Route>
        <Route exact path={urls.ASIDE_ROUTES.BASE_STRATEGY_PAGE}>
          <SuspenseLayout element={<StrategyPage />} />
        </Route>
        <Route exact path={urls.INTERNAL_STRATEGY_PAGE.ABOUT_STRATEGY}>
          <SuspenseLayout element={<AboutPage />} />
        </Route>
        <Redirect to={urls.ASIDE_ROUTES.BASE_DASHBOARD_PAGE} />
      </Switch>
    </div>
  );
};
