// React
import React from "react";
// helpers
import { useTranslation } from "react-i18next";
import { AssetItem } from "./AssetItem";
import { useSelector } from "react-redux";
import { RootState } from "../../store/storeConfig";
import { IAssetItemData } from "../../store/reducers/WSActualData/models";
import { PageType } from "../../utils/constants";
import { classes } from "../../utils/classes";
import { MiniCard } from "../MiniCard";
import { assetsSelector } from "store/reducers/WSActualData/selectors";
// styles
import style from "./styles/index.module.scss";

interface IAssets {
  type: PageType;
}

export const Assets = ({ type }: IAssets) => {
  const { t } = useTranslation();
  const assetsList: IAssetItemData[] = useSelector(assetsSelector);
  const selectedExchange = useSelector(
    (state: RootState) => state.Exchanges.selectedExchange
  );

  return (
    <div
      className={classes(
        style.ResourcesInfoContainer,
        type === PageType.Dashboard && style.ResourcesInfoContentDashboard
      )}
    >
      <h2 className={style.title}>{t("portfolioPage.yourAssets")}</h2>
      <div
        className={classes(
          type === PageType.Portfolio && style.PortfolioAssetsOverflow
        )}
      >
        {type === PageType.Portfolio && (
          <div className={style.ResourcesInfoContent}>
            <div
              className={classes(style.row, style.gridRow, style.gridRowHeader)}
            >
              <>
                <MiniCard description={t("portfolioPage.logo")} transparent />
                <MiniCard
                  className={style.name}
                  description={t("portfolioPage.name")}
                  transparent
                />
                <MiniCard
                  description={t("portfolioPage.balance")}
                  transparent
                />
                <MiniCard
                  description={t("portfolioPage.marketValue")}
                  transparent
                />
                <MiniCard
                  description={t("portfolioPage.marketValueU")}
                  transparent
                />
                <MiniCard
                  description={t("portfolioPage.changes24")}
                  transparent
                  rightAlignment
                />
              </>
            </div>
          </div>
        )}
        <div className={style.ResourcesInfoContent}>
          {assetsList.map((assetItem: IAssetItemData) => (
            <AssetItem
              type={type}
              item={assetItem}
              key={assetItem.name}
              selectedExchange={selectedExchange}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
