import { IDataRange } from "./models";

export const getInitialDateRange = (): IDataRange => {
  const currentDate = new Date();
  return {
    startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    endDate: currentDate,
    key: "selection",
  };
};

export const convert = (str: Date | string) => {
  return new Date(str).toLocaleDateString("ru-RU");
};

export const stringToDate = (string: string) => {
  const [day, month, year] = string.split(".");

  return new Date(+year, +month - 1, +day);
};

export const validateDateRegExp =
  /^(?:(?:0[1-9]|[12]\d|3[01])\.(?:0[13578]|1[02])\.(?:199\d|2[0-2]\d\d))|(?:(?:0[1-9]|[12]\d|30)\.(?:0[13-9]|1[0-2])\.(?:199\d|2[0-2]\d\d))|(?:(?:0[1-9]|1\d|2[0-8])\.02\.(?:199\d|2[0-2]\d\d))|(?:(?:29\.02\.(?:[12][0-29])(?:0[048]|[13579][26]|[2468][048])))$/;

export const maskDate = (dateStr: string) => {
  if (!dateStr) return "";
  const clearedDate = dateStr.replace(/[^\d]/g, "");
  const { length } = clearedDate;

  if (length < 2) return clearedDate;
  if (length < 5) return clearedDate.replace(/(\d{2})(\d{1,2})/, "$1.$2");
  return clearedDate.replace(/(\d{2})(\d{2})(\d{1,4})\d*/, "$1.$2.$3");
};
