//react
import React, { useRef } from "react";
// components
import { ReactComponent as CloseModal } from "./images/closeButton.svg";
//styles
import style from "./styles/index.module.scss";
//models
import { IModalWindowProps } from "./models";
//tools
import { classes } from "../../../utils/classes";
import { useClickOutside } from "utils/useClickOutside";
import { error } from "../../../constants";

export const ModalWindow = ({
  title,
  active,
  deactivate,
  hiddenModal,
  type,
  children,
  className,
  ...otherProps
}: IModalWindowProps) => {
  const modalRef = useRef<any>(null);
  useClickOutside(modalRef, () => deactivate());

  return (
    <div
      className={classes(
        style.modalContainer,
        active && style.activeModal,
        hiddenModal && style.hiddenModal,
        type === error && style.error
      )}
      role="dialog"
      aria-modal="true"
      {...otherProps}
    >
      <div
        className={classes(
          style.modalContent,
          active && style.activeContent,
          className && style[className]
        )}
        ref={modalRef}
      >
        {title && <h2 className={style.title}>{title}</h2>}

        <button onClick={() => deactivate()} className={style.closeButton}>
          <CloseModal />
        </button>

        {children}
      </div>
    </div>
  );
};
