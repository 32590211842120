// React
import React from "react";

// Component
import { Routes } from "../routes";

// add some config

//locale
import "../localization/i18n";

export const App = () => {
  return <Routes />;
};
