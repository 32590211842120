import { createSlice } from "@reduxjs/toolkit";
import noop from "lodash/noop";
import { asyncReducer } from "../../utils";
import { IBookmark } from "../WSActualData/models";
import { IAssetBookmarks } from "./models";

export const initialState: IAssetBookmarks = {
  availableBookmarkPairs: [],
};

export const { actions: assetBookmarksActions, reducer } = createSlice({
  name: "AssetBookmarks",
  initialState,
  reducers: {
    getAssetBookmarksPairs: (
      state: IAssetBookmarks,
      { payload }: { payload: string[] }
    ) => {
      state.availableBookmarkPairs = payload;
    },
    getAvailableBookmarkPairsAsync: asyncReducer<IAssetBookmarks>(noop),
    setBookmarksAsync: asyncReducer<IAssetBookmarks, string>(noop),
    changeBookmarksOrderAsync: asyncReducer<IAssetBookmarks, IBookmark[]>(noop),
  },
});
