export const ADD_MARKET_FIELDS = {
  EXCHANGE: "exchange",
  API_KEY: "api_key",
  SECRET_KEY: "api_secret",
  PASS_PHRASE: "api_passphrase",
};

export const STEP_NAMES = {
  ADD_EXCHANGE: "ADD_EXCHANGE",
  EXCHANGE_REQ_SUCCESS: "EXCHANGE_REQ_SUCCESS",
  MANAGE_EXCHANGES: "MANAGE_EXCHANGES",
  CONFIRM_DELETION: "CONFIRM_DELETION",
  UPDATE_EXCHANGE: "UPDATE_EXCHANGE",
};
