import {
  take,
  put,
  call,
  fork,
  ActionPattern,
  takeLatest,
} from "redux-saga/effects";
import { eventChannel, END } from "redux-saga";
import { wsActualDataActions } from "./slice";
import { store } from "../../storeConfig";
import { withTryCatch } from "../../utils";
import { loadingActions } from "../Loading/slice";
import {
  deleteExeptionTokenService,
  sendExeptionTokenService,
} from "./services";
import { notificationModalActions } from "../NotificationModal/slice";
import { t } from "i18next";
import { IExceptionForAdd } from "./models";
import { PageType } from "../../../utils/constants";
import { errorInterceptorModalgActions } from "../ErrorInterceptorModal/slice";

export function webSocketListener(serviceWebSocket: WebSocket) {
  return eventChannel((emitter) => {
    serviceWebSocket.onmessage = ({ data }: MessageEvent) => emitter(data);
    serviceWebSocket.onopen = () => {
      console.log("ws open");
      emitter(true);
    };
    serviceWebSocket.onclose = () => {
      console.log("ws close");
      emitter(END);
    };
    serviceWebSocket.onerror = () => emitter(END);
    return () => serviceWebSocket.close();
  });
}

export function* webSocketWorker(): Generator {
  try {
    const serviceWebSocket = new WebSocket(`${process.env.REACT_APP_WS}`);
    const socket = yield call(webSocketListener, serviceWebSocket);
    yield fork(sendWSMessageWorker, serviceWebSocket);
    yield fork(closeWSWorker, serviceWebSocket);

    // subscribe to channel
    while (true) {
      const payload: any = yield take(socket as ActionPattern);
      if (payload === true) {
        yield put(loadingActions.setWsStatus(true));
      } else {
        const parsedData = JSON.parse(payload);

        if (parsedData.code == 200) {
          yield put(wsActualDataActions.getWsActualData(parsedData));
        } else {
          yield put(
            errorInterceptorModalgActions.showModal({
              isShowModal: true,
              modalContent: t("errors.failedGeData"),
            })
          );
        }
        yield put(loadingActions.stopLoading());
      }
    }
  } finally {
    // channel diconnected
    // yield put(
    //   putMessage({
    //     list: [{ user: "Client", text: "Disconnected from server." }],
    //     status: "disconnected",
    //   })
    // );
  }
}

export function* sendWSMessageWorker(serviceWebSocket: WebSocket) {
  while (true) {
    const { payload } = yield take(
      wsActualDataActions.sendWSMessage.toString()
    );
    try {
      console.log("send message");
      payload.token = store.getState().Authentication.token;
      yield call([serviceWebSocket, "send"], JSON.stringify(payload));
    } catch (error) {
      // yield put(
      //   putMessage({
      //     list: [{ user: "Client", text: "Error sending last message." }],
      //   })
      // );
    }
  }
}

export function* closeWSWorker(serviceWebSocket: WebSocket) {
  while (true) {
    yield take(wsActualDataActions.closeWSActualData);
    try {
      yield call([serviceWebSocket, "close"]);
    } catch (error) {
      // yield put(
      //   putMessage({
      //     list: [{ user: "Client", text: "Error sending last message." }],
      //   })
      // );
    }
  }
}

export function* fixExeptionTokenWorker({
  payload,
}: {
  payload: IExceptionForAdd;
}) {
  yield call(deleteExeptionTokenService, {
    exchange: payload.exchange,
    id: payload.for_order_id,
  });
  yield call(sendExeptionTokenService, payload);
  yield put({
    type: "WSActualData/sendWSMessage",
    payload: {
      action: PageType.Portfolio,
      params: {
        exchange: store.getState().Exchanges.selectedExchange,
      },
    },
  });
  yield put(
    notificationModalActions.addNotificationModal(
      t("notification.successfullyTransactionSent")
    )
  );
}

export function* webSocketWatcher(): Generator {
  yield takeLatest(
    wsActualDataActions.openWSActualData,
    withTryCatch(webSocketWorker)
  );
  yield takeLatest(
    wsActualDataActions.fixExeptionToken,
    withTryCatch(fixExeptionTokenWorker)
  );
}
