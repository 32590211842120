// React
import React, { FormEvent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAsyncDispatch } from "../../store/utils";
import { authenticationActions } from "../../store/reducers/Authentication/slice";
import Button from "../../commonComponents/Button";
import { Input } from "../../commonComponents/Input";
import { handleEnterDown } from "../../utils/keyDownHandler";
// styles
import style from "./styles/index.module.scss";

export const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const asyncDispatch = useAsyncDispatch();
  const history = useHistory();
  const [formValue, setFormValue] = useState({
    password: "",
    repeatPassword: "",
  });
  const [isPasswordMismatch, setIsPasswordMismatch] = useState(false);
  const params = useParams<{ token: string }>();

  const onKeyDownReset = (e: React.KeyboardEvent<HTMLElement>) => {
    handleEnterDown(e, onResetPassword);
  };

  useEffect(() => {
    if (
      formValue.password !== formValue.repeatPassword &&
      formValue.repeatPassword &&
      formValue.password
    ) {
      return setIsPasswordMismatch(true);
    }
    return setIsPasswordMismatch(false);
  }, [formValue.password, formValue.repeatPassword]);

  const onResetPassword = async () => {
    await asyncDispatch(authenticationActions.resetPasswordAsync, {
      params: {
        code: params.token,
        newpassword: formValue.password,
      },
      history,
    });
  };

  const changeForm = (e: FormEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      [e.currentTarget.name]: e.currentTarget.value.trim(),
    });
  };

  return (
    <div className={style.LoginPageContainer} onKeyDown={onKeyDownReset}>
      <div className={style.LoginPageContent}>
        <div className={style.ressetPasswordLogo}>
          {t("loginPage.resetPassword")}
        </div>
        <section>
          <Input
            name="password"
            value={formValue.password}
            placeholder={t("resetPasswordPage.newPasswordLabel")}
            onChange={changeForm}
            autoComplete="new-password"
            isPassword
          />

          <Input
            name="repeatPassword"
            value={formValue.repeatPassword}
            placeholder={t("loginPage.repeatPasswordLabel")}
            onChange={changeForm}
            autoComplete="new-password"
            isPassword
          />

          {isPasswordMismatch && (
            <div className={style.errorLabel}>
              {t("loginPage.passwordMismatch")}
            </div>
          )}
          <div className={style.ResetPageButtons}>
            <Button
              onClick={onResetPassword}
              title={t("resetPasswordPage.resetButton")}
              disabled={
                !(
                  formValue.password &&
                  formValue.password === formValue.repeatPassword
                )
              }
            />
          </div>
        </section>
      </div>
    </div>
  );
};
