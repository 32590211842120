// React
import React, { useRef } from "react";
// utils
import { classes } from "../../utils/classes";
// models
import { CustomInputProps } from "./models";
// styles
import style from "./styles/index.module.scss";

export const CustomInput = ({
  label,
  placeholder,
  onChange,
  name,
  value,
  className,
  disabled,
  type,
  pattern,
}: CustomInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <form
      className={classes(style.CustomInputContainer, className)}
      onFocus={() => {
        inputRef.current && inputRef.current.focus();
      }}
      tabIndex={1}
      autoComplete="new-password"
    >
      <label>{label}</label>
      <input
        disabled={disabled}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        value={value}
        ref={inputRef}
        type={type}
        pattern={pattern}
        autoComplete="new-password"
      />
    </form>
  );
};
