import { axiosInstance } from "../../apiConfing/instance";
import { ASSETS_PAIRS_URL, SET_BOOKMARKS_URL } from "./constants";
import { ISetBookmarksBody } from "./models";

export const fetchAvailablePairs = async () => {
  return await axiosInstance.get<string[]>(ASSETS_PAIRS_URL);
};

export const setBookmarksRequest = async (body: ISetBookmarksBody) => {
  await axiosInstance.post(SET_BOOKMARKS_URL, body);
};
