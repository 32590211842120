import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
// models
import {
  IAddExchangeBody,
  IDeleteExchangeParams,
  IExchangeData,
  IUpdateExchangeParams,
} from "./models";
// services
import {
  addExchangeRequest,
  deleteExchange,
  fetchAvailableExchanges,
  fetchConnectedExchanges,
} from "./services";
// constants
import { store } from "../../storeConfig";
import { ALL_EXCHANGES, ExchangesManagerStutuses } from "./constants";
import { withTryCatch } from "../../utils";
import { exchangesActions } from "./slice";
import { loadingActions } from "../Loading/slice";

function* addExchangeWorker({ payload }: { payload: IAddExchangeBody }) {
  yield call(addExchangeRequest, payload);
  yield put(
    exchangesActions.exchangeRequestStatus(ExchangesManagerStutuses.add)
  );
  yield call(getConnectedExchangesWorker);
  yield put(exchangesActions.selectExchange(payload.exchange));
}

function* getAvailableExchangesWorker() {
  const response: AxiosResponse<string[]> = yield call(fetchAvailableExchanges);
  yield put(exchangesActions.getAvailableExchanges(response.data));
}

function* getConnectedExchangesWorker() {
  const response: AxiosResponse<IExchangeData[]> = yield call(
    fetchConnectedExchanges
  );
  if (!response.data.length) yield put(loadingActions.stopLoading());
  yield put(exchangesActions.getConnectedExchanges(response.data));
}

function* removeExchangeWorker({
  payload,
}: {
  payload: IDeleteExchangeParams;
}) {
  yield call(deleteExchange, payload);
  yield put(
    exchangesActions.exchangeRequestStatus(ExchangesManagerStutuses.delete)
  );
  yield call(getConnectedExchangesWorker);
  const currentExchange = store.getState().Exchanges.selectedExchange;
  const exchangeForSelect =
    currentExchange === payload.exchangeName ? ALL_EXCHANGES : currentExchange;
  yield put(exchangesActions.selectExchange(exchangeForSelect));
}

function* updateExchangeWorker({
  payload,
}: {
  payload: IUpdateExchangeParams;
}) {
  yield call(deleteExchange, payload.toDelete);
  yield call(addExchangeRequest, payload.toUpdate);
  yield put(
    exchangesActions.exchangeRequestStatus(ExchangesManagerStutuses.update)
  );
  yield call(getConnectedExchangesWorker);
}

export function* exchangesWatcher() {
  yield takeLatest(
    exchangesActions.addExchangeAsync,
    withTryCatch(addExchangeWorker)
  );
  yield takeLatest(
    exchangesActions.getConnectedExchangesAsync,
    withTryCatch(getAvailableExchangesWorker)
  );
  yield takeLatest(
    exchangesActions.getConnectedExchangesAsync,
    withTryCatch(getConnectedExchangesWorker)
  );
  yield takeLatest(
    exchangesActions.removeExchangeAsync,
    withTryCatch(removeExchangeWorker)
  );
  yield takeLatest(
    exchangesActions.updateExchangeAsync,
    withTryCatch(updateExchangeWorker)
  );
}
