import React from "react";
import { useTranslation } from "react-i18next";
import { Select } from "../../commonComponents/Select";
import { ButtonTypes } from "../../commonComponents/Button/models";
import { SelectTypes } from "../../commonComponents/Select/models";
import { ReactComponent as Headset } from "../../assets/icons/headset-help.svg";
import ModalWindowController from "../../commonComponents/ModalWindowController";
import { SupportModalContent } from "./SupportModalContent";
import Button from "../../commonComponents/Button";
//styles
import style from "./styles/index.module.scss";

const languages = (isHeader?: boolean) => [
  {
    value: "ru-GB",
    label: isHeader ? "RU" : "Русский",
  },
  {
    value: "en-GB",
    label: isHeader ? "EN" : "English",
  },
];

export const SupportContainer = ({ isHeader }: { isHeader?: boolean }) => {
  const { t, i18n } = useTranslation();

  return (
    <section className={style.supportContainer}>
      <Select
        defaultValue={languages(isHeader).find(
          (language) => language.value === i18n.language
        )}
        classNamePrefix="language"
        options={languages(isHeader)}
        onChange={(v) => {
          i18n.changeLanguage(v.value);
        }}
        type={SelectTypes.Default}
      />
      <ModalWindowController
        trigger={
          <Button
            title={!isHeader ? t("links.support") : ""}
            icon={<Headset />}
            type={ButtonTypes.HelperWithSvg}
            aria-label="support"
          />
        }
        content={<SupportModalContent />}
      />
    </section>
  );
};
