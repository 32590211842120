// React
import React from "react";
//utils
import { classes } from "../../utils/classes";
import { DEFAULT_TAB_INDEX } from "../../constants";
// styles
import style from "./styles/index.module.scss";
// models
import { ICheckbox } from "./models";

export const Checkbox = (props: ICheckbox) => {
  const {
    type = "primary",
    label,
    checked,
    onChange,
    tabIndex = DEFAULT_TAB_INDEX,
    hasError,
    icon,
    className,
  } = props;

  return (
    <div
      className={classes(
        style.checkboxContainer,
        style[type],
        className,
        hasError && style.error
      )}
      tabIndex={tabIndex}
      onKeyDown={onChange}
    >
      <div
        className={classes(style.item, checked && style.checked)}
        onClick={onChange}
      >
        {icon}
      </div>
      {label && (
        <span className={style.checkboxLabel} onClick={onChange}>
          {label}
        </span>
      )}
    </div>
  );
};
