// react
import React, { useEffect, useState } from "react";
import { OnChangeValue } from "react-select";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// redux
import { RootState } from "../../../store/storeConfig";
import { useAsyncDispatch } from "../../../store/utils";
import { exchangesActions } from "../../../store/reducers/Exchange/slice";
// components
import Button from "../../../commonComponents/Button";
import { Input } from "../../../commonComponents/Input";
import { Select } from "../../../commonComponents/Select";
import { ReactComponent as WhitePlusSVG } from "../../../assets/icons/whitePlus.svg";
import { ReactComponent as BackArrowSVG } from "../../../assets/icons/arrowBack.svg";
import { ReactComponent as Loupe } from "../../../assets/icons/loupe.svg";
// models, constants
import { ADD_MARKET_FIELDS } from "../constants";
import { IClonedModalProps, IExchangeFormData, ISelectOption } from "../models";
import { SelectTypes } from "../../../commonComponents/Select/models";
import { ExchangesManagerStutuses } from "../../../store/reducers/Exchange/constants";
// utils
import capitalize from "lodash/capitalize";
// styles
import styles from "./styles/index.module.scss";

const getExchangeUrl = (name: string) => {
  switch (name) {
    case "okx":
      return "https://www.okx.com/join/14062822";
    case "binance":
      return "https://www.binance.com/en/activity/referral-entry/CPA?ref=CPA_0091QZJ78U";
    case "kucoin":
      return "https://www.kucoin.com";
    case "bybit":
      return "https://www.bybit.com";
  }
};

export const AddExchange = ({
  availableExchanges,
  connectedExchanges,
  setActiveStep,
}: IClonedModalProps) => {
  const { t } = useTranslation();
  const asyncDispatch = useAsyncDispatch();

  const requestSuccess = useSelector(
    (state: RootState) => state.Exchanges.exchangeRequestSuccess
  );

  const selectOptions = availableExchanges
    ? availableExchanges
        .filter(
          (item) =>
            !connectedExchanges?.map((item) => item.exchange).includes(item)
        )
        .map((item) => ({
          value: item,
          label: capitalize(item),
        }))
    : [];

  const [exchange, setExchange] = useState<string>(selectOptions[0]?.value);
  const [formData, setFormData] = useState<IExchangeFormData>({
    api_key: "",
    api_secret: "",
    api_passphrase: "",
  });

  useEffect(() => {
    requestSuccess === ExchangesManagerStutuses.add &&
      setActiveStep &&
      setActiveStep("EXCHANGE_REQ_SUCCESS");
  }, [requestSuccess]);

  const onSubmit = async () => {
    asyncDispatch(exchangesActions.addExchangeAsync, { exchange, ...formData });
  };

  const handleSelectChange = (
    newValue: OnChangeValue<ISelectOption, false>
  ) => {
    newValue && setExchange(newValue.value);
  };

  const handleBackButton = () => {
    setActiveStep && setActiveStep("MANAGE_EXCHANGES");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  return (
    <>
      <h2 className={styles.modalTitle}>
        {!!connectedExchanges?.length && (
          <Button
            className={styles.backButton}
            icon={<BackArrowSVG />}
            type={"transparent"}
            onClick={handleBackButton}
          />
        )}
        <span>{t("modals.addExchange.title")}</span>
      </h2>
      {selectOptions.length ? (
        <div className={styles.contentContainer}>
          <Select
            label={t("modals.addExchange.chooseExchange")}
            options={selectOptions}
            type={SelectTypes.Big}
            onChange={handleSelectChange}
          />
          <Input
            name={ADD_MARKET_FIELDS.API_KEY}
            type={"text"}
            value={formData.api_key}
            label={t("modals.addExchange.apiKey")}
            placeholder={t("modals.addExchange.enterApiKey")}
            onChange={handleInputChange}
          />
          <Input
            name={ADD_MARKET_FIELDS.SECRET_KEY}
            type={"text"}
            value={formData.api_secret}
            label={t("modals.addExchange.secretKey")}
            placeholder={t("modals.addExchange.enterSecretKey")}
            onChange={handleInputChange}
          />
          {["okx", "kucoin"].includes(exchange) && (
            <Input
              name={ADD_MARKET_FIELDS.PASS_PHRASE}
              type={"text"}
              value={formData.api_passphrase}
              label={t("modals.addExchange.passPhrase")}
              placeholder={t("modals.addExchange.enterPassPhrase")}
              onChange={handleInputChange}
            />
          )}
          <Button
            title={t("modals.addExchange.connectExchange")}
            onClick={onSubmit}
            icon={<WhitePlusSVG />}
            disabled={
              !(
                formData.api_key &&
                formData.api_secret &&
                (["okx", "kucoin"].includes(exchange)
                  ? formData.api_passphrase
                  : true)
              )
            }
          />
          <div className={styles.register}>
            <p>
              {t("modals.addExchange.noAccount")}
              {capitalize(exchange)}?
            </p>
            <a href={getExchangeUrl(exchange)} target="_blank" rel="noreferrer">
              {t("modals.addExchange.register")}
            </a>
          </div>
        </div>
      ) : (
        <div className={styles.loupe}>
          <Loupe />
          <div className={styles.label}>
            {t("modals.addExchange.allExchangeAdded")}
          </div>
        </div>
      )}
    </>
  );
};
