import { useState } from "react";
import { maskDate, validateDateRegExp } from "./helpers";

export const useValidatedDate = (
  initialValue: string
): [string, (string: string) => void, string] => {
  const [date, setDate] = useState(initialValue);
  const [validDate, setValidDate] = useState(initialValue);

  const handleChangeDate = (string: string) => {
    const modifiedDate = maskDate(string);
    if (validateDateRegExp.test(modifiedDate)) {
      setValidDate(modifiedDate);
    }

    setDate(modifiedDate);
  };

  return [date, handleChangeDate, validDate];
};
