// react
import React, { FormEvent, useState } from "react";
import { useTranslation } from "react-i18next";
// redux
import { useSelector } from "react-redux";
import { RootState } from "../../../store/storeConfig";
// components
import { ReactComponent as SearchSVG } from "../../../assets/icons/search.svg";
import { ReactComponent as Loupe } from "../../../assets/icons/loupe.svg";
import { Input } from "../../../commonComponents/Input";
import { FixedSizeList as List } from "react-window";
import AssetIem from "./AssetItem";
import { CustomScrollbarsVirtualList } from "../../ScrollBar";
// models
import { IAddBookmarkModalContentProps } from "./models";
import { bookmarsNameSelector } from "store/reducers/WSActualData/selectors";
// styles
import style from "./styles/index.module.scss";

export const AddBookmarkModalContent = ({
  onPairClick,
  deactivate,
}: IAddBookmarkModalContentProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const changeInput = (e: FormEvent<HTMLInputElement>) =>
    setSearchValue(e.currentTarget.value);

  const currentBookmarks = useSelector(bookmarsNameSelector);

  const assetBookmarks = useSelector((state: RootState) =>
    state.AssetBookmarks.availableBookmarkPairs
      .filter(
        (i: string) =>
          i.includes(searchValue.toUpperCase().replace("/", "_")) &&
          !currentBookmarks.includes(i)
      )
      .map((i) => i.replace("_", "/"))
  );

  const handlePairClick = (pairName: string) => {
    onPairClick(pairName);
    deactivate && deactivate();
  };

  const Row = ({ index, style }: any) => (
    <div style={style}>
      <AssetIem data={assetBookmarks[index]} onClick={handlePairClick} />
    </div>
  );

  return (
    <div className={style.ModalContent}>
      <h2 className={style.title}>{t("modals.chooseACouple")}</h2>
      <Input
        type="text"
        value={searchValue}
        onChange={changeInput}
        label={t("modals.findACouple")}
        name="exchanges"
        placeholder={t("modals.search")}
        icon={<SearchSVG />}
        onClear={() => setSearchValue("")}
      />

      <div className={style.pairs}>
        {assetBookmarks.length ? (
          <List
            className="List"
            height={370}
            itemCount={assetBookmarks.length}
            itemSize={42}
            width={"auto"}
            outerElementType={CustomScrollbarsVirtualList}
          >
            {Row}
          </List>
        ) : (
          <div className={style.loupe}>
            <Loupe />
            <div className={style.label}>
              {t("dashboardPage.noInformation")}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
