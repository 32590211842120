// React
import React, { useState } from "react";
// Component
import { ChangeIndicator } from "../../Header/ChangeIndicator";
import { MiniCard } from "../../MiniCard";
// helpers
import { priceFormatter } from "../../../utils/priceFormatter";
import {
  getSvgByCoinTicker,
  getSvgByExchangeType,
} from "../../../utils/getSVG";
import { getUsdPrice } from "utils/getUsdPrice";
import { classes } from "../../../utils/classes";
// models, constants
import {
  IAssetItem,
  IAssetItemData,
} from "../../../store/reducers/WSActualData/models";
import { PageType } from "../../../utils/constants";
// styles
import style from "../styles/index.module.scss";
import { ALL_EXCHANGES } from "../../../store/reducers/Exchange/constants";

export const AssetItem = ({ item, type, selectedExchange }: IAssetItem) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={classes(
        style.row,
        type === PageType.Portfolio && style.gridRow,
        type === PageType.Portfolio &&
          selectedExchange === ALL_EXCHANGES &&
          style.activeRow
      )}
      key={item.name}
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className={style.imageWrapper}>
        {getSvgByCoinTicker(item.name).icon}
      </div>
      {type === PageType.Portfolio ? (
        <>
          <MiniCard
            className={style.name}
            title={item.name}
            description={item.fullName}
            transparent
          />
          <MiniCard
            className={style.balance}
            title={priceFormatter(item.count)}
            transparent
          />
          <MiniCard title={`$ ${priceFormatter(item.usd)}`} transparent />
          <MiniCard title={`$ ${priceFormatter(item.rate)}`} transparent />
        </>
      ) : (
        <>
          <MiniCard
            title={`$ ${priceFormatter(item.usd)}`}
            description={`${priceFormatter(item.count)} ${item.name}`}
            transparent
          />
        </>
      )}
      <MiniCard
        className={style.indicator}
        title={getUsdPrice(item.changeBalance24h)}
        transparent
        rightAlignment
      >
        <ChangeIndicator
          value={priceFormatter(Math.abs(item.change24h))}
          isGrowing={item.change24h >= 0}
        />
      </MiniCard>
      {isExpanded &&
        type === PageType.Portfolio &&
        selectedExchange === ALL_EXCHANGES &&
        item.exchanges &&
        item.exchanges.map((stock: IAssetItemData) => (
          <React.Fragment key={stock.fullName + stock.count}>
            <div className={classes(style.imgWrapper, style.subItem)}>
              {getSvgByExchangeType(stock.name)}
            </div>
            <span className={classes(style.subItem, style.balance)}>
              {priceFormatter(stock.count)}
            </span>
            <span className={style.subItem}>{priceFormatter(item.usd)}</span>
            <span className={classes(style.subItem, style.rate)}>
              {priceFormatter(item.rate)}
            </span>
            <span className={classes(style.subItem, style.empty)} />
          </React.Fragment>
        ))}
    </div>
  );
};
