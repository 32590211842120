import { selectSelf, createDeepEqualSelector } from "store/storeConfig";

export const headerTickersSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) => WSActualData.wsData.apiData.exchange__rates.slice(0, 50)
);
export const assetsSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) => WSActualData.wsData.apiData.user__assets.data
);

export const bookmarsSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.user__assets_bookmarks__get.data
);

export const bookmarsNameSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.user__assets_bookmarks__get.data.map(
      (i) => i.name
    )
);

export const exchangeMarketsSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.exchange__markets.data.map((i) => {
      return { label: i.replace("_", "/"), value: i.replace("_", "/") };
    })
);

export const exchangePairSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) => WSActualData.wsData.exchangeParams.pair
);

export const tradeHistorySelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) => WSActualData.wsData.apiData.order__open.data
);

export const shortHistorySelector = (isPortfolio: boolean) => {
  return createDeepEqualSelector(selectSelf, ({ WSActualData }) =>
    WSActualData.wsData.apiData.user__history__gate_and_trades.data.slice(
      0,
      isPortfolio ? 15 : 5
    )
  );
};

export const allHistorySelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.user__history__gate_and_trades.data
);

export const lineDiagramCoinsSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.user__balance__USD__detail.data
);

export const balanceUSDSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.user__balance__USD__total.data
);

export const balanceBTCSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.user__balance__BTC__total.data
);

export const profitabilitySelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) => WSActualData.wsData.apiData.profitability?.data
);

export const historyChangeySelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.user__history__change__USD.data
);

export const currentGraphSettingsSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) => WSActualData.wsData.currentGraphSettings
);

export const graphDataSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) => WSActualData.wsData.apiData.user__history__chart.data
);

export const dynamicPriceDataSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.exchange__dynamic.data.candles.map((i) => {
      return { ...i, time: i.time / 1000 };
    })
);

export const dynamicPriceoptionsSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.exchange__dynamic_assets.data.map((i) => ({
      value: i,
      label: i,
    }))
);

export const priceDynamicsDashboardChartSettingsSelector =
  createDeepEqualSelector(
    selectSelf,
    ({ WSActualData }) =>
      WSActualData.wsData.priceDynamicsDashboardChartSettings
  );

export const exchangeDynamicSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) => WSActualData.wsData.apiData.exchange__dynamic.data
);

export const exchangeDynamicPeriodsSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.exchange__dynamic_periods.data
);

export const profitabilityExceptionsSelector = createDeepEqualSelector(
  selectSelf,
  ({ WSActualData }) =>
    WSActualData.wsData.apiData.profitability?.data.exceptions
);
