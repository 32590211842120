// Redux
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";
import { createSelectorCreator, defaultMemoize } from "reselect";
import isEqual from "lodash.isequal";
// reducers
import { reducer as ErrorInterceptorModal } from "../reducers/ErrorInterceptorModal/slice";
import { reducer as WSActualData } from "../reducers/WSActualData/slice";
import { reducer as Authentication } from "../reducers/Authentication/slice";
import { reducer as Exchanges } from "../reducers/Exchange/slice";
import { reducer as Strategies } from "../reducers/Strategies/slice";
import { reducer as Loading } from "../reducers/Loading/slice";
import { reducer as NotificationModal } from "../reducers/NotificationModal/slice";
import { reducer as AssetBookmarks } from "../reducers/AssetBookmarks/slice";
import { reducer as Support } from "../reducers/Support/slice";
// Sagas
import rootSaga from "./sagas";
//utils
import { loadState, saveState } from "../../utils/sessionStorage";
import throttle from "lodash/throttle";

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    ErrorInterceptorModal,
    NotificationModal,
    Authentication,
    WSActualData,
    Loading,
    Exchanges,
    AssetBookmarks,
    Strategies,
    Support,
  },
  middleware: [sagaMiddleware],
  preloadedState: loadState(),
});

sagaMiddleware.run(rootSaga);

store.subscribe(
  throttle(() => {
    store.getState().Authentication.isAuthenticated &&
      saveState({
        Authentication: store.getState().Authentication,
        WSActualData: store.getState().WSActualData,
        Exchanges: store.getState().Exchanges,
        Strategies: store.getState().Strategies,
      });
  }, 1000)
);

export type RootState = ReturnType<typeof store.getState>;
export const dispatch = store.dispatch;
export const selectSelf = (state: RootState) => state;
export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  isEqual
);
