// react
import React from "react";
// components
import { ResponsiveContainer, LineChart, Line, YAxis } from "recharts";
// constants
import { CHART_SETTINGS } from "./constants";
// models
import { IBookmarkChartDataItem } from "../../store/reducers/WSActualData/models";

interface ISimpleLinearChartProps {
  data: IBookmarkChartDataItem[];
  isGrowing: boolean;
}

// styles
import styles from "./styles/index.module.scss";

export const SimpleLinearChart = ({
  data,
  isGrowing,
}: ISimpleLinearChartProps) => {
  return (
    <ResponsiveContainer
      width="100%"
      height={CHART_SETTINGS.HEIGHT}
      className={styles.SimpleLinearGraphContainer}
    >
      <LineChart
        data={data}
        margin={{
          top: 5,
          right: 5,
          left: 5,
          bottom: 5,
        }}
      >
        <Line
          type="monotone"
          dataKey={CHART_SETTINGS.DATA_KEY}
          stroke={
            isGrowing
              ? CHART_SETTINGS.GROWTH_LINE_COLOR
              : CHART_SETTINGS.DROP_LINE_COLOR
          }
          strokeWidth={2}
          dot={false}
        />
        <YAxis domain={["auto", "auto"]} hide />
      </LineChart>
    </ResponsiveContainer>
  );
};
