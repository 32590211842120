// React
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
// components
import { ReactComponent as ArrowSVG } from "../../../assets/icons/bottomArrow.svg";
import { ReactComponent as PlusSVG } from "../../../assets/icons/whitePlus.svg";
import Button from "../../../commonComponents/Button";
import ModalWindowController from "../../../commonComponents/ModalWindowController";
import { Stepper } from "../../Stepper";
// helpers
import { classes } from "../../../utils/classes";
import { getSvgByExchangeType } from "../../../utils/getSVG";
// models, constants
import { RootState } from "../../../store/storeConfig";
import urls from "../../../routes/urls";
import { IExchangeSelect } from "./models";
import { useWindowSize } from "utils/useWindowSize";
import { tabletMedium } from "../../../constants";
// styles
import style from "./styles/index.module.scss";

export const ExchangeSelect = ({ isHeader }: IExchangeSelect) => {
  const { t } = useTranslation();
  const currentPath = useHistory().location.pathname;
  const { width } = useWindowSize();
  const { connectedExchanges, availableExchanges, selectedExchange } =
    useSelector((state: RootState) => state.Exchanges);

  const renderTriggerButton = () => {
    const icon = getSvgByExchangeType(selectedExchange);
    const exchangeIcon = icon ? icon : <>{t("portfolioPage.allExchanges")}</>;
    // return type !== PageType.Dashboard && connectedExchanges.length ? (
    return currentPath !== urls.ASIDE_ROUTES.BASE_DASHBOARD_PAGE &&
      connectedExchanges.length ? (
      <button className={style.selectButton} aria-label="market">
        {exchangeIcon}
        <ArrowSVG />
      </button>
    ) : (
      <Button
        className={classes(style.selectButton, style.noSpace)}
        title={
          width > tabletMedium
            ? t("portfolioPage.connectExchange")
            : t("portfolioPage.connectExchangeShort")
        }
        icon={<PlusSVG />}
      />
    );
  };

  return (
    <div
      className={classes(
        style.ExchangeSelectContainer,
        !isHeader && style.ExchangeSelectContainerInsideCard,
        "ExchangeSelectContainer"
      )}
    >
      {/* {(currentPath === urls.ASIDE_ROUTES.BASE_PORTFOLIO_PAGE || !isHeader) && ( */}
      {([
        urls.ASIDE_ROUTES.BASE_PORTFOLIO_PAGE,
        urls.ASIDE_ROUTES.BASE_DASHBOARD_PAGE,
      ].includes(currentPath) ||
        !isHeader) && (
        <ModalWindowController
          trigger={renderTriggerButton()}
          content={
            <Stepper
              selectedExchange={selectedExchange}
              connectedExchanges={connectedExchanges}
              availableExchanges={availableExchanges}
            />
          }
        />
      )}
    </div>
  );
};
