import { t } from "i18next";
import { put, call, all, debounce } from "redux-saga/effects";
import { withTryCatch } from "../../utils";
import { notificationModalActions } from "../NotificationModal/slice";
import {
  IAuthenticationParam,
  IAuthenticationParamAsync,
  IPasswordChangeParam,
  IResetInstructionsParam,
  IResetPassword,
} from "./models";
import {
  changePasswordService,
  loginService,
  registrationService,
  resetPasswordService,
  sendResetInstructionService,
} from "./services";
import { authenticationActions } from "./slice";

export function* registrationWorker({
  payload,
}: {
  payload: IAuthenticationParamAsync;
}): Generator {
  yield call(registrationService, payload);
  yield put(
    notificationModalActions.addNotificationModal(
      t("notification.successfullyRegistered")
    )
  );
  const response = yield call(loginService, payload);
  yield put(authenticationActions.login(response as IAuthenticationParam));
}

export function* loginWorker({
  payload,
}: {
  payload: IAuthenticationParamAsync;
}): Generator {
  const response = yield call(loginService, payload);
  yield put(authenticationActions.login(response as IAuthenticationParam));
}

export function* changePasswordWorker({
  payload,
}: {
  payload: IPasswordChangeParam;
}): Generator {
  yield call(changePasswordService, payload);
  yield put(
    notificationModalActions.addNotificationModal(
      t("notification.successfullyPasswordChanged")
    )
  );
}

export function* sendResetInstructionsWorker({
  payload,
}: {
  payload: IResetInstructionsParam;
}): Generator {
  yield call(sendResetInstructionService, payload);
  yield put(
    notificationModalActions.addNotificationModal(
      t("notification.successfullySendResetInstructions")
    )
  );
}

export function* resetPasswordWorker({
  payload,
}: {
  payload: IResetPassword;
}): Generator {
  yield call(resetPasswordService, payload.params);
  yield put(
    notificationModalActions.addNotificationModal(
      t("notification.successfullyNewPasswordSet")
    )
  );
  yield put(payload.history.push("/login"));
}

export function* authenticationWatcher(): Generator {
  yield all([
    debounce(
      200,
      authenticationActions.registrationAsync,
      withTryCatch(registrationWorker)
    ),
    debounce(200, authenticationActions.loginAsync, withTryCatch(loginWorker)),
    debounce(
      200,
      authenticationActions.changePasswordAsync,
      withTryCatch(changePasswordWorker)
    ),
    debounce(
      200,
      authenticationActions.sendResetInstructionsAsync,
      withTryCatch(sendResetInstructionsWorker)
    ),
    debounce(
      200,
      authenticationActions.resetPasswordAsync,
      withTryCatch(resetPasswordWorker)
    ),
  ]);
}
