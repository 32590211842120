// react
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
// components
import { OverallBalance } from "../../components/OverallBalance";
import { PriceDynamics } from "../../components/PriceDynamics";
import History from "../../components/History";
import { CurrencyCards } from "../../components/CurrencyCards";
import { Assets } from "../../components/Assets";
import { RootState } from "../../store/storeConfig";
import { isDeepEqual } from "../../utils/isEqual";
import { useAsyncDispatch } from "../../store/utils";
import { wsActualDataActions } from "../../store/reducers/WSActualData/slice";
import debounce from "lodash/debounce";
import { priceDynamicsDashboardChartSettingsSelector } from "store/reducers/WSActualData/selectors";
//constants
import { ALL_EXCHANGES } from "../../store/reducers/Exchange/constants";
import { PageType } from "../../utils/constants";
// styles
import style from "./styles/index.module.scss";

const DashboardPage = () => {
  const asyncDispatch = useAsyncDispatch();
  const connectedExchanges = useSelector(
    (state: RootState) => state.Exchanges.connectedExchanges,
    isDeepEqual
  );

  const { coinTicker, period } = useSelector(
    priceDynamicsDashboardChartSettingsSelector
  );

  const { wsStatus } = useSelector((state: RootState) => state.Loading);

  const sendWSMessage = useCallback(
    debounce(() => {
      asyncDispatch(wsActualDataActions.sendWSMessage, {
        action: PageType.Dashboard,
        params: {
          exchange: ALL_EXCHANGES,
          dynamic_price_period: period,
          dynamic_price_asset: coinTicker,
        },
      });
    }, 200),
    [JSON.stringify(connectedExchanges), coinTicker, period]
  );

  useEffect(() => {
    if (connectedExchanges.length && wsStatus) {
      sendWSMessage();
    }
  }, [JSON.stringify(connectedExchanges), coinTicker, period, wsStatus]);

  return (
    <div className={style.DashBoardPageContainer}>
      <div className={style.column}>
        <OverallBalance />
        <Assets type={PageType.Dashboard} />
      </div>
      <div className={style.column}>
        <div className={style.cardsWrapper}>
          <CurrencyCards />
        </div>
      </div>
      <div className={style.column}>
        <PriceDynamics />
        <History />
      </div>
    </div>
  );
};

export default React.memo(DashboardPage, isDeepEqual);
