// React
import React from "react";
import { useTranslation } from "react-i18next";
import { ExchangeSelect } from "../Header/ExchangeSelect";
import Button from "../../commonComponents/Button";
import { authenticationActions } from "../../store/reducers/Authentication/slice";
import { wsActualDataActions } from "../../store/reducers/WSActualData/slice";
import { useDispatch } from "react-redux";
import { ReactComponent as LogoutSVG } from "../../assets/icons/logout.svg";
import { loadingActions } from "../../store/reducers/Loading/slice";
//styles
import styles from "./styles/index.module.scss";
import { IExchangeData } from "../../store/reducers/Exchange/models";
import { exchangesActions } from "../../store/reducers/Exchange/slice";

interface IConnectExchangeOverlay {
  inactiveExchanges: IExchangeData[];
}

export const ConnectExchangeOverlay = ({
  inactiveExchanges,
}: IConnectExchangeOverlay) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={styles.connectExchangeContainer}>
      <div className={styles.connectExchangeBody}>
        {inactiveExchanges.length ? (
          <div className={styles.connectExchangeLabel}>
            {t("expiredExchangeOverlayLabel1")} [
            {inactiveExchanges.map((exchange: IExchangeData) => (
              <span className={styles.exchangeLabel} key={exchange.exchange}>
                {exchange.exchange}
              </span>
            ))}
            ] {t("expiredExchangeOverlayLabel2")}
          </div>
        ) : (
          <div className={styles.connectExchangeLabel}>
            {t("connectExchangeOverlayLabel")}
          </div>
        )}
        <ExchangeSelect isHeader={false} />
        <div className={styles.logoutButton}>
          <Button
            title={t("buttons.logOut")}
            icon={<LogoutSVG />}
            onClick={() => {
              dispatch(authenticationActions.logout());
              dispatch(wsActualDataActions.clearWsActualDataState());
              dispatch(loadingActions.setWsStatus(false));
              dispatch(exchangesActions.clear());
            }}
          />
        </div>
      </div>
    </div>
  );
};
