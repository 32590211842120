import { COIN_ICONS, EXCHANGE_ICONS, OPERATION_ICONS } from "./constants";

export const getSvgByOperationType = (operationType: string) => {
  return OPERATION_ICONS[operationType];
};

export const getSvgByCoinTicker = (ticker: string) => {
  return COIN_ICONS[ticker]
    ? {
        isFound: true,
        icon: COIN_ICONS[ticker],
      }
    : { isFound: false, icon: COIN_ICONS["NoIcon"] };
};

export const getSvgByExchangeType = (exchange: string | undefined) => {
  return exchange && EXCHANGE_ICONS[exchange];
};
