// react
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// redux
import { RootState } from "../../../store/storeConfig";
import { useAsyncDispatch } from "../../../store/utils";
import { exchangesActions } from "../../../store/reducers/Exchange/slice";
// components
import Button from "../../../commonComponents/Button";
import { Input } from "../../../commonComponents/Input";
import { ReactComponent as WhitePlusSVG } from "../../../assets/icons/whitePlus.svg";
import { ReactComponent as BackArrowSVG } from "../../../assets/icons/arrowBack.svg";
// models, constants
import { ADD_MARKET_FIELDS } from "../constants";
import { IClonedModalProps, IExchangeFormData } from "../models";
import { IExchangeData } from "../../../store/reducers/Exchange/models";
import { ExchangesManagerStutuses } from "../../../store/reducers/Exchange/constants";
// styles
import styles from "./styles/index.module.scss";

export const UpdateExchange = ({
  setActiveStep,
  activeExchange,
}: IClonedModalProps) => {
  const { t } = useTranslation();
  const asyncDispatch = useAsyncDispatch();
  const requestSuccess = useSelector(
    (state: RootState) => state.Exchanges.exchangeRequestSuccess
  );

  const [formData, setFormData] = useState<IExchangeFormData>({
    api_key: "",
    api_secret: "",
    api_passphrase: "",
  });

  useEffect(() => {
    requestSuccess === ExchangesManagerStutuses.update &&
      setActiveStep &&
      setActiveStep("EXCHANGE_REQ_SUCCESS");
  }, [requestSuccess]);

  const onSubmit = async () => {
    const { api_key, exchange } = activeExchange as IExchangeData;
    await asyncDispatch(exchangesActions.updateExchangeAsync, {
      toDelete: {
        exchangeName: exchange,
        apiKey: api_key,
      },
      toUpdate: {
        exchange: activeExchange?.exchange as string,
        ...formData,
      },
    });
  };

  const handleBackButton = () => {
    setActiveStep && setActiveStep("MANAGE_EXCHANGES");
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  return (
    <>
      <h2 className={styles.modalTitle}>
        <Button
          className={styles.backButton}
          icon={<BackArrowSVG />}
          type={"transparent"}
          onClick={handleBackButton}
        />

        <span>{t("modals.updateExchange.title")}</span>
      </h2>
      <div className={styles.contentContainer}>
        <Input
          name={ADD_MARKET_FIELDS.API_KEY}
          type={"text"}
          value={formData.api_key}
          label={t("modals.addExchange.apiKey")}
          placeholder={t("modals.addExchange.enterApiKey")}
          onChange={handleInputChange}
        />
        <Input
          name={ADD_MARKET_FIELDS.SECRET_KEY}
          type={"text"}
          value={formData.api_secret}
          label={t("modals.addExchange.secretKey")}
          placeholder={t("modals.addExchange.enterSecretKey")}
          onChange={handleInputChange}
        />
        {["okx", "kucoin"].includes(activeExchange?.exchange as string) && (
          <Input
            name={ADD_MARKET_FIELDS.PASS_PHRASE}
            type={"text"}
            value={formData.api_passphrase}
            label={t("modals.addExchange.passPhrase")}
            placeholder={t("modals.addExchange.enterPassPhrase")}
            onChange={handleInputChange}
          />
        )}
        <Button
          title={t("modals.updateExchange.update")}
          onClick={onSubmit}
          icon={<WhitePlusSVG />}
          disabled={
            !(
              formData.api_key &&
              formData.api_secret &&
              (["okx", "kucoin"].includes(activeExchange?.exchange as string)
                ? formData.api_passphrase
                : true)
            )
          }
        />
      </div>
    </>
  );
};
