// React
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/storeConfig";
import { isDeepEqual } from "../../utils/isEqual";
// styles
import style from "./styles/index.module.scss";

export const CardOverlay = ({
  children,
  logo,
}: {
  children?: ReactNode;
  logo?: string;
}) => {
  const connectedExchanges = useSelector(
    (state: RootState) => state.Exchanges.connectedExchanges,
    isDeepEqual
  );

  return (
    <div className={style.CardOverlay}>
      {!!connectedExchanges.length && (
        <>
          <div className={style.CardOverlayLogo}>
            <h2 className={style.cardTitle}>{logo}</h2>
          </div>
          {children}
        </>
      )}
    </div>
  );
};
