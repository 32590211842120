import { StylesConfig } from "react-select";
import { SelectTypes } from "../models";

export const Styles = (type: string): StylesConfig => {
  let cardSize = "55px";

  type === SelectTypes.Big && (cardSize = "100px");

  return {
    menu: (styles: any) => ({
      ...styles,
      fontFamily: "sans-serif",
      color: "#ffffff",
      fontSize: "15px",
      backgroundColor: "#24273a",
      zIndex: "99",
      cursor: "pointer",
    }),
    control: (styles: any) => ({
      ...styles,
      display: "flex",
      height: "46px",
      marginBottom: "10px",
      backgroundColor: "#24273a",
      border: "none",
      cursor: "pointer",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: () => ({
      display: "flex",
      padding: "8px",
      color: "rgb(0, 107, 255)",
    }),
    input: () => ({
      color: "#ffffff",
      gridArea: "1/1/2/3",
      marginLeft: "2px",
      marginRight: "2px",
      maxWidth: "100%",
      whiteSpace: "nowrap",
      boxSizing: "border-box",
    }),
    valueContainer:
      type !== SelectTypes.Default
        ? (styles: any) => ({
            ...styles,
            flex: "0 1 auto",
            width: cardSize,
            height: "22px",
            textAlign: "center",
            marginLeft: "auto",
            marginRight: "0",
            fontFamily: "sans-serif",
            fontSize: "13px",
            lineHeight: "16px",
            fontWeight: "600",
            backgroundColor: "#303349",
            borderRadius: "4px",
          })
        : (styles: any) => ({
            ...styles,
            fontSize: "13px",
            fontWeight: 600,
          }),
    singleValue: (styles: any) => ({
      ...styles,
      color: type !== SelectTypes.Default ? "#676A81" : "#ffffff",
      textOverflow: "inherit",
    }),
    option: (provided, state): any => {
      return {
        ...provided,
        cursor: "pointer",
        backgroundColor: !state.isSelected ? "#24273a" : "#2684FF",
        ":hover": {
          backgroundColor: state.isSelected ? "#2684FF" : "#2684ff33",
        },
      };
    },
  };
};
