import { t } from "i18next";
import { put, call, all, debounce } from "redux-saga/effects";
import { withTryCatch } from "../../utils";
import { notificationModalActions } from "../NotificationModal/slice";
import { SupportMessage } from "./models";
import { supportService } from "./services";
import { supportActions } from "./slice";

export function* supportWorker({
  payload,
}: {
  payload: SupportMessage;
}): Generator {
  yield call(supportService, payload);
  yield put(
    notificationModalActions.addNotificationModal(
      t("notification.successfullySentSupportMessage")
    )
  );
}

export function* supportWatcher(): Generator {
  yield all([
    debounce(50, supportActions.sendMessageAsync, withTryCatch(supportWorker)),
  ]);
}
