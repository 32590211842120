// Axios
import { AxiosError } from "axios";
// store
import { dispatch } from "../storeConfig";
import { IShowRespErrorsProps } from "./models";
import { wsActualDataActions } from "../reducers/WSActualData/slice";
import { authenticationActions } from "../reducers/Authentication/slice";
import { errorInterceptorModalgActions } from "../reducers/ErrorInterceptorModal/slice";
import { loadingActions } from "../reducers/Loading/slice";
import { exchangesActions } from "../reducers/Exchange/slice";
import { ErrMessage } from "store/reducers/ErrorInterceptorModal/models";

const onLogOut = () => {
  dispatch(authenticationActions.logout());
  dispatch(wsActualDataActions.clearWsActualDataState());
  dispatch(loadingActions.setWsStatus(false));
  dispatch(exchangesActions.clear());
};

const checkSpecialErrorCases = (err: string | ErrMessage) => {
  switch (err) {
    case "Invalid credentials.": {
      return {
        ru: "Неверные учетные данные.",
        en: "Invalid credentials.",
      };
    }
    default:
      return err;
  }
};

const showRespErrors = ({ response }: IShowRespErrorsProps) => {
  response &&
    dispatch(
      errorInterceptorModalgActions.showModal({
        isShowModal: true,
        modalContent: checkSpecialErrorCases(
          response.data?.message || response.data?.data?.message
        ),
      })
    );
  dispatch(loadingActions.stopLoading());
};

// reconnect HTTP
// const tryHTTPReconnect = (milliseconds: number, originalRequest: AxiosError) => {
//   return setTimeout(
//     () => axiosInstance.request(originalRequest.config),
//     milliseconds
//   );
// };

export const apiErrorHandler = (error: Error & AxiosError) => {
  switch (error?.response?.status) {
    case 401: {
      onLogOut();
      showRespErrors(error);
      break;
    }
    default: {
      showRespErrors(error);
    }
  }
  return Promise.reject(error);
};
