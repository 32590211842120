import React, { useEffect } from "react";
import { ModalWindow } from "../../commonComponents/ModalWindowController/ModalWindow";
import { useSelector } from "react-redux";
import { dispatch, RootState } from "../../store/storeConfig";
import { useTranslation } from "react-i18next";
import { error } from "../../constants";
import { errorInterceptorModalgActions } from "../../store/reducers/ErrorInterceptorModal/slice";
//styles
import styles from "./styles/index.module.scss";

export const ErrorInterceptorModal = () => {
  const { t, i18n } = useTranslation();
  const { modalContent, isShowModal } = useSelector(
    (state: RootState) => state.ErrorInterceptorModal as any
  );

  const checkEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      dispatch(errorInterceptorModalgActions.hideeModal());
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", checkEscape);
    return () => {
      document.removeEventListener("keydown", checkEscape);
    };
  }, []);

  return (
    <>
      {isShowModal && (
        <ModalWindow
          type={error}
          active={isShowModal}
          deactivate={() =>
            dispatch(errorInterceptorModalgActions.hideeModal())
          }
        >
          {modalContent ? (
            <h3 className={styles.description}>
              {modalContent[i18n.language.split("-")[0]] || modalContent}
            </h3>
          ) : (
            <>
              <h3 className={styles.description}>
                {t("errors.responseError.description")}
                <br />
                {t("errors.solvingStepsMessage")}
                <br />
              </h3>
              <ul className={styles.steps}>
                <li>{t("errors.responseError.steps.one")}</li>
                <li>{t("errors.responseError.steps.two")}</li>
                <li>{t("errors.responseError.steps.three")}</li>
              </ul>
            </>
          )}
        </ModalWindow>
      )}
    </>
  );
};
