import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { dispatch } from "../../store/storeConfig";
import { NotificationModalSelector } from "../../store/reducers/NotificationModal/selector";
import { notificationModalActions } from "../../store/reducers/NotificationModal/slice";
import { ReactComponent as SuccesSVG } from "../../assets/icons/succes.svg";

//styles
import styles from "./styles/index.module.scss";

export const NotificationModal = () => {
  const modalContent = useSelector(NotificationModalSelector);
  let timer: string | number | NodeJS.Timeout | undefined;
  useEffect(() => {
    if (modalContent) {
      timer = setTimeout(
        () => dispatch(notificationModalActions.removeNotificationModal()),
        4000
      );
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return () => clearTimeout(timer);
  }, [modalContent, dispatch]);

  return (
    <>
      {!!modalContent && (
        <div className={styles.NotificationModalContainer}>
          <SuccesSVG />
          <span>{modalContent}</span>
        </div>
      )}
    </>
  );
};
