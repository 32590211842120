import React from "react";
import style from "./styles/index.module.scss";
import { IHistory } from "../../../store/reducers/WSActualData/models";
import {
  getSvgByExchangeType,
  getSvgByOperationType,
} from "../../../utils/getSVG";
import { useTranslation } from "react-i18next";
import { priceFormatter } from "../../../utils/priceFormatter";
import { convertMsToDate } from "../../../utils/dateFormatter";
import { classes } from "../../../utils/classes";

const HistoryItem = ({ data }: { data: IHistory }) => {
  const { t } = useTranslation();
  return (
    <li className={style.ItemContainer}>
      <div className={style.imgWrapper}>{getSvgByOperationType(data.type)}</div>
      <div className={style.text}>
        <span>
          <span className={style.price}>{priceFormatter(data.amount)}</span>
          <span
            className={classes(
              style.type,
              data.type === "sell" && style.sell,
              data.type === "buy" && style.buy
            )}
          >
            {t(`history.${data.type}`)}
          </span>
        </span>
        <span className={style.time}>
          {data.symbol} {convertMsToDate(data.exchange_time)}
        </span>
      </div>
      <div className={style.logo}>{getSvgByExchangeType(data.exchange)}</div>
    </li>
  );
};

export default React.memo(HistoryItem);
