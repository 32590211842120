// react
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// redux
import {
  exchangeDynamicPeriodsSelector,
  priceDynamicsDashboardChartSettingsSelector,
} from "store/reducers/WSActualData/selectors";
import { wsActualDataActions } from "../../../store/reducers/WSActualData/slice";
// helpers
import { classes } from "../../../utils/classes";
// styles
import style from "./styles/index.module.scss";

export const PriceChartSetting = () => {
  const priceDynamicsDashboardChartSettings = useSelector(
    priceDynamicsDashboardChartSettingsSelector
  );
  const chartPeriods = useSelector(exchangeDynamicPeriodsSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className={style.ChartSettingContainer}>
      {chartPeriods.map((period) => (
        <button
          className={classes(
            style.small,
            period === priceDynamicsDashboardChartSettings.period &&
              style.active
          )}
          onClick={() => {
            dispatch(
              wsActualDataActions.changePriceDynamics({
                ...priceDynamicsDashboardChartSettings,
                period,
              })
            );
          }}
          key={`${period}`}
        >
          {t(`dashboardPage.periods.${period}`)}
        </button>
      ))}
    </div>
  );
};
