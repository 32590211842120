import { AxiosResponse } from "axios";
import { all, put, call, takeLatest } from "redux-saga/effects";
// constants
import { PageType } from "../../../utils/constants";
import { ALL_EXCHANGES } from "../Exchange/constants";
// models
import { IAction } from "../../storeConfig/models";
import { ISetBookmarksBody } from "./models";
import { IBookmark } from "../WSActualData/models";
// services
import { fetchAvailablePairs, setBookmarksRequest } from "./services";
import { withTryCatch } from "../../utils";
import { assetBookmarksActions } from "./slice";
import { wsActualDataActions } from "../WSActualData/slice";
import { store } from "../../storeConfig";

function* getAvailableBookmarkPairsWorker() {
  const response: AxiosResponse<string[]> = yield call(fetchAvailablePairs);
  yield put(assetBookmarksActions.getAssetBookmarksPairs(response.data));
}

function* setBookmarksWorker({ payload }: IAction<string>) {
  yield call<(arg: ISetBookmarksBody) => void>(setBookmarksRequest, {
    assets: payload || "",
  });

  const graphParams =
    store.getState().WSActualData.wsData.priceDynamicsDashboardChartSettings;

  yield put({
    type: "WSActualData/sendWSMessage",
    payload: {
      action: PageType.Dashboard,
      params: {
        exchange: ALL_EXCHANGES,
        dynamic_price_period: graphParams.period,
        dynamic_price_asset: graphParams.coinTicker,
      },
    },
  });
}

function* changeBookmarksOrderWorker({ payload }: { payload: IBookmark[] }) {
  yield put(wsActualDataActions.changeBookmarksOrder(payload));
  const bookmarkString: string = yield payload
    ?.map((bookmark: IBookmark) => bookmark.name)
    .join(", ");
  yield call(setBookmarksWorker, {
    payload: bookmarkString,
  } as IAction<string>);
}

export function* assetBookmarksWatcher() {
  yield all([
    takeLatest(
      assetBookmarksActions.getAvailableBookmarkPairsAsync,
      withTryCatch(getAvailableBookmarkPairsWorker)
    ),
    takeLatest(
      assetBookmarksActions.setBookmarksAsync,
      withTryCatch(setBookmarksWorker)
    ),
    takeLatest(
      assetBookmarksActions.changeBookmarksOrderAsync,
      withTryCatch(changeBookmarksOrderWorker)
    ),
  ]);
}
