// React
import React, { FormEvent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAsyncDispatch } from "../../store/utils";
import { authenticationActions } from "../../store/reducers/Authentication/slice";
import Button from "../../commonComponents/Button";
import { Input } from "../../commonComponents/Input";
import { handleEnterDown } from "../../utils/keyDownHandler";
import Video from "./video.mp4";
// styles
import style from "./styles/index.module.scss";

enum AuthenticationMode {
  login,
  registration,
  forgotPassword,
}
export const LoginPage = () => {
  const { t } = useTranslation();
  const asyncDispatch = useAsyncDispatch();
  const [authenticationMode, setAuthenticationMode] = useState(
    AuthenticationMode.login
  );
  const [formValue, setFormValue] = useState({
    email: "",
    password: "",
    repeatPassword: "",
  });
  const [isPasswordMismatch, setIsPasswordMismatch] = useState(false);
  const [isInvalidEmailAddress, setIsInvalidEmailAddress] = useState(false);

  useEffect(() => {
    if (
      formValue.password !== formValue.repeatPassword &&
      formValue.repeatPassword &&
      formValue.password
    ) {
      return setIsPasswordMismatch(true);
    }
    return setIsPasswordMismatch(false);
  }, [formValue.password, formValue.repeatPassword, authenticationMode]);

  useEffect(() => {
    if (
      // eslint-disable-next-line no-useless-escape
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(formValue.email) ||
      formValue.email === ""
    ) {
      return setIsInvalidEmailAddress(false);
    }

    return setIsInvalidEmailAddress(true);
  }, [formValue.email]);

  const login = () => {
    asyncDispatch(authenticationActions.loginAsync, {
      email: formValue.email,
      password: formValue.password,
    });
  };

  const resetInputData = () => {
    setFormValue({ email: "", password: "", repeatPassword: "" });
    setIsInvalidEmailAddress(false);
    setIsPasswordMismatch(false);
  };
  const back = () => {
    resetInputData();
    setAuthenticationMode(AuthenticationMode.login);
  };

  const onForgotPassword = () => {
    setAuthenticationMode(AuthenticationMode.forgotPassword);
  };

  const toRegistration = () => {
    resetInputData();
    setAuthenticationMode(AuthenticationMode.registration);
  };

  const registration = async () => {
    await asyncDispatch(authenticationActions.registrationAsync, {
      email: formValue.email,
      password: formValue.password,
    });
  };

  const changeForm = (e: FormEvent<HTMLInputElement>) => {
    setFormValue({
      ...formValue,
      [e.currentTarget.name]: e.currentTarget.value.trim(),
    });
  };

  const onKeyDownLogin = (e: React.KeyboardEvent<HTMLElement>) => {
    handleEnterDown(e, login);
  };

  const sendEmail = () => {
    asyncDispatch(authenticationActions.sendResetInstructionsAsync, {
      email: formValue.email,
    });
  };

  return (
    <div className={style.LoginPageContainer} onKeyDown={onKeyDownLogin}>
      <video autoPlay muted className={style.bgVideo}>
        <source src={Video} />
      </video>
      <div className={style.LoginPageContent}>
        {authenticationMode === AuthenticationMode.forgotPassword ? (
          <>
            <div className={style.ressetPasswordLogo}>
              {t("loginPage.resetPassword")}
            </div>
            <div className={style.forgotInstruction}>
              {t("loginPage.forgotInstruction")}
            </div>
            <Input
              type="email"
              name="email"
              value={formValue.email}
              placeholder={t("loginPage.e-mailLabel")}
              onChange={changeForm}
              autoComplete={"on"}
            />
            {isInvalidEmailAddress && (
              <div className={style.errorLabel}>
                {t("loginPage.invalidEmailAddress")}
              </div>
            )}
            <div className={style.LoginPageButtons}>
              <Button onClick={back} title={`⬅ ${t("loginPage.back")}`} />
              <Button
                title={t("loginPage.send")}
                disabled={!formValue.email || isInvalidEmailAddress}
                onClick={sendEmail}
              />
            </div>
          </>
        ) : (
          <>
            <h2 className={style.title}>
              {authenticationMode === AuthenticationMode.login
                ? t("loginPage.loginLabel")
                : t("loginPage.registrationLabel")}
            </h2>
            <section>
              <Input
                type="email"
                name="email"
                value={formValue.email}
                placeholder={t("loginPage.e-mailLabel")}
                onChange={changeForm}
                autoComplete={
                  authenticationMode === AuthenticationMode.login
                    ? "on"
                    : "new-password"
                }
              />
              <Input
                name="password"
                value={formValue.password}
                placeholder={t("loginPage.passwordLabel")}
                onChange={changeForm}
                autoComplete={
                  authenticationMode === AuthenticationMode.login
                    ? "on"
                    : "new-password"
                }
                isPassword={true}
              />
              {authenticationMode === AuthenticationMode.login && (
                <span
                  onClick={onForgotPassword}
                  className={style.forgotPassword}
                >
                  {t("loginPage.forgotPassword")}?
                </span>
              )}
              {authenticationMode === AuthenticationMode.registration && (
                <Input
                  name="repeatPassword"
                  value={formValue.repeatPassword}
                  placeholder={t("loginPage.repeatPasswordLabel")}
                  onChange={changeForm}
                  autoComplete="new-password"
                  isPassword={true}
                />
              )}
              {isInvalidEmailAddress && (
                <div className={style.errorLabel}>
                  {t("loginPage.invalidEmailAddress")}
                </div>
              )}
              {isPasswordMismatch && (
                <div className={style.errorLabel}>
                  {t("loginPage.passwordMismatch")}
                </div>
              )}
              <div className={style.LoginPageButtons}>
                {authenticationMode === AuthenticationMode.login ? (
                  <>
                    <Button
                      disabled={
                        !(formValue.email && formValue.password) ||
                        isInvalidEmailAddress
                      }
                      onClick={login}
                      title={t("loginPage.loginButton")}
                    />
                    <Button
                      onClick={toRegistration}
                      title={t("loginPage.registrationButton")}
                    />
                  </>
                ) : (
                  <>
                    <Button onClick={back} title={`⬅ ${t("loginPage.back")}`} />
                    <Button
                      disabled={
                        !(
                          formValue.email &&
                          formValue.password &&
                          formValue.password === formValue.repeatPassword
                        ) || isInvalidEmailAddress
                      }
                      onClick={registration}
                      title={t("loginPage.done")}
                    />
                  </>
                )}
              </div>
            </section>
          </>
        )}
      </div>
    </div>
  );
};
