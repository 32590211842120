// React
import React, { FormEvent, useState } from "react";
import { ReactComponent as ClosedEyeSVG } from "../../assets/icons/closedEye.svg";
import { ReactComponent as OpenEyeSVG } from "../../assets/icons/openEye.svg";
import { ReactComponent as CancelSVG } from "../../assets/icons/cancel.svg";
// utils
import { classes } from "../../utils/classes";
// styles
import styles from "./styles/index.module.scss";
// models
import { IInputProps } from "./models";

export const Input = (props: IInputProps) => {
  const [errorContent, setErrorContent] = useState<string | null>("");
  const [isShowPass, setIsShowPass] = useState<boolean>(false);

  const {
    icon,
    label,
    validate,
    isRequired,
    autoComplete = "new-password",
    isPassword,
    onClear,
    ...otherProps
  } = props;

  const resetErrorContent = () => setErrorContent("");

  const validateInput = () => validate && setErrorContent(validate());

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
  };

  const changeShowType = () => {
    setIsShowPass(!isShowPass);
  };
  const type = isPassword
    ? isShowPass
      ? "text"
      : "password"
    : otherProps.type;

  return (
    <form
      className={styles.inputContainer}
      autoComplete={autoComplete}
      onSubmit={handleSubmit}
    >
      {label && (
        <div
          className={`${styles.inputLabel} ${
            isRequired && styles.requiredField
          }`}
        >
          {label}
        </div>
      )}
      <input
        type={type}
        {...otherProps}
        className={classes(styles.input, errorContent && styles.error)}
        onBlur={validateInput}
        onFocus={resetErrorContent}
        autoComplete={autoComplete}
      />
      {errorContent && (
        <div className={classes(styles.error)}>{errorContent}</div>
      )}
      {isPassword && (
        <span onClick={changeShowType} className={styles.showPassword}>
          {isShowPass ? <OpenEyeSVG /> : <ClosedEyeSVG />}
        </span>
      )}
      {icon}
      {onClear && (
        <span className={styles.clearValue} onClick={onClear}>
          <CancelSVG />
        </span>
      )}
    </form>
  );
};
