// React
import React, { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Marquee from "react-fast-marquee";
import Ticker from "./Ticker";
import { useLocation } from "react-router-dom";
import urls from "routes/urls";
import { classes } from "utils/classes";
import { headerTickersSelector } from "store/reducers/WSActualData/selectors";
// styles
import style from "./styles/index.module.scss";
import "./styles/custom-styles.scss";

export interface IRate {
  avg_for_last_24h: number;
  fresh: number;
  perc_change_by_24h: number;
  symbol: string;
}

export const Tickers = () => {
  const [speed, setSpeed] = useState(3);
  const { pathname } = useLocation();
  const isPortfolio = urls.ASIDE_ROUTES.BASE_PORTFOLIO_PAGE === pathname;
  const actualExchangeRate = useSelector(headerTickersSelector);

  const timer = useRef<NodeJS.Timeout | undefined>(undefined);

  const tickerContainer = useCallback((node: HTMLDivElement | null) => {
    if (node !== null) {
      timer.current = setTimeout(() => {
        setSpeed(node.scrollWidth * 0.04);
      }, 100);
    }
  }, []);

  return (
    <div
      className={classes(
        style.TickerContainer,
        isPortfolio && style.portfolioTicker
      )}
      ref={tickerContainer}
    >
      <Marquee gradient={false} speed={speed} pauseOnHover={true}>
        {actualExchangeRate &&
          actualExchangeRate.map((item: IRate) => (
            <Ticker item={item} key={item.symbol} />
          ))}
      </Marquee>
    </div>
  );
};

// export const Tickers = React.memo(Component);
