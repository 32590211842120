//Base private and public routes
const BASE_APP_PAGE = "";
const BASE_LOGIN_PAGE = "/login";

const BASE_DASHBOARD_PAGE = `${BASE_APP_PAGE}/dashboard`;
const BASE_STRATEGY_PAGE = `${BASE_APP_PAGE}/strategy`;
const BASE_PORTFOLIO_PAGE = `${BASE_APP_PAGE}/portfolio`;
const BASE_BILLING_PAGE = `${BASE_APP_PAGE}/billing`;
const BASE_ACCOUNT_PAGE = `${BASE_APP_PAGE}/account`;
const DETAIL_STRATEGY = `${BASE_APP_PAGE}/strategy/detail`;
const ABOUT_STRATEGY = `${BASE_APP_PAGE}/strategy/about`;
const TOKEN_DETAILS = `${BASE_PORTFOLIO_PAGE}/tokenDetails`;
const RESET_PASS_PAGE = `/password-reset/:token`;

// Auth
const BASE_ROUTES = {
  BASE_APP_PAGE,
  BASE_LOGIN_PAGE,
  RESET_PASS_PAGE,
};

// Aside
const ASIDE_ROUTES = {
  BASE_DASHBOARD_PAGE,
  BASE_STRATEGY_PAGE,
  BASE_PORTFOLIO_PAGE,
  BASE_BILLING_PAGE,
  BASE_ACCOUNT_PAGE,
};

// Internal pages
const INTERNAL_STRATEGY_PAGE = {
  DETAIL_STRATEGY,
  ABOUT_STRATEGY,
};

const INTERNAL_PORTFOLIO_PAGE = {
  TOKEN_DETAILS,
};

const MODIFICATOR = {
  ADD: "add",
  EDIT: "edit",
};

export default {
  BASE_ROUTES,
  ASIDE_ROUTES,
  MODIFICATOR,
  INTERNAL_STRATEGY_PAGE,
  INTERNAL_PORTFOLIO_PAGE,
};
