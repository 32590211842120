import { OnChangeValue } from "react-select";

export enum SelectTypes {
  Small = "small",
  Big = "big",
  Default = "default",
}
export interface SelectProps {
  label?: string;
  value?: any;
  options: any[];
  defaultValue?: any;
  type?: SelectTypes;
  onChange?: (newValue: OnChangeValue<any, any>) => void;
  className?: string;
  isSearchable?: boolean;
  classNamePrefix?: string;
}
