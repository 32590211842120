// react
import React from "react";
//components
import { ReactComponent as NavArrowSVG } from "../../../assets/icons/nav-arrow-up.svg";
// helpers
import { classes } from "../../../utils/classes";
import { priceFormatter } from "../../../utils/priceFormatter";
//styles
import style from "./styles/index.module.scss";
//models
import { IndicatorProps } from "./models";

export const ChangeIndicator = (props: IndicatorProps) => {
  const { value, isGrowing } = props;

  return (
    <span
      className={classes(
        style.indicator,
        isGrowing || value === "-" ? style.growing : ""
      )}
    >
      <NavArrowSVG />
      {priceFormatter(value, 2)}%
    </span>
  );
};
