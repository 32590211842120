// react
import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
// redux
import { exchangesActions } from "../../../store/reducers/Exchange/slice";
// components
import Button from "../../../commonComponents/Button";
import { Checkbox } from "../../../commonComponents/Checkbox";
import { ReactComponent as PlusSVG } from "../../../assets/icons/plus.svg";
import { ReactComponent as WhitePlusSVG } from "../../../assets/icons/whitePlus.svg";
import { ReactComponent as UpdateSVG } from "../../../assets/icons/refresh.svg";
// utils
import { getSvgByExchangeType } from "../../../utils/getSVG";
import { classes } from "../../../utils/classes";
// models, constants
import { ALL_EXCHANGES } from "../../../store/reducers/Exchange/constants";
import { IClonedModalProps } from "../models";
import { IExchangeData } from "../../../store/reducers/Exchange/models";
import urls from "../../../routes/urls";
// styles
import styles from "./styles/index.module.scss";

export const ManageExchanges = ({
  connectedExchanges,
  selectedExchange,
  setActiveStep,
  setActiveExchange,
  deactivate,
}: IClonedModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const handleAddExchange = () => {
    setActiveStep && setActiveStep("ADD_EXCHANGE");
  };

  const handleCheck = (exchangeName: string) => {
    dispatch(exchangesActions.selectExchange(exchangeName));
    deactivate && deactivate();
  };

  const handleDelete = (connectedExchange: IExchangeData) => {
    setActiveExchange && setActiveExchange(connectedExchange);
    setActiveStep && setActiveStep("CONFIRM_DELETION");
  };

  const handleUpdate = (connectedExchange: IExchangeData) => {
    setActiveExchange && setActiveExchange(connectedExchange);
    setActiveStep && setActiveStep("UPDATE_EXCHANGE");
  };

  const isDashboard = urls.ASIDE_ROUTES.BASE_DASHBOARD_PAGE === pathname;

  return (
    <>
      <h2 className={styles.modalTitle}>
        {t("modals.manageExchanges.exchanges")}
      </h2>
      <div className={styles.checkboxesContainer}>
        <Checkbox
          className={classes(
            styles.checkboxWide,
            isDashboard ? styles.disabled : ""
          )}
          type="exchangeItem"
          checked={isDashboard ? true : selectedExchange === ALL_EXCHANGES}
          onChange={() => handleCheck(ALL_EXCHANGES)}
          icon={<>{t("modals.manageExchanges.allExchanges")}</>}
        />
        {connectedExchanges &&
          connectedExchanges.map((exchange, idx) => {
            const exchangeIcon = getSvgByExchangeType(exchange.exchange) || (
              <span className={styles.exchangeLabelMock}>
                {exchange.exchange}
              </span>
            );

            return (
              <div key={idx} className={styles.checkboxWrapper}>
                <Checkbox
                  className={classes(
                    styles.checkbox,
                    isDashboard ? styles.disabled : ""
                  )}
                  type="exchangeItem"
                  checked={
                    isDashboard ? false : selectedExchange === exchange.exchange
                  }
                  onChange={() => handleCheck(exchange.exchange)}
                  icon={exchangeIcon}
                />
                <Button
                  className={styles.updateExchange}
                  type="withoutBorder"
                  icon={<UpdateSVG />}
                  onClick={() => handleUpdate(exchange)}
                />
                <Button
                  className={styles.deleteExchange}
                  type="withoutBorder"
                  icon={<WhitePlusSVG />}
                  onClick={() => handleDelete(exchange)}
                />
              </div>
            );
          })}
        <Button onClick={handleAddExchange} icon={<PlusSVG />} />
      </div>
    </>
  );
};
