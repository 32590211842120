import { createSlice } from "@reduxjs/toolkit";
import { IErrorInterceptorModal } from "./models";

const initialState: IErrorInterceptorModal = {
  modalContent: "",
  isShowModal: false,
};

export const { actions: errorInterceptorModalgActions, reducer } = createSlice({
  name: "ErrorInterceptorModal",
  initialState,
  reducers: {
    showModal: (
      state: IErrorInterceptorModal,
      { payload }: { payload: IErrorInterceptorModal }
    ) => {
      state.modalContent = payload.modalContent;
      state.isShowModal = payload.isShowModal;
    },
    hideeModal: (state: IErrorInterceptorModal) => {
      state.modalContent = "";
      state.isShowModal = false;
    },
  },
});
