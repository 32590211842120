// React
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { OnChangeValue } from "react-select";
import {
  dynamicPriceDataSelector,
  dynamicPriceoptionsSelector,
  exchangeDynamicSelector,
  priceDynamicsDashboardChartSettingsSelector,
} from "store/reducers/WSActualData/selectors";
// components
import { wsActualDataActions } from "../../store/reducers/WSActualData/slice";
import { ChangeIndicator } from "../Header/ChangeIndicator";
import { Select } from "../../commonComponents/Select";
import { LinearChart } from "../LinearChart";
import { PriceChartSetting } from "./PriceChartSettings";
import { CardOverlay } from "../CardOverlay";
import { Spinner } from "../Spinner";
// helpers
import { PageType } from "../../utils/constants";
import { getUsdPrice } from "utils/getUsdPrice";
// models
import { ISelectOption } from "../Stepper/models";
// styles
import style from "./styles/index.module.scss";

export const PriceDynamics = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const dynamicPriceData = useSelector(dynamicPriceDataSelector);
  const options = useSelector(dynamicPriceoptionsSelector);
  const priceDynamicsDashboardChartSettings = useSelector(
    priceDynamicsDashboardChartSettingsSelector
  );
  const { perc, usd } = useSelector(exchangeDynamicSelector);

  const handleSelectChange = (option: OnChangeValue<ISelectOption, false>) =>
    option &&
    dispatch(
      wsActualDataActions.changePriceDynamics({
        ...priceDynamicsDashboardChartSettings,
        coinTicker: option.value,
      })
    );

  return (
    <div className={style.DynamicsContainer}>
      {!dynamicPriceData.length && (
        <CardOverlay logo={t("dashboardPage.priceDynamics")}>
          <Spinner isSimple />
        </CardOverlay>
      )}
      <div className={style.title}>
        <h2 className={style.cardTitle}>{t("dashboardPage.priceDynamics")}</h2>
        <div className={style.description}>
          <Select
            onChange={handleSelectChange}
            className={style.select}
            classNamePrefix="currency"
            options={options}
            defaultValue={{
              value: priceDynamicsDashboardChartSettings.coinTicker || "BTC",
              label: priceDynamicsDashboardChartSettings.coinTicker || "BTC",
            }}
            isSearchable
          />

          <div className={style.info}>
            {!!usd && <span className={style.value}>{getUsdPrice(usd)}</span>}
            {!!perc && <ChangeIndicator value={perc} isGrowing={perc >= 0} />}
          </div>
        </div>
      </div>

      <div className={style.chart}>
        <LinearChart
          data={dynamicPriceData}
          selectedPeriod={priceDynamicsDashboardChartSettings.period}
          pageType={PageType.Dashboard}
        />
      </div>

      <PriceChartSetting />
    </div>
  );
};
