import { Action } from "redux";
import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { PayloadAction } from "@reduxjs/toolkit";
import { call } from "redux-saga/effects";

export type Resolve = (value?: any | PromiseLike<any>) => void;
export type Reject = (reason?: any) => void;
export type Reducer<S, A> = (state: S, action: PayloadAction<A>) => S | void;
export const useAsyncDispatch = () => {
  const dispatch = useDispatch();

  return useCallback(
    (
      reducer: (resolve: Resolve, reject: Reject, payload: any) => Action,
      payload?: any
    ) => {
      return new Promise((resolve, reject) => {
        dispatch(reducer(resolve, reject, payload));
      });
    },
    [dispatch]
  );
};

export const asyncReducer = <S, A = undefined>(reducer: Reducer<S, A>) => ({
  reducer,
  prepare: (resolve: Resolve, reject: Reject, payload: A) => {
    return { payload, meta: { resolve, reject } };
  },
});

export function withTryCatch<
  P = void,
  T extends string = string,
  M = never,
  E = never
>(saga: (a: PayloadAction<P, T, M, E>) => any) {
  return function* (a: PayloadAction<P, T, M, E>) {
    try {
      yield call(saga, a);
    } catch (e) {
      console.log("e", e, "saga body  ", saga);
    }
  };
}
