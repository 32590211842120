import { createSlice } from "@reduxjs/toolkit";
import { asyncReducer } from "../../utils";
import noop from "lodash/noop";
import { ALL_EXCHANGES, ExchangesManagerStutuses } from "./constants";
import {
  IAddExchangeBody,
  IDeleteExchangeParams,
  IExchange,
  IExchangeData,
  IUpdateExchangeParams,
} from "./models";

export const initialStateEchanges: IExchange = {
  selectedExchange: ALL_EXCHANGES,
  connectedExchanges: [],
  availableExchanges: [],
  exchangeRequestSuccess: ExchangesManagerStutuses.inactive,
};

export const { actions: exchangesActions, reducer } = createSlice({
  name: "Exchanges",
  initialState: initialStateEchanges,
  reducers: {
    exchangeRequestStatus: (
      state: IExchange,
      { payload }: { payload: ExchangesManagerStutuses }
    ) => {
      state.exchangeRequestSuccess = payload;
    },
    selectExchange: (state: IExchange, { payload }: { payload: string }) => {
      state.selectedExchange = payload;
    },
    addExchangeAsync: asyncReducer<IExchange, IAddExchangeBody>(noop),
    getConnectedExchangesAsync: asyncReducer<IExchange>(noop),
    getConnectedExchanges: (
      state: IExchange,
      { payload }: { payload: IExchangeData[] }
    ) => {
      state.connectedExchanges = payload;
    },
    removeExchangeAsync: asyncReducer<IExchange, IDeleteExchangeParams>(noop),
    updateExchangeAsync: asyncReducer<IExchange, IUpdateExchangeParams>(noop),
    getAvailableExchangesAsync: asyncReducer<IExchange, IDeleteExchangeParams>(
      noop
    ),
    getAvailableExchanges: (
      state: IExchange,
      { payload }: { payload: string[] }
    ) => {
      state.availableExchanges = payload;
    },
    clear: (state: IExchange) => {
      state.connectedExchanges = initialStateEchanges.connectedExchanges;
      state.selectedExchange = ALL_EXCHANGES;
    },
  },
});
