import isEqual from "lodash/isEqual";

export const isDeepEqual = (prev: any, next: any) => {
  if (isEqual(prev, next)) {
    return true;
  }
  return false;
};

export const isEqualCustom = (prev: any, next: any) => {
  if (prev === next) {
    return true;
  }
  return false;
};
