// react
import React from "react";
// components
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  Line,
  XAxis,
  YAxis,
  Tooltip,
} from "recharts";
import { CustomTooltip } from "./Tooltip";
import { useTranslation } from "react-i18next";
import { CardOverlay } from "components/CardOverlay";
import { Spinner } from "components/Spinner";
// constants
import { CHART_SETTINGS } from "./constants";
//utils
import { formatGraphPrice } from "../../utils/priceFormatter";
import { getTimeByPeriod } from "../../utils/dateFormatter";
// models
import { IFrameData, ILinearChartProps } from "./models";
// styles
import styles from "./styles/index.module.scss";

export const isChartReady = (
  data: IFrameData[],
  selectedPeriod: string
): boolean => {
  if (!data.length) return true;
  switch (selectedPeriod) {
    case "1days":
      return data.length > 1;
    case "7days":
      return data.length > 1;
    case "30days":
      return data.length > 1;
    case "360days":
      return data.length > 1;
    default:
      return true;
  }
};

export const LinearChart = ({
  data,
  selectedPeriod = "year",
  pageType,
}: ILinearChartProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.chartWrapper}>
      {!isChartReady(data, selectedPeriod) && (
        <CardOverlay>
          <Spinner isSimple />
          <span className={styles.notEnoughDataLabel}>
            {t("portfolioPage.portfolioChangeNotEnoughData")}
          </span>
        </CardOverlay>
      )}
      <ResponsiveContainer
        className={styles.LinearGraphContainer}
        height={CHART_SETTINGS[`${pageType}HEIGHT`]}
      >
        <LineChart
          data={data}
          margin={{
            bottom: 10,
            top: 20,
            left: 25,
            right: -10,
          }}
        >
          <CartesianGrid
            strokeDasharray="0"
            stroke="#2f3147"
            className={styles.grid}
          />
          <XAxis
            dataKey="time"
            tickSize={0}
            minTickGap={["3year", "5year"].includes(selectedPeriod) ? 35 : 10}
            interval="equidistantPreserveStart"
            tickFormatter={(value) => getTimeByPeriod(value, selectedPeriod)}
            axisLine={false}
            textAnchor="middle"
            tickMargin={15}
            stroke={"#676A81"}
          />
          <YAxis
            padding={{ bottom: 5, top: 5 }}
            domain={["auto", "auto"]}
            tickSize={0}
            axisLine={false}
            textAnchor="start"
            orientation="right"
            tickFormatter={formatGraphPrice}
            tickMargin={10}
            stroke={"#676A81"}
          />
          <Tooltip wrapperStyle={{ zIndex: 100 }} content={<CustomTooltip />} />
          <Line
            dataKey="value"
            type="monotone"
            stroke={CHART_SETTINGS.LINE_COLOR}
            strokeWidth={2}
            dot={false}
            activeDot={{
              r: 5,
              fill: "#272B40",
              stroke: "#fff",
              strokeWidth: 2,
            }}
          />
          <Line
            dataKey="price"
            type="monotone"
            stroke={CHART_SETTINGS.LINE_COLOR}
            strokeWidth={2}
            dot={false}
            activeDot={{
              r: 5,
              fill: "#272B40",
              stroke: "#fff",
              strokeWidth: 2,
            }}
          />
          <Line
            dataKey="oldValue"
            type="monotone"
            stroke={CHART_SETTINGS.SECOND_LINE_COLOR}
            strokeWidth={2}
            dot={false}
            activeDot={{
              r: 5,
              fill: "#272B40",
              stroke: "#fff",
              strokeWidth: 2,
            }}
            strokeDasharray="7 7"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};
