import { t } from "i18next";
import i18n from "../../localization/i18n";

export const unifyTimestamp = (ms: number) =>
  +(ms * 1000).toString().slice(0, 13);

export const getTimeByPeriod = (ms: number, period: string) => {
  const timestamp = unifyTimestamp(ms);
  const date = new Date(timestamp);

  switch (period) {
    case "6month":
    case "year":
    case "360days":
      return date.toLocaleString(i18n.language, {
        month: "short",
        year: "numeric",
      });
    case "3year":
    case "5year":
      return date.toLocaleString(i18n.language, {
        year: "numeric",
      });
    case "month":
    case "30days":
    case "week":
    case "7days":
      return date.toLocaleString(i18n.language, {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      });
    case "1days":
      return date.toLocaleString(i18n.language, {
        hour: "numeric",
        minute: "numeric",
      });
    default:
      return date.toLocaleString(i18n.language);
  }
};

export const convertMsToDate = (ms: number): string => {
  const timestamp = unifyTimestamp(ms);

  return new Date(timestamp).toLocaleString(i18n.language, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    // hour12: true,
  });
};

export const getLocaleDay = (ms: number) => {
  const daysArr: string[] = t("dashboardPage.weekDay", { returnObjects: true });
  return daysArr[new Date(ms).getDay()];
};

export const firstLetterToUppercase = (word: string) => {
  return word[0].toUpperCase() + word.slice(1);
};
