// React
import React from "react";
// Component
import { ChangeIndicator } from "../../ChangeIndicator";
import "../styles/custom-styles.scss";
// helpers
import { isDeepEqual } from "utils/isEqual";
import { priceFormatter } from "../../../../utils/priceFormatter";
import { getSvgByCoinTicker } from "../../../../utils/getSVG";
import { IRate } from "..";
// styles
import style from "../styles/index.module.scss";

const Ticker = ({ item }: { item: IRate }) => (
  <>
    <span className={style.imgContainer}>
      {getSvgByCoinTicker(item.symbol).icon}
    </span>
    <span className={style.content}>
      <span className={style.top}>
        <span className={style.title}>$ {priceFormatter(item.fresh)}</span>
        {!!item.perc_change_by_24h && (
          <ChangeIndicator
            value={priceFormatter(item.perc_change_by_24h)}
            isGrowing={item.perc_change_by_24h > 0}
          />
        )}
      </span>
      <span className={style.bottom}>
        {item.symbol} / {"USD"} - {"24h"}
      </span>
    </span>
  </>
);

export default React.memo(Ticker, isDeepEqual);
