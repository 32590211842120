// React
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// import { ReactComponent as AddSVG } from "../../assets/icons/add-button.svg";
// import Button from "../../commonComponents/Button";
// import { Stepper } from "../Stepper";
// import ModalWindowController from "../../commonComponents/ModalWindowController";
import { CardOverlay } from "../CardOverlay";
import { Spinner } from "../Spinner";
// helpers
import { priceFormatter } from "../../utils/priceFormatter";
// import { isDeepEqual } from "../../utils/isEqual";
import {
  balanceBTCSelector,
  balanceUSDSelector,
} from "store/reducers/WSActualData/selectors";
// models, constants
// import { RootState } from "../../store/storeConfig";
// import { buttonTypes } from "../../commonComponents/Button/constants";
// style
import style from "./style/index.module.scss";

export const OverallBalance = () => {
  const { t } = useTranslation();
  const balanceUSD = useSelector(balanceUSDSelector);
  const balanceBTC = useSelector(balanceBTCSelector);
  // const { selectedExchange, availableExchanges, connectedExchanges } =
  //   useSelector((state: RootState) => state.Exchanges, isDeepEqual);

  return (
    <div className={style.BalanceContainer}>
      {!balanceUSD && (
        <CardOverlay logo={t("dashboardPage.overallBalance")}>
          <Spinner isSimple />
        </CardOverlay>
      )}
      <h2 className={style.title}>{t("dashboardPage.overallBalance")}</h2>
      <div className={style.value}>
        $ {balanceUSD && priceFormatter(balanceUSD)}
      </div>
      <span className={style.description}>
        {" "}
        ~ {balanceUSD && priceFormatter(balanceBTC)} BTC
      </span>
      {/* <ModalWindowController
        trigger={
          <Button
            type={buttonTypes.withoutBorder}
            title={t("dashboardPage.addExchange")}
            icon={<AddSVG />}
          />
        }
        content={
          <Stepper
            selectedExchange={selectedExchange}
            connectedExchanges={connectedExchanges}
            availableExchanges={availableExchanges}
          />
        }
      /> */}
    </div>
  );
};
