// react
import React, { useState } from "react";
//components
import Button from "../../../commonComponents/Button";
import { ReactComponent as SettingSVG } from "../../../assets/icons/settings.svg";
import { ReactComponent as DeleteSVG } from "../../../assets/icons/deleteBookmark.svg";
import { ReactComponent as DragSVG } from "../../../assets/icons/drag2.svg";
import { ChangeIndicator } from "../../Header/ChangeIndicator";
import ModalWindowController from "../../../commonComponents/ModalWindowController";
import { AddBookmarkModalContent } from "../manageBookmarkModalContent";
import { SimpleLinearChart } from "../../SimpleLinearChart";
//helpers
import { getSvgByCoinTicker } from "../../../utils/getSVG";
import { priceFormatter } from "../../../utils/priceFormatter";
import { classes } from "../../../utils/classes";
import { useWindowSize } from "../../../utils/useWindowSize";
import { desktop } from "../../../constants";
// import { getLocaleDay } from "../../../utils/dateFormatter";
// models, constants
import { buttonTypes } from "../../../commonComponents/Button/constants";
import { CurrencyCardProps } from "./models";
// styles
import style from "./styles/index.module.scss";

export const CurrencyCard = ({
  data,
  deleteBookmark,
  changeBookmark,
  draggable = false,
  onDragEnd,
  onDragOver,
  onDragStart,
  onDrop,
}: CurrencyCardProps) => {
  const handlePairClick = (value: string) => {
    changeBookmark && changeBookmark(value, data.name);
  };
  const [isHovered, setIsHovered] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const { width } = useWindowSize();

  const handleDragOver = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
    onDragOver && onDragOver();
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    onDragEnd && onDragEnd();
  };

  const handleDrop = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    onDrop && onDrop();
  };

  return (
    <div
      className={classes(
        style.CurrencyCardContainer,
        isDragging && style.drag,
        isHovered && style.CurrencyCardContainerHovered
      )}
      draggable={draggable}
      onDragStart={onDragStart}
      onDragLeave={handleDragEnd}
      onDragEnd={handleDragEnd}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className={style.top}>
        <h2 className={style.title}>{data.name.split("_")[0]}</h2>

        <div className={style.buttons}>
          {width >= desktop && (
            <span
              className={style.draggableButton}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Button
                type={buttonTypes.setting}
                icon={<DragSVG />}
                aria-label="drag"
              />
            </span>
          )}
          <ModalWindowController
            className={style.settingsModal}
            draggable
            onDragStart={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            trigger={
              <Button
                type={buttonTypes.setting}
                icon={<SettingSVG />}
                aria-label="setting"
              />
            }
            content={<AddBookmarkModalContent onPairClick={handlePairClick} />}
          />
          <Button
            type={buttonTypes.setting}
            icon={<DeleteSVG />}
            onClick={() => deleteBookmark(data.name)}
            aria-label="delete"
          />
        </div>
      </div>
      <div className={style.cardDescription}>
        <span className={style.coast}>$ {priceFormatter(data.rate, 2)}</span>
        <ChangeIndicator
          value={data.change24h}
          isGrowing={data.change24h >= 0}
        />
      </div>
      <SimpleLinearChart data={data.chart} isGrowing={data.change24h >= 0} />
      {/* <div className={style.daySelector}>
        {data.chart.map(({ time }) => {
          return (
            <div key={time} className={style.xAxisTick}>
              {getLocaleDay(time)}
            </div>
          );
        })}
      </div> */}
      <div className={style.bottom}>
        <div className={style.imageWrapper}>
          {getSvgByCoinTicker(data.name.split("_")[0]).icon}
        </div>

        <div className={style.description}>
          <span className={style.value1}>$ {priceFormatter(data.usd, 2)}</span>
          <span className={style.value2}>
            {priceFormatter(data.count, 2)} {data.name.split("_")[0]}
          </span>
        </div>
      </div>
    </div>
  );
};
