// react
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
// redux
import { RootState } from "../../../store/storeConfig";
import { useAsyncDispatch } from "../../../store/utils";
import { exchangesActions } from "../../../store/reducers/Exchange/slice";
// components
import Button from "../../../commonComponents/Button";
import { ReactComponent as CheckedSVG } from "../../../assets/icons/checkCircled.svg";
// models
import { IClonedModalProps } from "../models";
// styles
import styles from "./styles/index.module.scss";
import { ExchangesManagerStutuses } from "../../../store/reducers/Exchange/constants";

export const ConfirmDeletion = ({
  activeExchange,
  setActiveStep,
}: IClonedModalProps) => {
  const { t } = useTranslation();
  const asyncDispatch = useAsyncDispatch();

  const requestSuccess = useSelector(
    (state: RootState) => state.Exchanges.exchangeRequestSuccess
  );

  useEffect(() => {
    requestSuccess === ExchangesManagerStutuses.delete &&
      setActiveStep &&
      setActiveStep("EXCHANGE_REQ_SUCCESS");
  }, [requestSuccess]);

  const handleConfirmDeletion = () => {
    if (activeExchange) {
      const { api_key, exchange } = activeExchange;
      asyncDispatch(exchangesActions.removeExchangeAsync, {
        exchangeName: exchange,
        apiKey: api_key,
      });
    }
  };

  const handleCancel = () => {
    setActiveStep && setActiveStep("MANAGE_EXCHANGES");
  };

  return (
    <div className={styles.confirmContainer}>
      <CheckedSVG />
      <h2 className={styles.modalTitle}>{t("modals.confirmation.title")}</h2>
      <p className={styles.text}>{t("modals.confirmation.confirmationText")}</p>
      <div className={styles.buttonsContainer}>
        <Button
          title={t("modals.confirmation.confirmButton")}
          onClick={handleConfirmDeletion}
        />
        <Button
          title={t("modals.confirmation.cancelButton")}
          onClick={handleCancel}
        />
      </div>
    </div>
  );
};
