import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ModalWindow } from "./ModalWindow";
import { IModalWindowControllerProps } from "./models";

const ModalWindowController = ({
  trigger,
  content,
  ...restProps
}: IModalWindowControllerProps) => {
  const [activeModal, setActiveModal] = useState<boolean>(false);

  const clickActivePopup = () => {
    setActiveModal(true);
  };

  const keyActivatePopup = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e?.code === "Enter") {
      e.stopPropagation();
      setActiveModal(true);
    }
  };

  const deactivatePopup = () => {
    setActiveModal(false);
  };

  const checkEscape = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      deactivatePopup();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", checkEscape);
    return () => {
      document.removeEventListener("keydown", checkEscape);
    };
  }, []);

  return (
    <>
      {React.cloneElement(trigger, {
        onClick: clickActivePopup,
        onKeyDown: keyActivatePopup,
      })}
      {ReactDOM.createPortal(
        <>
          {activeModal && (
            <ModalWindow
              active={activeModal}
              deactivate={deactivatePopup}
              {...restProps}
            >
              {React.cloneElement(content, {
                deactivate: deactivatePopup,
              })}
            </ModalWindow>
          )}
        </>,
        document.getElementById("root") as any
      )}
    </>
  );
};

export default ModalWindowController;
