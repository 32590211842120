// React
import React from "react";
// Components
import { ExchangeSelect } from "./ExchangeSelect";
import { ReactComponent as LogoSVG } from "../../assets/icons/faviconLogo.svg";
import { SupportContainer } from "../SupportContainer";
import { Tickers } from "./Tickers";
// styles
import style from "./styles/index.module.scss";
import { useHistory } from "react-router-dom";
import urls from "routes/urls";

export const Header = () => {
  const history = useHistory();

  return (
    <header className={style.header}>
      <div className={style.support}>
        <SupportContainer isHeader={true} />
      </div>
      <span
        className={style.logo}
        onClick={() => history.push(urls.ASIDE_ROUTES.BASE_DASHBOARD_PAGE)}
      >
        <LogoSVG />
        <label>
          Lets<span>Bulls</span>
        </label>
      </span>
      <ExchangeSelect isHeader={true} />
      <Tickers />
    </header>
  );
};
