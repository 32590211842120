// Axios
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
// store
import { store } from "../storeConfig";
// constants
import { ApiUrl } from "./APIConstants";
// utils
import { apiErrorHandler } from "./helpers";

export const axiosInstance = axios.create({
  baseURL: ApiUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.response.use(
  ({ data }: AxiosResponse) => data,
  (error) => apiErrorHandler(error)
);

axiosInstance.interceptors.request.use(
  (requestConfig: AxiosRequestConfig) => {
    const token = store.getState().Authentication.token;
    requestConfig.headers = {
      ...requestConfig.headers,
      Authorization: `Bearer ${token}`,
    };
    return requestConfig;
  },
  (error) => apiErrorHandler(error)
);
