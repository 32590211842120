import { all, day, hour, month, week } from "../../constants";

export const filterOptions = [
  {
    id: 1,
    name: all,
  },
  {
    id: 2,
    name: month,
  },
  {
    id: 3,
    name: week,
  },
  {
    id: 4,
    name: day,
  },
  {
    id: 5,
    name: hour,
  },
];
