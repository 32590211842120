import { createSlice } from "@reduxjs/toolkit";
import noop from "lodash/noop";
import { asyncReducer } from "../../utils";
import { SupportMessage } from "./models";
export const initialState = {};

export const { actions: supportActions, reducer } = createSlice({
  name: "Support",
  initialState,
  reducers: {
    sendMessageAsync: asyncReducer<any, SupportMessage>(noop),
  },
});
