// react
import React, { useCallback, useEffect, useState } from "react";
//redux
import { useSelector } from "react-redux";
// components
import HistoryItem from "../HistoryItem";
import Calendar from "../../Calendar";
import { ReactComponent as Loupe } from "../../../assets/icons/loupe.svg";
import { FixedSizeList as List } from "react-window";
import { getInitialDateRange } from "../../Calendar/helpers";
import { useWindowSize } from "../../../utils/useWindowSize";
import { CustomScrollbarsVirtualList } from "../../ScrollBar";
// helpers
import { useTranslation } from "react-i18next";
import { filterOptions } from "../filterOptions";
import { filterByRange } from "../utils";
import { all, day, hour, month, tabletMedium, week } from "../../../constants";
//models
import { IDataRange } from "../../Calendar/models";
import { IHistory } from "../../../store/reducers/WSActualData/models";
import { useHideBodyScroll } from "utils/useHideBodyScroll";
import { allHistorySelector } from "store/reducers/WSActualData/selectors";
//styles
import style from "./styles/index.module.scss";

export const FilterModalContent = () => {
  const [activeRange, setActiveRange] = useState<IDataRange | undefined>(
    getInitialDateRange()
  );
  useHideBodyScroll();
  const { width } = useWindowSize();
  const [activeFilter, setActiveFilter] = useState<string>(
    filterOptions[0].name
  );
  const history = useSelector(allHistorySelector);
  const [historyFilter, setHistoryFilter] = useState<IHistory[]>(history);

  const Row = ({ index, style }: any) => (
    <div style={style}>
      <HistoryItem data={historyFilter[index]} />
    </div>
  );
  const { t } = useTranslation();

  const buttonsFilter = useCallback(
    (name: string) => () => {
      switch (name) {
        case month:
          setActiveRange({
            endDate: new Date(),
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            key: "selection",
          });
          setActiveFilter(name);
          break;
        case week:
          setActiveRange({
            endDate: new Date(),
            startDate: new Date(new Date().setDate(new Date().getDate() - 7)),
            key: "selection",
          });
          setActiveFilter(name);
          break;
        case day:
          setActiveRange({
            endDate: new Date(),
            startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
            key: "selection",
          });
          setActiveFilter(name);
          break;
        case hour:
          setActiveRange({
            endDate: new Date(),
            startDate: new Date(new Date().setHours(new Date().getHours() - 1)),
            key: "selection",
          });
          setActiveFilter(name);
          break;
        case all:
          setHistoryFilter(history);
          setActiveFilter(name);
          break;
        default:
          break;
      }
    },
    []
  );

  useEffect(() => {
    if (activeFilter !== "all" && activeRange) {
      setHistoryFilter(filterByRange(history, activeRange));
    }
  }, [activeRange]);

  const onCalendarChange = useCallback((value: IDataRange) => {
    setActiveRange(value);
  }, []);

  return (
    <div className={style.modalContent}>
      <div className={style.aside}>
        <h2 className={style.title}>{t("modals.filter")}</h2>
        <div className={style.menu}>
          {filterOptions.map((item) => (
            <button
              key={item.id + item.name}
              onClick={buttonsFilter(item.name)}
              className={item.name === activeFilter ? style.active : ""}
            >
              {t(`modals.${item.name}`)}
            </button>
          ))}
        </div>
        <div className={style.submenu}>
          <div className={style.subTitle}>{t("modals.period")}</div>
          <Calendar
            activeRange={activeRange}
            onChange={onCalendarChange}
            setActiveFilter={setActiveFilter}
          />
        </div>
      </div>
      <div className={style.main}>
        <h2 className={style.title}>{t("dashboardPage.history")}</h2>
        <div>
          <div className={style.row}>
            <div className={style.subTitle}>
              {activeFilter ? t(`modals.${activeFilter}`) : ""}
            </div>
            <div className={style.listContainer}>
              <ul className={style.list}>
                {historyFilter.length ? (
                  <List
                    className="List"
                    height={width <= tabletMedium ? 270 : 370}
                    itemCount={historyFilter.length}
                    itemSize={68}
                    width={"auto"}
                    outerElementType={CustomScrollbarsVirtualList}
                  >
                    {Row}
                  </List>
                ) : (
                  <div className={style.loupe}>
                    <Loupe />
                    <div className={style.label}>
                      {t("dashboardPage.noInformation")}
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
