import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { TextArea } from "../../../commonComponents/TextArea";
import Button from "../../../commonComponents/Button";
//styles
import style from "./styles/index.module.scss";
import { useAsyncDispatch } from "store/utils";
import { supportActions } from "store/reducers/Support/slice";

export const SupportModalContent = ({
  deactivate,
}: {
  deactivate?: () => void;
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const asyncDispatch = useAsyncDispatch();

  const sendMessage = () => {
    asyncDispatch(supportActions.sendMessageAsync, value);
    deactivate && deactivate();
  };

  return (
    <aside className={style.SupportModalContainer}>
      <h1 className={style.title}>{t("modals.support.title")}</h1>

      <TextArea value={value} onChange={(e) => setValue(e.target.value)} />
      <Button
        title={t("modals.support.send")}
        disabled={!value}
        onClick={sendMessage}
      />
    </aside>
  );
};
