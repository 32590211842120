// react
import React from "react";
//redux
import { useSelector } from "react-redux";
// components
import { ReactComponent as MoreSVG } from "../../assets/icons/more.svg";
import HistoryItem from "./HistoryItem";
import Button from "../../commonComponents/Button";
// helpers
import { useTranslation } from "react-i18next";
import { buttonTypes } from "../../commonComponents/Button/constants";
import { useLocation } from "react-router-dom";
import urls from "routes/urls";
import { shortHistorySelector } from "store/reducers/WSActualData/selectors";
import { FilterModalContent } from "./FilterModalContent";
import ModalWindowController from "commonComponents/ModalWindowController";
//models
import { IHistory } from "../../store/reducers/WSActualData/models";
//styles
import style from "./styles/index.module.scss";

const History = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const history = useSelector(
    shortHistorySelector(urls.ASIDE_ROUTES.BASE_PORTFOLIO_PAGE === pathname)
  );

  return (
    <>
      <div className={style.HistoryContainer}>
        <div className={style.header}>
          <h2 className={style.title}>{t("dashboardPage.history")}</h2>
          <ModalWindowController
            className="historyModal"
            trigger={
              <Button
                type={buttonTypes.withoutBorder}
                title={t("dashboardPage.more")}
                icon={<MoreSVG />}
              />
            }
            content={<FilterModalContent />}
          />
        </div>
        <div className={style.HistoryContent}>
          <div className={style.row}>
            <div className={style.subTitle}>{t("dashboardPage.recent")}</div>
            <ul className={style.list}>
              {history.map((historyItem: IHistory, index: number) => (
                <HistoryItem
                  key={index + historyItem.exchange_time}
                  data={historyItem}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(History);
