import { call, takeLatest } from "redux-saga/effects";
import { IPayload } from "../../storeConfig/models";
import { withTryCatch } from "../../utils";

import { addGridStrategyService, getGridStrategiesService } from "./services";
import { strategiesActions } from "./slice";

function* getGridStrategiesWorker(): Generator {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const response = yield call(getGridStrategiesService);
  response;
  // yield put(strategiesActions.getGridStrategies(response));
}

function* addGridStrategyWorker({ payload }: IPayload) {
  yield call(addGridStrategyService, payload);
  yield call(getGridStrategiesWorker);
}

export function* strategyWatcher() {
  yield takeLatest(
    strategiesActions.addGridStrategyAsync,
    withTryCatch(addGridStrategyWorker)
  );
  yield takeLatest(
    strategiesActions.getGridStrategiesAsync,
    withTryCatch(getGridStrategiesWorker)
  );
}
