import { IHistory } from "../../../store/reducers/WSActualData/models";
import { IDataRange } from "../../Calendar/models";

export const filterByRange = (array: IHistory[], range: IDataRange) => {
  const getDateParts = (date: Date, shift = 0) =>
    [date.getFullYear(), date.getMonth(), date.getDate() + shift] as const;

  const isBetweenRange = (date: number) => {
    if (range.startDate && range.endDate) {
      return (
        new Date(date * 1000) >= new Date(...getDateParts(range.startDate)) &&
        new Date(date * 1000) <= new Date(...getDateParts(range.endDate, 1))
      );
    }
  };

  return array.filter((history) => isBetweenRange(history.exchange_time));
};
