import { all, fork } from "redux-saga/effects";
import { webSocketWatcher } from "../reducers/WSActualData/sagas";
import { authenticationWatcher } from "../reducers/Authentication/sagas";
import { exchangesWatcher } from "../reducers/Exchange/sagas";
import { assetBookmarksWatcher } from "../reducers/AssetBookmarks/sagas";
import { strategyWatcher } from "../reducers/Strategies/sagas";
import { supportWatcher } from "store/reducers/Support/sagas";

export default function* rootSaga() {
  yield all([
    fork(webSocketWatcher),
    fork(authenticationWatcher),
    fork(exchangesWatcher),
    fork(assetBookmarksWatcher),
    fork(strategyWatcher),
    fork(supportWatcher),
  ]);
}
