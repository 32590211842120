/* eslint-disable prettier/prettier */

import { axiosInstance } from "../../apiConfing/instance";
import { ADD_TRANSACTION, REMOVE_TRANSACTION } from "./constants";
import { IExceptionForAdd, IExceptionForRemove } from "./models";

// OLD SEVICE
export default {};
// import { dispatch } from "../../storeConfig";
// import { w3cwebsocket as W3CWebSocket } from "websocket";
// import { wsActualDataActions } from "./slice";

// const websocket:any = {};

// export function wsConnection() {
//   websocket.ws = new W3CWebSocket(`${process.env.REACT_APP_WS}`);
//   // websocket.ws.onmessage = (e: any) => {
//   //   dispatch({ type: GET_WS_ACTUAL_DATA, payload: JSON.parse(e.data) });
//   // };
// }

// export function wsActualDataService() {
//   const { ws } = websocket;
//   // ws.onclose = (e: any)=> {
//   //   console.log(
//   //     "Socket is closed. Reconnect will be attempted in 1 second.",
//   //     e.reason
//   //   );
//   //   setTimeout(() => {
//   //     wsActualDataService();
//   //   }, 1000);
//   // };

//   // ws.onerror = () => {
//   //   ws.close();
//   // };

//   return {
//     open: () => {
//       //
//     },
//     close: () => {
//       ws.close();
//     },
//     sendMessage: (message: any, callback?: any) => {
//       const waitForConnection = (callback: any, interval: any) => {
//         if (ws?.readyState === 1) {
//           callback();
//         } else {
//           setTimeout(function () {
//             waitForConnection(callback, interval);
//           }, interval);
//         }
//       };

//       waitForConnection(function () {
//         ws.send(JSON.stringify(message));
//         if (typeof callback !== "undefined") {
//           callback();
//         }
//         ws.onmessage = (e: any) => {
//           dispatch(wsActualDataActions.getWsActualData(JSON.parse(e.data)));
//         };
//       }, 1000);
//     },
//   };
// }

// OLAD SAGA

// import { call, debounce, takeLatest } from "redux-saga/effects";
// import { store } from "../../storeConfig";
// import { withTryCatch } from "../../utils";
// import { ISendWSMessageParams } from "./models";
// import { wsActualDataService, wsConnection } from "./services";
// import { wsActualDataActions } from "./slice";

// export function* openWsActualDataWorker(): Generator {
//   yield call(wsConnection);
// }

// export function* closeWSActualDataWorker(): Generator {
//   yield call(wsActualDataService().close);
// }

// export function* sendWSMessageWorker({
//   payload,
// }: {
//   payload: ISendWSMessageParams;
// }): Generator {
//   payload.token = store.getState().Authentication.token;
//   yield call(wsActualDataService().sendMessage, payload);
// }

// export function* webSocketWatcher(): Generator {
//   yield takeLatest(
//     wsActualDataActions.openWSActualData,
//     withTryCatch(openWsActualDataWorker)
//   );
//   yield takeLatest(
//     wsActualDataActions.closeWSActualData,
//     withTryCatch(closeWSActualDataWorker)
//   );
//   yield debounce(
//     500,
//     wsActualDataActions.sendWSMessage,
//     withTryCatch(sendWSMessageWorker)
//   );
// }


export const sendExeptionTokenService= async (body: IExceptionForAdd) =>
  await axiosInstance.post(ADD_TRANSACTION, body);

export const deleteExeptionTokenService = async (body: IExceptionForRemove) => {
  return await axiosInstance.delete(REMOVE_TRANSACTION, body as any);
};
