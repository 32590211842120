import React, { ComponentType } from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { RootState } from "../store/storeConfig";
import urls from "./urls";

interface PrivateRouteProps extends RouteProps {
  component: ComponentType<any>;
}

export const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const isAuth = useSelector(
    (store: RootState) => store.Authentication.isAuthenticated
  );

  return (
    <Route
      exact
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect push to={urls.BASE_ROUTES.BASE_LOGIN_PAGE} />
        )
      }
    />
  );
};
