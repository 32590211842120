// react
import React, { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import { enUS, ru } from "date-fns/locale";
import { useTranslation } from "react-i18next";
// components
import { ReactComponent as CalendarSVG } from "../../assets/icons/calendar.svg";
// helpers
import { useValidatedDate } from "./useValidatedDate";
import { useClickOutside } from "../../utils/useClickOutside";
import { isDeepEqual } from "../../utils/isEqual";
import { convert, getInitialDateRange, stringToDate } from "./helpers";
// models
import { ICalendarProps } from "./models";
// styles
import style from "./styles/index.module.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./styles/custom-styles.scss";

const Calendar = ({
  onChange,
  setActiveFilter,
  activeRange,
}: ICalendarProps) => {
  const { t } = useTranslation();
  const initialRange = getInitialDateRange();

  const [startDate, setStartDate, validStartDate] = useValidatedDate(
    convert(initialRange.startDate)
  );
  const [endDate, setEndDate, validEndDate] = useValidatedDate(
    convert(initialRange.endDate)
  );

  useEffect(() => {
    onChange({
      startDate: stringToDate(validStartDate),
      endDate: stringToDate(validEndDate),
      key: "selection",
    });
  }, [validStartDate, validEndDate]);

  useEffect(() => {
    if (activeRange) {
      setStartDate(convert(activeRange.startDate));
      setEndDate(convert(activeRange.endDate));
    }
  }, [activeRange]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    callback: (string: string) => void
  ) => {
    callback(e.target.value);
  };

  const handleStartDateBlur = () => {
    setStartDate(validStartDate);
  };

  const handleEndDateBlur = () => {
    setEndDate(validEndDate);
  };

  const datePicker = useRef<HTMLDivElement>(null);
  const [isOpen, toggleModal] = useState(false);

  useClickOutside(datePicker, () => toggleModal(false));

  const localization = localStorage.getItem("i18nextLng");
  const localizationDateRangePicker = ["ru-GB", "ru"].includes(
    localization as any
  )
    ? ru
    : enUS;

  const changeCalendarVisible = () => {
    toggleModal(!isOpen);
    setActiveFilter("");
  };

  const onChangeDateRangePicker = (item: any) => {
    onChange(item.selection);
  };

  return (
    <div className={style.calendarContainer}>
      <div className={style.datesSelectedBlock}>
        <div className={style.inputWrapper}>
          <input
            className={style.calendarInput}
            placeholder={t("dateRangePicker.dateInputPlaceholder")}
            value={startDate}
            onChange={(event) => handleChange(event, setStartDate)}
            onBlur={handleStartDateBlur}
          />
        </div>
        <div className={style.inputWrapper}>
          <input
            className={style.calendarInput}
            placeholder={t("dateRangePicker.dateInputPlaceholder")}
            value={endDate}
            onChange={(event) => handleChange(event, setEndDate)}
            onBlur={handleEndDateBlur}
          />
        </div>
        <button
          className={style.calendarInitiator}
          onClick={changeCalendarVisible}
        >
          <CalendarSVG />
        </button>
      </div>
      {isOpen && (
        <div className={style.datePicker} ref={datePicker}>
          <DateRangePicker
            className={style.calendar}
            ranges={activeRange ? [activeRange] : []}
            rangeColors={["#006BFF"]}
            onChange={onChangeDateRangePicker}
            inputRanges={[]}
            staticRanges={[]}
            months={1}
            locale={localizationDateRangePicker}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(Calendar, isDeepEqual);
