import { createSlice } from "@reduxjs/toolkit";
import { asyncReducer } from "../../utils";
import noop from "lodash/noop";
import { IGridStrategy, IStrategies, IStrategyParams } from "./models";
import { strategyDetailOptionsMock } from "../../../components/StrategyParams";

export const initialState: IStrategies = {
  squeezeStrategies: [],
  dcaStrategies: [],
  gridStrategies: [
    {
      strategyType: null,
      key: 1,
      params: "",
    },
    {
      strategyType: null,
      key: 2,
      params: "",
    },
    {
      strategyType: null,
      key: 3,
      params: "",
    },
  ],
  strategyParams: {
    key: "",
    strategyType: "",
    isNew: false,
  },
  activeStrategyDetailParams: {
    investmentAmount: "",
    lowPrice: "",
    topPrice: "",
    sellPrice: "",
    buyPrice: "",
    gridStep: "",
    levels: "",
    exchange: { value: "", label: "", api_key: "" },
    pair: strategyDetailOptionsMock.pairs[0],
    type: strategyDetailOptionsMock.type[0],
    leverage: 1,
  },
};
export const { actions: strategiesActions, reducer } = createSlice({
  name: "Strategies",
  initialState,
  reducers: {
    // getGridStrategies: (state: any, { payload }: { payload: any }) => {
    //   state;
    //   payload;
    // },
    setStrategyDetailsParams: (
      state: IStrategies,
      { payload }: { payload: IStrategyParams }
    ) => {
      state.strategyParams = payload;
    },
    setActiveStrategyDetailParams: (
      state: IStrategies,
      { payload }: { payload: IGridStrategy }
    ) => {
      state.activeStrategyDetailParams = payload;
    },
    addGridStrategyAsync: asyncReducer<IStrategies, IGridStrategy>(noop),
    getGridStrategiesAsync: asyncReducer<IStrategies>(noop),
  },
});
