export const ALL_EXCHANGES = "all-exchanges";
export const USER_EXCHANGES_URL = "/user/exchange-account";
export const ADD_EXCHANGE_URL = `${USER_EXCHANGES_URL}/set`;
export const CONNECTED_EXCHANGES_URL = `${USER_EXCHANGES_URL}/list`;
export const EXCHANGES_URL = "/exchange";
export const AVAILABLE_EXCHANGES_URL = `${EXCHANGES_URL}/allow`;

export enum ExchangesManagerStutuses {
  add = "add",
  delete = "delete",
  update = "update",
  inactive = "inactive",
}
