// React
import React from "react";
import ReactDOM from "react-dom/client";
// Redux
import { Provider } from "react-redux";
import { store } from "./store/storeConfig";
// Component
import { App } from "./AppConfig";
//PWA
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

// styles
import "./commonStyles/_fonts.module.scss";
import "./commonStyles/_reset.module.scss";
import "./commonStyles/_common.module.scss";
import "./commonStyles/main.module.scss";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
