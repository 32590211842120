// React
import React from "react";
// styles
import styles from "./styles/index.module.scss";
// models
import { ITextAreaProps } from "./models";

export const TextArea = (props: ITextAreaProps) => {
  return <textarea className={styles.textArea} {...props} maxLength={966} />;
};
