export const priceFormatter = (
  price: number | string,
  precision = 2
): string => {
  if (typeof price === "string" && price.split(".")[1]?.length > 6) {
    return price;
  }

  price = +price;

  if (typeof price === "number" && price < 0.01 && price > 0)
    return priceFormatterForSpeciaCases(price);
  return (
    price
      // .toFixed(precision)
      .toLocaleString("ru", {
        maximumFractionDigits: precision,
        minimumFractionDigits: precision,
      })
      .replace(",", ".")
  );
};

export const formatGraphPrice = (price: number) => {
  const formattedPrice = +price.toPrecision(5);
  return formattedPrice.toLocaleString("ru").replace(",", ".");
};

export const priceFormatterForSpeciaCases = (price: number): string =>
  `${Math.round(price * 100000000) / 100000000}`;
