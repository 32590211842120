// React
import React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  Route,
} from "react-router-dom";
// Component
import { PrivateRoute } from "./PrivateRoute";
import { PageController } from "../components/PageController";
import { ErrorInterceptorModal } from "../components/ErrorInterceptorModal";
import { LoginPage } from "../pages/LoginPage";
import { NotificationModal } from "../components/NotificationModal";
import { PublicRoute } from "./PublicRoute";
import { ResetPasswordPage } from "../pages/ResetPasswordPage";
import ScrollToTop from "../utils/scrollToTop";
// helpers
import urls from "./urls";

export const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <ErrorInterceptorModal />
      <NotificationModal />
      <Switch>
        <Route path={urls.BASE_ROUTES.RESET_PASS_PAGE}>
          <ResetPasswordPage />
        </Route>
        <PublicRoute
          path={urls.BASE_ROUTES.BASE_LOGIN_PAGE}
          component={LoginPage}
          exact
        />
        <PrivateRoute
          path={urls.BASE_ROUTES.BASE_APP_PAGE}
          component={PageController}
          exact
        />
        <Redirect to={urls.BASE_ROUTES.BASE_APP_PAGE} />
      </Switch>
    </Router>
  );
};
