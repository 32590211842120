// react
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// components
import { ReactComponent as CheckedSVG } from "../../../assets/icons/checkCircled.svg";
import Button from "../../../commonComponents/Button";
import { exchangesActions } from "../../../store/reducers/Exchange/slice";
// models
import { ExchangesManagerStutuses } from "../../../store/reducers/Exchange/constants";
import { IClonedModalProps } from "../models";
// styles
import styles from "./styles/index.module.scss";
import { RootState } from "../../../store/storeConfig";

export const ExchangeRequestSuccess = ({ deactivate }: IClonedModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const requestSuccess = useSelector(
    (state: RootState) => state.Exchanges.exchangeRequestSuccess
  );

  useEffect(() => {
    return () => {
      dispatch(
        exchangesActions.exchangeRequestStatus(
          ExchangesManagerStutuses.inactive
        )
      );
    };
  }, []);

  const handleButtonClick = () => {
    deactivate && deactivate();
  };

  const getTitles = () => {
    switch (requestSuccess) {
      case ExchangesManagerStutuses.add:
        return {
          title: t("modals.success.exchangeAdded"),
          text: t("modals.success.exchangeAddedText"),
        };
      case ExchangesManagerStutuses.delete:
        return {
          title: t("modals.success.exchangeRemoved"),
          text: t("modals.success.exchangeRemovedText"),
        };
      case ExchangesManagerStutuses.update:
        return {
          title: t("modals.success.exchangeUpdated"),
          text: t("modals.success.exchangeUpdatesText"),
        };
    }
  };

  return (
    <div className={styles.successContainer}>
      <CheckedSVG />
      <h2 className={styles.modalTitle}>{getTitles()?.title}</h2>
      <p className={styles.text}>{getTitles()?.text}</p>
      <Button title={t("modals.success.toWork")} onClick={handleButtonClick} />
    </div>
  );
};
