// device width
export const mobileSmall = 320;
export const mobile = 414;
export const tabletMedium = 768;
export const desktop = 1024;
export const desktopLarge = 1280;

//error & modal types
export const error = "error";
export const warning = "warning";

// tabIndexes
export const DEFAULT_TAB_INDEX = 0;
export const MODAL_TAB_INDEX = 1;

//filters
export const month = "month";
export const week = "week";
export const day = "day";
export const hour = "hour";
export const all = "all";
