// React
import React from "react";
// helpers
import { classes } from "../../utils/classes";
// models
import { MiniCardProps } from "./models";
// styles
import style from "./styles/index.module.scss";

export const MiniCard = (props: MiniCardProps) => {
  const {
    title,
    description,
    selected,
    transparent,
    rightAlignment,
    className,
  } = props;

  const children = props.children || (
    <div className={style.description}>{description}</div>
  );

  return (
    <div
      className={classes(
        style.card,
        transparent && style.transparent,
        rightAlignment && style.rightAlignment,
        className
      )}
    >
      <div className={classes(style.title, selected && style[selected])}>
        {title}
      </div>
      {children}
    </div>
  );
};
