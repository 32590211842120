import { axiosInstance } from "../../apiConfing/instance";

// urls
import {
  ADD_EXCHANGE_URL,
  CONNECTED_EXCHANGES_URL,
  AVAILABLE_EXCHANGES_URL,
  USER_EXCHANGES_URL,
} from "./constants";

// models
import {
  IAddExchangeBody,
  IDeleteExchangeParams,
  IExchangeData,
} from "./models";

export const addExchangeRequest = async (body: IAddExchangeBody) =>
  await axiosInstance.post<IAddExchangeBody>(ADD_EXCHANGE_URL, body);

export const fetchConnectedExchanges = async () =>
  await axiosInstance.get<IExchangeData[]>(CONNECTED_EXCHANGES_URL);

export const fetchAvailableExchanges = async () =>
  await axiosInstance.get<string[]>(AVAILABLE_EXCHANGES_URL);

export const deleteExchange = async ({
  exchangeName,
  apiKey,
}: IDeleteExchangeParams) => {
  const deleteUrl = `${USER_EXCHANGES_URL}/${exchangeName}:${apiKey}/delete`;
  return await axiosInstance.delete(deleteUrl);
};
