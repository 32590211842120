// react
import React, { useState } from "react";
// components
import { AddExchange } from "./AddExchange";
import { ExchangeRequestSuccess } from "./ExchangeRequestSuccess";
import { ManageExchanges } from "./ManageExchanges";
import { ConfirmDeletion } from "./ConfirmDeletion";
// constants, models
import { STEP_NAMES } from "./constants";
import { IClonedModalProps, IStepperProps, TStep } from "./models";
import { IExchangeData } from "../../store/reducers/Exchange/models";
import { UpdateExchange } from "./UpdateExchange";
import { useHideBodyScroll } from "utils/useHideBodyScroll";

const STEPS = {
  [STEP_NAMES.ADD_EXCHANGE]: <AddExchange />,
  [STEP_NAMES.EXCHANGE_REQ_SUCCESS]: <ExchangeRequestSuccess />,
  [STEP_NAMES.MANAGE_EXCHANGES]: <ManageExchanges />,
  [STEP_NAMES.CONFIRM_DELETION]: <ConfirmDeletion />,
  [STEP_NAMES.UPDATE_EXCHANGE]: <UpdateExchange />,
};

export const Stepper = ({
  availableExchanges,
  connectedExchanges,
  selectedExchange,
  deactivate,
}: IStepperProps) => {
  const initialStep: TStep = connectedExchanges.length
    ? "MANAGE_EXCHANGES"
    : "ADD_EXCHANGE";
  useHideBodyScroll();
  const [activeStep, setActiveStep] = useState<TStep>(initialStep);
  const [activeExchange, setActiveExchange] = useState<IExchangeData | null>(
    null
  );

  const getModalProps = (step: TStep): IClonedModalProps => {
    switch (step) {
      case "ADD_EXCHANGE":
        return {
          setActiveStep,
          connectedExchanges,
          availableExchanges,
        };
      case "CONFIRM_DELETION":
        return {
          setActiveStep,
          activeExchange,
        };
      case "EXCHANGE_REQ_SUCCESS":
        return {
          deactivate,
        };
      case "MANAGE_EXCHANGES":
        return {
          setActiveStep,
          setActiveExchange,
          connectedExchanges,
          selectedExchange,
          deactivate,
        };
      case "UPDATE_EXCHANGE":
        return {
          setActiveStep,
          activeExchange,
        };
      default:
        return {};
    }
  };

  return (
    <>
      {STEPS[activeStep] &&
        React.cloneElement<IClonedModalProps>(STEPS[activeStep], {
          ...getModalProps(activeStep),
        })}
    </>
  );
};
