// React
import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as SpinnerSVG } from "../../assets/icons/spinner.svg";
//styles
import styles from "./styles/index.module.scss";
import { classes } from "utils/classes";

export const Spinner = ({
  isSimple,
  isLoading,
}: {
  isSimple?: boolean;
  isLoading?: boolean;
}) => {
  const { t } = useTranslation();

  if (isSimple)
    return (
      <div className={styles.spinnerWrapper}>
        <SpinnerSVG />
      </div>
    );
  return (
    <div
      className={classes(
        styles.spinnerContainer,
        isLoading === false && styles.stopLoading
      )}
    >
      <div className={styles.spinnerWrapper}>
        <SpinnerSVG />
      </div>
      <span>{t("loading")}...</span>
    </div>
  );
};
