// react
import React, { useEffect, useState } from "react";
// redux
import { useSelector } from "react-redux";
import { useAsyncDispatch } from "../../store/utils";
import { assetBookmarksActions } from "../../store/reducers/AssetBookmarks/slice";
// components
import { CurrencyCard } from "./CurrencyCard";
import ModalWindowController from "../../commonComponents/ModalWindowController";
import { AddBookmarkModalContent } from "./manageBookmarkModalContent";
// models
import { IBookmark } from "../../store/reducers/WSActualData/models";
import { bookmarsSelector } from "store/reducers/WSActualData/selectors";
// styles
import style from "./styles/index.module.scss";

export const CurrencyCards = () => {
  const [currentCard, setCurrentCard] = useState<IBookmark | null>(null);
  const asyncDispatch = useAsyncDispatch();

  const bookmarks = useSelector(bookmarsSelector);

  useEffect(() => {
    asyncDispatch(assetBookmarksActions.getAvailableBookmarkPairsAsync);
  }, []);

  const addBookmark = (value: string) => {
    const connectedBookmarks = bookmarks.map((item) => item.name);
    connectedBookmarks.push(value);
    const bookmarksString = connectedBookmarks.join(", ");
    asyncDispatch(assetBookmarksActions.setBookmarksAsync, bookmarksString);
  };

  const deleteBookmark = (name: string) => {
    const connectedBookmarks = bookmarks
      .map((item) => item.name)
      .filter((item) => item !== name);
    const bookmarksString = connectedBookmarks.join(", ");
    asyncDispatch(assetBookmarksActions.setBookmarksAsync, bookmarksString);
  };

  const changeBookmark = (newValue: string, oldValue: string) => {
    const connectedBookmarks = bookmarks.map((item) => item.name);
    const bookmarksString = connectedBookmarks
      .join(", ")
      .replace(oldValue, newValue);
    asyncDispatch(assetBookmarksActions.setBookmarksAsync, bookmarksString);
  };

  const handleDragStart = (card: IBookmark) => {
    setCurrentCard(card);
  };

  const handleDrop = (card: IBookmark) => {
    const newBookmarks = [...bookmarks];

    const eventCardIdx = newBookmarks.findIndex(
      (item) => item.name === card.name
    );
    const currentCardIdx = newBookmarks.findIndex(
      (item) => item.name === currentCard?.name
    );

    [newBookmarks[eventCardIdx], newBookmarks[currentCardIdx]] = [
      newBookmarks[currentCardIdx],
      newBookmarks[eventCardIdx],
    ];

    asyncDispatch(
      assetBookmarksActions.changeBookmarksOrderAsync,
      newBookmarks
    );
  };

  return (
    <>
      {bookmarks.map((item, idx) => (
        <CurrencyCard
          onDragStart={() => handleDragStart(item)}
          onDrop={() => handleDrop(item)}
          draggable
          data={item}
          key={idx}
          deleteBookmark={deleteBookmark}
          changeBookmark={changeBookmark}
        />
      ))}
      <ModalWindowController
        trigger={<button className={style.addCard} aria-label="add" />}
        content={<AddBookmarkModalContent onPairClick={addBookmark} />}
      />
    </>
  );
};
