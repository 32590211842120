import { axiosInstance } from "../../apiConfing/instance";
import { IGridStrategy } from "./models";

export const getGridStrategiesService = async () => {
  return await axiosInstance.get("/grid/bot/list");
};

export const addGridStrategyService = async (body: IGridStrategy) => {
  await axiosInstance.post("/grid/bot/create", body);
};
